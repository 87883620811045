import { sortBy, flow as pipe } from "lodash";
import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { Button, Table } from "semantic-ui-react";
import WorkingTimeRow from "./WorkingTimeRow/WorkingTimeRow";
import TotalRow from "./TotalRow";
import WorkingTimeEditModal from "./WorkingTimeEditModal";
import WorkingTimeCreateModal from "./WorkingTimeCreateModal";
import { toUIDateTimeFormat } from "../../../../utils/dateUtils";
import Loader from "components/Loader";

const SORT_DIRECTION = {
  DESC: "descending",
  ASC: "ascending"
};

const WorkingTimesTable = ({
  isWorkinTimeFetching,
  workingTimes = [],
  task
}) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(SORT_DIRECTION.ASC);

  const [workingTimeToEdit, setWorkingTimeToEdit] = useState(null);
  const [
    isCreateWorkingTimeModalOpen,
    setCreateWorkingTimeModalOpened
  ] = useState(false);

  const countTotal = useMemo(
    () =>
      workingTimes.reduce(
        (sum, wt) => sum + ((wt.piecesPerEmployee || 0) * wt.pieceRate || 0),
        0
      ),
    workingTimes
  );

  const workingTimeData = workingTimes
    ?.map(wt => {
      return {
        id: wt.id,
        employee: wt.employee
          ? `${wt.employee?.firstName} ${wt.employee?.lastName}`
          : "-",
        employeesGroup: wt.employeesGroup ? wt.employeesGroup.name : "-",
        contractor: wt.contractor ? wt.contractor.name : "-",
        areas: wt.areas,
        clockedIn: toUIDateTimeFormat(wt.startAt),
        clockedOut: toUIDateTimeFormat(wt.endAt),
        startAt: new Date(wt.startAt),
        endAt: new Date(wt.endAt),
        workingTime: wt.workingTimeInHMWithoutLunch,
        lunchBreak: wt.lunchInterval,
        generalTaskId: wt.generalTaskId,
        pieceRate: wt.pieceRate,
        piecesPerEmployee: wt.piecesPerEmployee,
        countTotal: parseFloat(
          parseFloat((wt.pieceRate || 0) * (wt.piecesPerEmployee || 0)).toFixed(
            2
          )
        ),
        nativeWt: {
          ...wt,
          taskAreas: task.areas
        }
      };
    })
    .sort((a, b) => {
      const dateA = parseDate(a.clockedOut);
      const dateB = parseDate(b.clockedOut);

      return dateA - dateB;
    });

  function parseDate(dateString) {
    const [day, month, yearAndTime] = dateString.split("/");
    const [year, time] = yearAndTime.split(" ");
    const [hour, minute] = time.split(":");

    return new Date(year, month - 1, day, hour, minute);
  }

  const sortedWTdata = useMemo(
    () =>
      sortColumn
        ? pipe(
            data => sortBy(data, sortColumn),
            data =>
              sortDirection === SORT_DIRECTION.DESC ? data.reverse() : data
          )(workingTimeData)
        : workingTimeData,
    [sortColumn, sortDirection, workingTimeData]
  );

  const setSorting = column => () => {
    setSortColumn(column);
    setSortDirection(
      sortDirection === SORT_DIRECTION.DESC
        ? SORT_DIRECTION.ASC
        : SORT_DIRECTION.DESC
    );
  };

  //Task ids:
  //Thinning (2), Pruning (3), Grafting (5)
  const showPieceRateFields = [2, 3, 5].includes(task.type.id);

  if (workingTimes.length === 0 && !isWorkinTimeFetching)
    return (
      <>
        <WorkingTimeCreateModal
          task={task}
          open={isCreateWorkingTimeModalOpen}
          onClose={() => setCreateWorkingTimeModalOpened(false)}
          showPieceRateFields={showPieceRateFields}
        />
        <Table.Body>
          <Table.Row>
            <Table.Cell textAlign="center" colSpan={10}>
              <div>
                No clock in/out submissions
                <br />
                <Button
                  primary
                  size="small"
                  type="button"
                  onClick={() => setCreateWorkingTimeModalOpened(true)}
                >
                  Add
                </Button>
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </>
    );

  return (
    <>
      {workingTimeToEdit && (
        <WorkingTimeEditModal
          open={!!workingTimeToEdit}
          workingTime={workingTimeToEdit}
          onClose={() => setWorkingTimeToEdit(null)}
          showPieceRateFields={showPieceRateFields}
        />
      )}
      {isCreateWorkingTimeModalOpen && (
        <WorkingTimeCreateModal
          task={task}
          open={isCreateWorkingTimeModalOpen}
          onClose={() => setCreateWorkingTimeModalOpened(false)}
          showPieceRateFields={showPieceRateFields}
        />
      )}
      {isWorkinTimeFetching ? (
        <Loader />
      ) : (
        <Table.Body style={{ textAlign: "center" }}>
          <Table.Row>
            <Table.Cell textAlign="center" colSpan={10}>
              <Table sortable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell
                      sorted={sortColumn === "id" ? sortDirection : null}
                      onClick={setSorting("id")}
                    >
                      ID
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortColumn === "employee" ? sortDirection : null}
                      onClick={setSorting("employee")}
                    >
                      Employee
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "employeesGroup" ? sortDirection : null
                      }
                      onClick={setSorting("employeesGroup")}
                    >
                      Group
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "contractor" ? sortDirection : null
                      }
                      onClick={setSorting("contractor")}
                    >
                      Contractor
                    </Table.HeaderCell>
                    <Table.Cell>
                      <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                        Block/Patch
                      </span>
                    </Table.Cell>
                    <Table.HeaderCell
                      sorted={sortColumn === "startAt" ? sortDirection : null}
                      onClick={setSorting("startAt")}
                    >
                      Clocked <br /> In
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={sortColumn === "endAt" ? sortDirection : null}
                      onClick={setSorting("endAt")}
                    >
                      Clocked <br /> Out
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "workingTime" ? sortDirection : null
                      }
                      onClick={setSorting("workingTime")}
                    >
                      Working <br /> Time
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      sorted={
                        sortColumn === "lunchBreak" ? sortDirection : null
                      }
                      onClick={setSorting("lunchBreak")}
                    >
                      Lunch <br /> Break
                    </Table.HeaderCell>
                    {showPieceRateFields && (
                      <>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "pieceRate" ? sortDirection : null
                          }
                          onClick={setSorting("pieceRate")}
                        >
                          Piece Rate ($)
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "piecesPerEmployee"
                              ? sortDirection
                              : null
                          }
                          onClick={setSorting("piecesPerEmployee")}
                        >
                          No. Pieces
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          sorted={
                            sortColumn === "countTotal" ? sortDirection : null
                          }
                          onClick={setSorting("countTotal")}
                        >
                          Total ($)
                        </Table.HeaderCell>
                      </>
                    )}
                    <Table.Cell />
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <TotalRow
                    workingTimes={workingTimes}
                    addWorkingTime={() => setCreateWorkingTimeModalOpened(true)}
                    showPieceRateFields={showPieceRateFields}
                    totalPrice={countTotal}
                  />
                  {sortedWTdata?.map(wt => (
                    <WorkingTimeRow
                      task={task}
                      key={"working_time_" + wt.id}
                      workingTime={wt}
                      onEditClick={() => setWorkingTimeToEdit(wt.nativeWt)}
                      showPieceRateFields={showPieceRateFields}
                    />
                  ))}
                </Table.Body>
              </Table>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      )}
    </>
  );
};

WorkingTimesTable.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.shape({
      id: PropTypes.number.isRequired
    }).isRequired
  }).isRequired,
  workingTimes: PropTypes.array.isRequired
};

WorkingTimesTable.defaultProps = {
  workingTimes: []
};

export default WorkingTimesTable;

import React, { useState } from "react";
import { Dropdown, Input, Icon } from "semantic-ui-react";
import { map } from "lodash";
import styles from "./AreasList.module.css";

const AreasList = ({ text, className, onChange, options }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const filtered = options?.filter(item =>
    item.text.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Dropdown
      icon={<Icon className={styles.dropdonwIcon} name="chevron down" />}
      text={text}
      placeholder="Areas"
      selection
      fluid
      multiple
      search
      onClose={() => setSearchQuery("")}
      className={styles.dropdownCustomStyle}
      searchQuery={searchQuery}
      closeOnChange={false}
    >
      <Dropdown.Menu className={styles.menuContainer}>
        <Input
          placeholder="Search"
          icon="search"
          iconPosition="left"
          className={styles.searchInput}
          onClick={e => e.stopPropagation()}
          onChange={e => setSearchQuery(e.target.value)}
          value={searchQuery}
        />
        <Dropdown.Menu scrolling>
          {map(
            (filtered || options).sort((a, b) => {
              const nameA = a.label?.trim().toLowerCase(),
                nameB = b.label?.trim().toLowerCase();
              if (nameA < nameB) return -1;
              if (nameA > nameB) return 1;
              return 0;
            }),
            option => (
              <Dropdown.Item
                key={option.value}
                value={option.value}
                text={option.text}
                onClick={(e, data) => onChange(e, data.value)}
              />
            )
          )}
        </Dropdown.Menu>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AreasList;

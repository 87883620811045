import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment";
import { Button, Icon, Input } from "semantic-ui-react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "./DatePicker.module.css";

class DatePicker extends Component {
  state = {
    trigger: false,
    focused: false,
    inputValue: null
  };

  calendar = React.createRef();
  input = React.createRef();

  componentDidMount() {
    document.body.addEventListener("click", this.handleBlur);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleBlur);
  }

  handleBlur = event => {
    const { focused } = this.state;
    if (
      (!this.calendar.current ||
        // eslint-disable-next-line react/no-find-dom-node
        (this.calendar.current &&
          !ReactDOM.findDOMNode(this.calendar.current).contains(
            event.target
          ))) &&
      !focused
    ) {
      this.toggleShowCalendar(false);
    }
  };

  toggleShowCalendar(show = false) {
    this.setState({ trigger: show });
  }

  changeDateInput = event => {
    const { onChange, minDate } = this.props;
    /* eslint-disable-next-line */
    const inputValue = event.target.value.replace(/[^\/\d]/g, "");
    /* eslint-disable-next-line */
    const isDate = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

    this.setState({
      inputValue
    });
    if (
      inputValue.match(isDate) &&
      moment(inputValue, "DD/MM/YYYY").isValid() &&
      minDate &&
      moment(inputValue).isSameOrAfter(moment(minDate))
    ) {
      onChange(moment(inputValue, "DD/MM/YYYY").format("YYYY-MM-DD"));
    }
  };

  changeDate = value => {
    const { onChange, setDateDropdownChecker } = this.props;
    setDateDropdownChecker && setDateDropdownChecker(false);
    onChange(value ? moment(value).format("YYYY-MM-DD") : null);
    this.toggleShowCalendar(false);
  };

  onFocus = event => {
    this.setState({
      inputValue: event.target.value,
      focused: true,
      trigger: true
    });
  };

  onBlur = e => {
    const { inputValue } = this.state;
    const { onChange, disableClear } = this.props;

    if (
      moment(inputValue, "DD/MM/YYYY").isValid() ||
      (inputValue === "" && !disableClear)
    ) {
      onChange(
        inputValue ? moment(inputValue, "DD/MM/YYYY").format("YYYY-MM-DD") : ""
      );
    }
    this.setState({
      inputValue: null,
      focused: false
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  };

  onKeyPress = event => {
    if (event.key === "Enter") {
      // eslint-disable-next-line react/no-find-dom-node
      ReactDOM.findDOMNode(this.input.current)
        .querySelector("input")
        .blur();
      this.toggleShowCalendar(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  render() {
    const {
      disabled,
      loading,
      placeholder,
      value,
      labelText,
      labelClass,
      disableClear,
      position,
      error,
      className,
      size,
      noCalendarIcon,
      minDate,
      maxDate,
      name
    } = this.props;
    const { inputValue, trigger } = this.state;

    const currentValue =
      inputValue !== null
        ? inputValue
        : value
        ? moment(value, "YYYY-MM-DD").format("DD/MM/YYYY")
        : "";

    const extraProps = {
      ...(minDate ? { minDate: moment(minDate).toDate() } : {}),
      ...(maxDate ? { maxDate: moment(maxDate).toDate() } : {})
    };

    return (
      <span
        ref={this.calendar}
        className={`${styles.inputHolder} ${className || ""}`}
      >
        {labelText && (
          <label className={labelClass || null}>{labelText} </label>
        )}
        <Input
          disabled={disabled}
          loading={loading}
          fluid
          className={styles.datePickerInput}
          size={size || "large"}
          onFocus={this.onFocus}
          value={currentValue}
          placeholder={placeholder || "DD/MM/YYYY"}
          icon={
            noCalendarIcon ? null : (
              <Icon className={styles.calendarIcon} name="calendar" />
            )
          }
          onChange={this.changeDateInput}
          onBlur={this.onBlur}
          onKeyPress={this.onKeyPress}
          ref={this.input}
          error={error}
          name={name}
        />

        {trigger && (
          <div
            className={`${styles.calendarHolder} ${
              position ? styles[position] : ""
            }`}
          >
            <Calendar
              value={value ? new Date(value) : new Date()}
              onChange={this.changeDate}
              {...extraProps}
            />
            {!disableClear && (
              <div className={styles.buttonHolder}>
                <Button
                  type="button"
                  onClick={() => this.props.onChange(null)}
                  className="button-text"
                >
                  Clear Date
                </Button>
              </div>
            )}
          </div>
        )}
      </span>
    );
  }
}

DatePicker.propTypes = {
  className: PropTypes.any,
  disableClear: PropTypes.bool,
  error: PropTypes.bool,
  labelClass: PropTypes.any,
  labelText: PropTypes.string,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
  name: PropTypes.string,
  noCalendarIcon: PropTypes.bool,
  onChange: PropTypes.func,
  position: PropTypes.any,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

export default DatePicker;

import React from "react";
import { Table } from "semantic-ui-react";
import CollapsibleBlock from "components/CollapsibleBlock";
import styles from "../Sidebar.module.css";
import { StatusChip } from "routes/WorkingTime/components/StatusChip";

const TaskDetails = ({ activeItem = {}, employees, taskList }) => {
  const totalWT = activeItem?.items?.map(item => {
    const start = new Date(item.startAt);
    const end = new Date(item.endAt);
    const diffInMs = end - start;
    const diffInHours = diffInMs / (1000 * 60 * 60);
    return { diffInHours: diffInHours.toFixed(2) };
  });

  const approvedStatus = activeItem?.items?.some(item => !item.approved)
    ? false
    : true;
  function convertToHoursMinutes() {
    const total = totalWT?.reduce((prev, curr) => +prev + +curr.diffInHours, 0);
    const hours = Math.floor(total);
    const minutes = Math.round((total - hours) * 60);
    return `${hours}h ${minutes}m`;
  }
  const activeEmployee = employees
    .filter(item => activeItem?.items?.some(e => e.createdBy === item.id))
    .map(i => `${i.firstName} ${i.lastName}`);
  const activeTask = taskList?.find(item => item.id === activeItem?.id);
  return (
    <CollapsibleBlock title="Task details" className={styles.blockContainer}>
      <Table className={styles.taskDetailsTable} definition fixed>
        <Table.Body>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>ID</Table.Cell>
            <Table.Cell> {`GT-${activeItem?.id}`}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Task name</Table.Cell>
            <Table.Cell>{activeItem?.taskName}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Type</Table.Cell>
            <Table.Cell>{activeItem?.type}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Added by</Table.Cell>
            <Table.Cell>{activeEmployee.join(", ")}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Status</Table.Cell>
            <Table.Cell>{<StatusChip approved={approvedStatus} />}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>
              Total working time
            </Table.Cell>
            <Table.Cell>{convertToHoursMinutes()}</Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell className={styles.headerSection}>Comments</Table.Cell>
            <Table.Cell>{activeTask?.comment || "-"}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </CollapsibleBlock>
  );
};

export default TaskDetails;

import React, { useEffect } from "react";
import { Field, useFormikContext } from "formik";
import { Icon, Form, Input, Segment } from "semantic-ui-react";
import styles from "./CompletionModal.module.css";
import { calculateTimeDifference } from "routes/Tasks/components/helper";
import DateRangePickerComponent from "./DateRangePicker";

const SecondStepForm = ({
  differenceHours,
  setErrorChecker,
  handleUpdateValue,
  fromDetail,
  areaIndex,
  index,
  disabled,
  wtIndex
}) => {
  const { values, setFieldValue } = useFormikContext();
  const detailedItem =
    values?.blocks[areaIndex]?.assignee[index]?.workingTime[wtIndex];

  const hoursDifference =
    values.wtStart &&
    values.wtEnd &&
    calculateTimeDifference(values.wtStart, values.wtEnd, values.lunchInterval);
  const minutesDifference =
    values.wtStart &&
    values.wtEnd &&
    calculateTimeDifference(values.wtStart, values.wtEnd, values.lunchInterval);
  const detailsHoursDifference =
    fromDetail &&
    detailedItem?.wtStart &&
    detailedItem?.wtEnd &&
    calculateTimeDifference(
      detailedItem?.wtStart,
      detailedItem?.wtEnd,
      detailedItem?.lunchInterval
    );
  const detailsMinutesDifference =
    fromDetail &&
    detailedItem?.wtStart &&
    detailedItem?.wtEnd &&
    calculateTimeDifference(
      detailedItem?.wtStart,
      detailedItem?.wtEnd,
      detailedItem?.lunchInterval
    );
  useEffect(() => {
    return setErrorChecker(!!differenceHours && differenceHours >= 12);
  }, [values]);
  return (
    <div className={styles.formWrapper}>
      <Form>
        <Segment className={styles.editModalSegment}>
          <Form.Group style={{ gap: "40px" }}>
            <Form.Field
              disabled={disabled}
              width={8}
              className={styles.formField}
              required
            >
              <label>Date range </label>
              {
                <DateRangePickerComponent
                  disabled={disabled}
                  values={values}
                  index={index}
                  areaIndex={areaIndex}
                  handleUpdateValue={handleUpdateValue}
                  fromDetail={fromDetail}
                  wtIndex={wtIndex}
                  setFieldValue={setFieldValue}
                  detailedItem={detailedItem}
                />
              }
            </Form.Field>
            <Form.Field
              disabled={disabled}
              width={8}
              className={styles.formField}
            >
              <label>Working time</label>
              <Field
                as={Input}
                disabled={true}
                icon={<Icon name="clock outline" />}
                className={styles.disabledField}
                name="row"
                value={
                  fromDetail
                    ? detailsHoursDifference && detailsMinutesDifference
                      ? `${detailsHoursDifference?.hoursDifference}h ${detailsMinutesDifference?.minutesDifference}mm`
                      : "0h 00mm"
                    : hoursDifference && minutesDifference
                    ? `${hoursDifference?.hoursDifference}h ${minutesDifference?.minutesDifference}mm`
                    : "0h 00mm"
                }
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ gap: "40px" }}>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field
                disabled={disabled}
                width={8}
                className={styles.formField}
              >
                <label>Start</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  name="wtStart"
                  type="time"
                  value={fromDetail ? detailedItem?.wtStart : values.wtStart}
                  onChange={(_, { value }) => {
                    return !fromDetail
                      ? (values.blocks.map((area, areaIndex) => {
                          return area.assignee.map((a, assigneIndex) => {
                            return a.workingTime.map((wt, wtInd) => {
                              return setFieldValue(
                                `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]wtStart`,
                                value
                              );
                            });
                          });
                        }),
                        setFieldValue("wtStart", value))
                      : handleUpdateValue("wtStart", value);
                  }}
                />
              </Form.Field>
              <Form.Field
                disabled={disabled}
                width={8}
                className={styles.formField}
              >
                <label>Finish</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  name="wtEnd"
                  type="time"
                  value={fromDetail ? detailedItem?.wtEnd : values.wtEnd}
                  onChange={(_, { value }) => {
                    return !fromDetail
                      ? (values.blocks.map((area, areaIndex) => {
                          return area.assignee.map((a, assigneIndex) => {
                            return a.workingTime.map((wt, wtInd) => {
                              return setFieldValue(
                                `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]wtEnd`,
                                value
                              );
                            });
                          });
                        }),
                        setFieldValue("wtEnd", value))
                      : handleUpdateValue("wtEnd", value);
                  }}
                />
              </Form.Field>
            </span>
            <Form.Field
              disabled={disabled}
              width={8}
              className={styles.formField}
            >
              <label>Piece rate</label>
              <Field
                as={Input}
                disabled={disabled}
                name="price"
                value={fromDetail ? detailedItem?.price : values.price}
                onChange={(_, { value }) => {
                  return !fromDetail
                    ? (values.blocks.map((area, areaIndex) => {
                        return area.assignee.map((a, assigneIndex) => {
                          return a.workingTime.map((wt, wtInd) => {
                            return setFieldValue(
                              `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]price`,
                              value
                            );
                          });
                        });
                      }),
                      setFieldValue("price", value))
                    : handleUpdateValue("price", value);
                }}
              />
            </Form.Field>
          </Form.Group>
          <Form.Group style={{ gap: "40px" }}>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field
                disabled={disabled}
                required
                width={fromDetail ? 16 : 8}
                className={styles.formField}
              >
                <label>Lunch interval (min)</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  icon={<Icon name="clock outline" />}
                  className={styles.disabledField}
                  name="lunchInterval"
                  value={
                    fromDetail
                      ? detailedItem?.lunchInterval
                      : values.lunchInterval
                  }
                  onChange={(_, { value }) => {
                    const blockCount = values?.blocks
                      .map(item => item.assignee)
                      .flat();
                    return !fromDetail
                      ? (values.blocks.map((area, areaIndex) => {
                          return area.assignee.map((a, assigneIndex) => {
                            return a.workingTime.map((wt, wtInd) => {
                              return setFieldValue(
                                `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]lunchInterval`,
                                value
                              );
                            });
                          });
                        }),
                        setFieldValue("lunchInterval", value),
                        setFieldValue(
                          "totalBreakTime",
                          blockCount.length * +value
                        ))
                      : handleUpdateValue("lunchInterval", value);
                  }}
                />
              </Form.Field>
              {!fromDetail && (
                <Form.Field
                  disabled={disabled}
                  width={8}
                  className={styles.formField}
                >
                  <label>Total break time</label>
                  <Field
                    as={Input}
                    name="row"
                    disabled={true}
                    icon={<Icon name="clock outline" />}
                    className={styles.disabledField}
                    value={values.blocks
                      .map(item => item.assignee.map(i => i.workingTime))
                      .flat(3)
                      .reduce((prev, curr) => +prev + +curr.lunchInterval, 0)}
                  />
                </Form.Field>
              )}
            </span>
            <span style={{ width: "50%", display: "flex" }}>
              <Form.Field
                disabled={disabled}
                width={8}
                type="number"
                className={styles.formField}
              >
                <label>No. of pieces</label>
                <Field
                  disabled={disabled}
                  as={Input}
                  name="noOfPieces"
                  value={
                    fromDetail ? detailedItem?.noOfPieces : values.noOfPieces
                  }
                  onChange={(_, { value }) => {
                    return !fromDetail
                      ? (values.blocks.map((area, areaIndex) => {
                          return area.assignee.map((a, assigneIndex) => {
                            return a.workingTime.map((wt, wtInd) => {
                              return setFieldValue(
                                `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]noOfPieces`,
                                value
                              );
                            });
                          });
                        }),
                        setFieldValue("noOfPieces", value))
                      : handleUpdateValue("noOfPieces", value);
                  }}
                />
              </Form.Field>
              <Form.Field
                disabled={disabled}
                width={8}
                className={styles.formField}
              >
                <label>Total ($)</label>
                <Field
                  as={Input}
                  disabled={true}
                  className={styles.disabledField}
                  name="number"
                  value={
                    fromDetail
                      ? detailedItem?.price && detailedItem?.noOfPieces
                        ? !isNaN(
                            +detailedItem?.price * +detailedItem?.noOfPieces
                          )
                          ? +detailedItem?.price * +detailedItem?.noOfPieces
                          : "0.00"
                        : "0.00"
                      : values.price && values.noOfPieces
                      ? !isNaN(+values.price * +values.noOfPieces)
                        ? +values.price * +values.noOfPieces
                        : "0.00"
                      : "0.00"
                  }
                />
              </Form.Field>
            </span>
          </Form.Group>
        </Segment>
        {!!differenceHours && differenceHours >= 12 && (
          <div
            style={{
              width: "100%",
              marginTop: "16px",
              color: "#8C0D24"
            }}
          >
            Should be earlier than 12 hours after Clocked In time
          </div>
        )}
      </Form>
    </div>
  );
};

export default SecondStepForm;

import React from "react";
import styles from "../TasksTable.module.css";
import { ROLE_COLORS } from "../../../constants/Tasks/index";
import { getNameInitials } from "routes/Labour/utils";
import { Popup, Menu, Button, Icon, Label } from "semantic-ui-react";
import { priorityIcon } from "components/Table/helpers";
import moment from "moment";

export const employeeViewer = (assignedTo, employeeList) => {
  const employeeIds = assignedTo.map(item => item.employeeId || item.id);
  const assignees = employeeList.filter(item =>
    employeeIds.some(e => e === item.id)
  );
  const numberOfEmployees = assignees.length;

  const morePopupContent = members => {
    return (
      <div className={styles.morePopupContent}>
        {members.length &&
          members &&
          members.slice(3, members.length).map(emp => {
            const employee =
              (employeeList &&
                emp &&
                employeeList.find(employeeObj => employeeObj.id === emp)) ||
              null;
            const employeeImg =
              employee && employee.picture
                ? employee.picture.presignedUrl
                : null;
            return (
              employee &&
              !employee.archived && (
                <div key={employee.id} className={styles.popupItem}>
                  <span>{`${employee.firstName} ${employee.lastName}`}</span>
                </div>
              )
            );
          })}
      </div>
    );
  };
  return numberOfEmployees ? (
    <ul className={styles.avatarsContainer}>
      {employeeIds.slice(0, 3).map((employeeId, index) => {
        const employee =
          (employeeList &&
            employeeList.find(employeeObj => employeeObj.id === employeeId)) ||
          null;
        const employeeImg =
          employee && employee.picture ? employee.picture.presignedUrl : null;

        return employeeImg ? (
          <Popup
            key={employeeId}
            position="bottom center"
            inverted
            trigger={
              <li
                className={styles.avatarHolder}
                style={{
                  backgroundImage: `url(${employeeImg})`
                }}
              />
            }
            content={`${employee.firstName} ${employee.lastName}`}
          />
        ) : (
          <Popup
            key={employeeId}
            inverted
            position="bottom center"
            trigger={
              <li
                className={styles.avatarHolder}
                style={{
                  background: ROLE_COLORS[employee && employee.type]
                    ? ROLE_COLORS[employee && employee.type]
                    : "gray"
                }}
              >
                {getNameInitials(employee && employee)}
              </li>
            }
            content={`${employee && employee.firstName} ${employee &&
              employee.lastName}`}
          />
        );
      })}
      {numberOfEmployees > 3 && (
        <Popup
          inverted
          position="right center"
          popperModifiers={{
            preventOverflow: {
              boundariesElement: "offsetParent"
            }
          }}
          content={morePopupContent(employeeIds)}
          trigger={<span>+{numberOfEmployees - 3}</span>}
        />
      )}
    </ul>
  ) : (
    "—"
  );
};

export const slashingTabsSection = (
  activeTab,
  paymentMethod,
  priority,
  handleItemClick
) => {
  return (
    <Menu pointing secondary className={styles.tabsMenu}>
      <Menu.Item className={styles.method}>
        {priorityIcon(priority)}
        {paymentMethod
          ? (
              paymentMethod
                .toLowerCase()
                .charAt(0)
                .toUpperCase() + paymentMethod.toLowerCase().slice(1)
            ).replace(/_/g, " ")
          : ""}
      </Menu.Item>
      <Menu.Item
        name={"Open"}
        active={activeTab === "Open"}
        onClick={handleItemClick}
      >
        To do
      </Menu.Item>
      <Menu.Item
        name={"Completed"}
        active={activeTab === "Completed"}
        onClick={handleItemClick}
      >
        Completed
      </Menu.Item>
      <Menu.Item
        name={"Task_information"}
        active={activeTab === "Task_information"}
        onClick={handleItemClick}
      >
        Task information
      </Menu.Item>
    </Menu>
  );
};

export const swtichButton = (areaType, setAreaType, setSelectedItems) => {
  return (
    <Button.Group basic className={styles.buttonsGroup} fluid>
      <Button
        key={"all"}
        type="button"
        onClick={() => (
          setAreaType("ALL"), setSelectedItems && setSelectedItems([])
        )}
        className={areaType === "ALL" ? styles.buttonActive : null}
        basic
      >
        All
      </Button>
      <Button
        key={"parentBlock"}
        type="button"
        onClick={() => (
          setAreaType("PARENT_BLOCK"), setSelectedItems && setSelectedItems([])
        )}
        className={areaType === "PARENT_BLOCK" ? styles.buttonActive : null}
        basic
      >
        Parent block
      </Button>
    </Button.Group>
  );
};

export const progressLine = task => {
  const completedAreas = task.areas.filter(item => item.completed);
  const progressPrecent = Math.ceil(
    (completedAreas.length * 100) / task.areas.length
  );
  return (
    <div className={styles.progressLineContainer}>
      <div className={styles.progressLine}>
        <div
          className={styles.progressBar}
          style={{ width: `${progressPrecent}%` }}
        />
      </div>
      <span className={styles.precent}>{progressPrecent} %</span>
    </div>
  );
};
export const areasGrouper = data => {
  const idMap = {};
  data.forEach(obj => {
    const { id, children } = obj;

    if (!idMap[id]) {
      idMap[id] = { ...obj, children: [...children] };
    } else {
      idMap[id].children = [...idMap[id].children, ...children];
    }
  });
  const groupedArray = Object.values(idMap);
  return groupedArray;
};

export const wtCreater = filtredWt => {
  const data = filtredWt?.map(item => {
    const date1 = new Date(item.startAt);
    const date2 = new Date(item.endAt);
    const differenceMs = date2 - date1;
    const differenceMinutes = differenceMs / (1000 * 60) - item.lunchInterval;
    return differenceMinutes;
  });
  const totalMinutes = data?.reduce((prev, curr) => prev + curr, 0);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.round(totalMinutes % 60);
  return !!totalMinutes ? `${hours}h ${minutes}m` : "";
};

export const wtBigSmallFinder = dateStrings => {
  function findMinMaxDates(dateStrings) {
    let minDate = dateStrings && new Date(dateStrings[0]);
    let maxDate = dateStrings && new Date(dateStrings[0]);

    for (let i = 1; i < dateStrings?.length; i++) {
      const currentDate = new Date(dateStrings[i]);

      if (currentDate < minDate) {
        minDate = currentDate;
      }
      if (currentDate > maxDate) {
        maxDate = currentDate;
      }
    }
    return { minDate, maxDate };
  }

  const { minDate, maxDate } = findMinMaxDates(dateStrings);
  return dateStrings?.length
    ? `${moment(minDate).format("DD/MM/YY")} - ${moment(maxDate).format(
        "DD/MM/YY"
      )}`
    : "-";
};

export const progressBarStep = step => {
  return (
    <div className={styles.stepLoading}>
      {step === 1 ? (
        <>
          <div className={styles.iconAreasLabelWrapper}>
            <Icon
              className={styles.dotIcon}
              size="large"
              name="dot circle outline"
            />
            <span className={styles.dotIconLabel}>General</span>
          </div>
          <div className={styles.hrLine} />
          <div className={styles.iconLabelWrapper}>
            <Icon
              className={styles.outLineCircel}
              size="large"
              name="circle outline"
            />
            <span className={styles.outLineCircelLabel}>Working Time</span>
          </div>
        </>
      ) : (
        <>
          <div className={styles.iconAreasLabelWrapper}>
            <Icon
              className={styles.dotIcon}
              size="large"
              name="check circle outline"
            />
            <span className={styles.dotIconLabel}>General</span>
          </div>
          <div className={styles.hrLine} />
          <div className={styles.iconLabelWrapper}>
            <Icon
              className={styles.dotIcon}
              size="large"
              name="dot circle outline"
            />
            <span className={styles.dotIconLabel}>Working Time</span>
          </div>
        </>
      )}
    </div>
  );
};

export const calculateTimeDifference = (time1Str, time2Str, lunchInterval) => {
  const [hours1, minutes1] = time1Str?.split(":")?.map(Number);
  const [hours2, minutes2] = time2Str?.split(":")?.map(Number);

  const totalMinutes1 = hours1 * 60 + minutes1;
  let totalMinutes2 = hours2 * 60 + minutes2;

  // Adjust for time crossing midnight
  if (totalMinutes2 < totalMinutes1) {
    totalMinutes2 += 24 * 60; // Add 1440 minutes to totalMinutes2
  }

  const timeDifferenceMinutes = totalMinutes2 - totalMinutes1;
  const adjustedTimeDifference = timeDifferenceMinutes + Number(lunchInterval);

  const hoursDifference = Math.floor(adjustedTimeDifference / 60);
  const minutesDifference = adjustedTimeDifference % 60;

  return { hoursDifference, minutesDifference };
};

export const selectedEmployeeViewer = (
  selected,
  title,
  dataList,
  setValues,
  values
) => {
  return (
    <div className={styles.workersRow}>
      <span className={styles.workersRowHead}>{title}:</span>
      {values[selected]?.map(item => {
        return dataList.map(({ id, lastName, firstName }) => {
          return (
            id === (item?.employeeId || item) && (
              <Label className={styles.addedItem}>
                <span
                  className={styles.addedItemValue}
                >{`${firstName} ${lastName}`}</span>
                <Icon
                  className={styles.closeIcon}
                  name="close"
                  onClick={() => {
                    const filteredValues = values[selected]?.filter(
                      id => item !== id
                    );
                    setValues({
                      ...values,
                      [selected]: filteredValues
                    });
                  }}
                />
              </Label>
            )
          );
        });
      })}
    </div>
  );
};
export const selectedMachineryViewer = (
  selected,
  title,
  dataList,
  setValues,
  values
) => {
  return (
    <div className={styles.workersRow}>
      {values[selected]?.map(item => {
        return dataList.map(({ id, name }) => {
          return (
            id === (item?.employeeId || item) && (
              <Label className={styles.addedItem}>
                <span className={styles.addedItemValue}>{name}</span>
                <Icon
                  className={styles.closeIcon}
                  name="close"
                  onClick={() => {
                    const filteredValues = values[selected]?.filter(
                      id => item !== id
                    );
                    setValues({
                      ...values,
                      [selected]: filteredValues
                    });
                  }}
                />
              </Label>
            )
          );
        });
      })}
    </div>
  );
};

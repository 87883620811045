import React, { useState, useRef } from "react";
import {
  Modal,
  Button,
  Confirm,
  Icon,
  Form,
  Container
} from "semantic-ui-react";
import { connect, useDispatch } from "react-redux";
import { Formik, Field, useFormikContext } from "formik";
import styles from "./CompletionModal.module.css";
import { progressBarStep } from "routes/Tasks/components/helper";
import AttachmentLabel from "components/Attachments/AttachmentLabel";
import FileChooser from "components/Attachments/FileChooser";
import {
  createWorkingTime,
  updateGeneralTask,
  uploadAttachment
} from "actions/Tasks";
import ObjectID from "bson-objectid";
import ComboForm from "./StepOneForm";
import DetailsSection from "./Detailsection";
import { map } from "lodash";
import moment from "moment";

const CompletionModal = ({
  tableData,
  open,
  employeeList,
  machineryList,
  selectedItemsIds,
  setSelectedItems,
  onClose,
  activeArea,
  activeWorkingTime,
  filtredArea,
  activeTask
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const task = tableData.content?.find(item => item.id === activeTask.id) || {};
  const [errorChecker, setErrorChecker] = useState(null);
  const [secondOpen, setSecondOpen] = useState(false);
  const [loadingAttachments, setLoadingAttachments] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [openSections, setOpenSections] = useState({});
  const [combinations, setCombinations] = useState([
    { assignee: "", equipment: "" }
  ]);
  const [step, setStep] = useState(1);
  const handleAddCombo = (setFieldValue, values) => {
    setFieldValue("assignees", [...values.assignees, ""]);
    setFieldValue("equipments", [...values.equipments, ""]);
  };
  const handleTaskSubmit = taskData => {
    const dataToSend = {
      ...task,
      ...taskData,
      areas: [
        ...task.areas.filter(item =>
          taskData.areas.every(area => area.areaId !== item.areaId)
        ),
        ...taskData.areas
      ]
    };
    dispatch(updateGeneralTask(task.id, dataToSend));
  };

  const handleSubmit = async (taskData, wtData) => {
    const dispatchPromises = wtData.map(async item => {
      return dispatch(createWorkingTime({ formData: item }));
    });

    const results = await Promise.all(dispatchPromises);
    const allSuccessful = results.every(result => result.status === 200);

    if (allSuccessful) {
      setSelectedItems([]);
      handleTaskSubmit(taskData);
      setErrorChecker(null);
    } else {
      console.error("One or more requests failed");
    }
    setStep(1);
    setDetailsOpen(false);
    onClose();
  };

  const handleDeleteCombo = index => {
    const updatedCombos = [...combinations];
    updatedCombos.splice(index, 1);
    setCombinations(updatedCombos);
  };
  const onAttachmentSelected = (setFieldValue, values) => files => {
    setLoadingAttachments(attachments => [...attachments, "attachments"]);
    files.forEach(file => {
      const dataToSend = {
        file,
        idReplace: ObjectID().toHexString(),
        callBack: loadedFile => {
          setFieldValue(
            "attachments",
            values["attachments"]
              ? [...values["attachments"], loadedFile]
              : [loadedFile]
          );
          setLoadingAttachments(attachments =>
            attachments.filter(i => i !== "attachments")
          );
        }
      };
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onloadend = () => {
        dispatch(uploadAttachment(dataToSend));
      };
    });
  };

  const dateTimeStartString =
    activeWorkingTime[0]?.startAt && activeWorkingTime[0]?.startAt;
  const dateTimeEndString =
    activeWorkingTime[0]?.endAt && activeWorkingTime[0]?.endAt;
  const startTimeOnly =
    dateTimeStartString && moment(dateTimeStartString).format("HH:mm");
  const endTimeOnly =
    dateTimeEndString && moment(dateTimeEndString).format("HH:mm");

  return (
    <Modal
      open={open}
      closeOnDimmerClick={false}
      closeOnEscape={false}
      size="large"
      onClick={e => e.stopPropagation()}
      className={styles.root}
    >
      <Formik
        innerRef={formik => (formikRef.current = formik)}
        initialValues={{
          attachments: [],
          assignees: [""],
          equipments: [""],
          description: "",
          start: null,
          end: null,
          wtStart: null,
          wtEnd: null,
          price: null,
          noOfPieces: null,
          lunchInterval: null,
          totalBreakTime: 0,
          blocks: []
        }}
      >
        {({ isValid, setFieldValue, values, dirty }) => {
          const groupedArray = values.blocks.map((item, index) =>
            item.assignee?.map((assigne, assigneIndex) => {
              return assigne?.workingTime?.map(item => ({
                ...item,
                areas: [{ areaId: filtredArea[index]?.id }],
                employeeId: values.assignees[assigneIndex],
                machineryId: values.equipments[assigneIndex]
              }));
            })
          );
          const taskDataToSend = {
            areas: filtredArea.map(item => ({
              areaId: item.id,
              completed: true
            })),
            attachments: values.attachments,
            description: values.description
          };

          const wtDataToSend = groupedArray.flat(3)?.map(item => {
            const endDate = item?.end
              ? `${moment(item?.end).format("YYYY-MM-DD")}T${item?.wtEnd ||
                  "00:00"}:00Z`
              : null;
            const startDate = item?.start
              ? `${moment(item?.start).format("YYYY-MM-DD")}T${item?.wtStart ||
                  "23:59"}:59Z`
              : null;
            return {
              areas: item?.areas,
              employeeId: item?.employeeId,
              endAt: endDate,
              generalTaskId: task.id,
              lunchInterval: item?.lunchInterval,
              machineryId: item?.machineryId,
              pieceRate: item?.price,
              piecesPerEmployee: item?.noOfPieces,
              startAt: startDate
            };
          });
          return (
            <>
              <Modal.Header className={styles.modalHeader}>
                {selectedItemsIds?.length
                  ? `Completion of ${selectedItemsIds.length} areas`
                  : "Edit area"}
                <Icon
                  name="close"
                  className={styles.closeIcon}
                  onClick={() => {
                    return dirty
                      ? setSecondOpen(true)
                      : (onClose(),
                        setOpenSections({}),
                        setDetailsOpen(false),
                        setStep(1));
                  }}
                />
              </Modal.Header>
              ;
              <Modal.Content scrolling className={styles.modalContentContainer}>
                <div className={styles.progressBar}>
                  {progressBarStep(step)}
                  <div className={styles.progressBarHeader}>
                    {step === 1
                      ? "Choose combinations of assignees and machines that worked on the areas"
                      : "How long did it take you to complete the task?"}
                  </div>
                </div>
                <div className={styles.formContainer}>
                  {step === 1 ? (
                    <div className={styles.stepOneContainer}>
                      <Form>
                        <div className={styles.assigneeField}>
                          {values.assignees.map((combo, index) => (
                            <ComboForm
                              selectedItemsIds={selectedItemsIds}
                              setErrorChecker={setErrorChecker}
                              activeArea={activeArea}
                              step={step}
                              key={index}
                              index={index}
                              combo={combo}
                              onChange={setFieldValue}
                              onDelete={handleDeleteCombo}
                              values={values}
                              combinations={combinations}
                              employeeList={employeeList}
                            />
                          ))}

                          <div className={styles.addBtnWrapper}>
                            <Button
                              disabled={errorChecker}
                              className={styles.addBtn}
                              onClick={() => {
                                return (
                                  handleAddCombo(setFieldValue, values),
                                  setFieldValue(`blocks[${0}].assignee`, [
                                    ...values.blocks[0]?.assignee,
                                    {
                                      workingTime: [
                                        {
                                          start: null,
                                          end: null,
                                          wtStart: null,
                                          wtEnd: null,
                                          price: 0,
                                          noOfPieces: 0,
                                          lunchInterval: 0
                                        }
                                      ]
                                    }
                                  ])
                                );
                              }}
                            >
                              <Icon name="plus" />
                              Add assignee/machinery combo
                            </Button>
                          </div>
                        </div>
                        <div className={styles.additionalInformation}>
                          <div className={styles.assigneeWrapper}>
                            <div className={styles.assigneeHeader}>
                              Additional information
                            </div>
                            <div className={styles.comboContainer}>
                              <Form.Group>
                                <Field
                                  placeholder="You can leave some notes here"
                                  as={Form.TextArea}
                                  width={16}
                                  name="description"
                                  label="Notes"
                                  onChange={(_, { value }) =>
                                    setFieldValue("description", value)
                                  }
                                />
                              </Form.Group>
                              <Form.Group className={styles.attachFileWrapper}>
                                <Container
                                  fluid
                                  className={styles.generalAttachmentsContainer}
                                >
                                  {map(
                                    values.attachments,
                                    (attachment, index) => (
                                      <AttachmentLabel
                                        attachment={attachment}
                                        withDownloadfromEdit={
                                          attachment.online ? false : true
                                        }
                                        onDeleteClick={() => {
                                          setFieldValue(
                                            "attachments",
                                            values.attachments.filter(
                                              (_, i) => i !== index
                                            )
                                          );
                                        }}
                                      />
                                    )
                                  )}
                                  <FileChooser
                                    className={styles.generalAttachmentsButton}
                                    onChange={onAttachmentSelected(
                                      setFieldValue,
                                      values
                                    )}
                                    label="Attach files"
                                    disabled={loadingAttachments.includes(
                                      "attachments"
                                    )}
                                  />
                                </Container>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                  ) : (
                    <div className={styles.stepTwoContainer}>
                      <DetailsSection
                        detailsOpen={detailsOpen}
                        setDetailsOpen={setDetailsOpen}
                        setErrorChecker={setErrorChecker}
                        employeeList={employeeList}
                        machineryList={machineryList}
                        areas={values.blocks}
                        selectedAssigne={values.assignees}
                        filtredArea={filtredArea}
                        onDelete={handleDeleteCombo}
                        openSections={openSections}
                        setOpenSections={setOpenSections}
                      />
                    </div>
                  )}
                </div>{" "}
                <div className={styles.buttonContainer}></div>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  disabled={step === 1}
                  color="green"
                  basic
                  onClick={() => (
                    setStep(1), setOpenSections({}), setDetailsOpen(false)
                  )}
                  className={styles.modalBtn}
                >
                  <Icon name="chevron left" />
                  Back
                </Button>
                <Button
                  content={step === 1 ? "Next" : "Complete"}
                  color="green"
                  onClick={async () => {
                    if (step === 1) {
                      setStep(2);
                    } else {
                      await handleSubmit(taskDataToSend, wtDataToSend);
                      setErrorChecker(null);
                    }
                  }}
                  disabled={
                    !isValid ||
                    errorChecker ||
                    !values.assignees.length ||
                    values.assignees.some(item => !item) ||
                    (step === 2 &&
                      wtDataToSend.some(
                        item =>
                          !item.endAt || !item.startAt || !item.lunchInterval
                      ))
                  }
                  className={styles.modalBtn}
                />
              </Modal.Actions>
            </>
          );
        }}
      </Formik>

      <Confirm
        className={styles.confirmModal}
        content="Discard unsaved changes?"
        open={secondOpen}
        onCancel={() => setSecondOpen(false)}
        onConfirm={() => {
          return (
            setOpenSections({}),
            setDetailsOpen(false),
            onClose(),
            setStep(1),
            formikRef?.current?.resetForm(),
            setCombinations([{ assignee: "", equipment: "" }]),
            setSecondOpen(false)
          );
        }}
      />
    </Modal>
  );
};

export default connect((state, props) => {
  return {
    tableData: state.newTasks.data || []
  };
})(CompletionModal);

import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import filterTypes from "components/Table/filterTypes";
import { connect, useDispatch, useSelector } from "react-redux";
import { Icon, Button, Popup } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import {
  selectTableStructuredData,
  selectExpotStructuredData,
  formaterData,
} from "selectors/reports/shed/productTypeReport";
import {
  matterOptionsSelector,
  chemicalsOptionsFilterSelector,
  chemicalTypeOptionsSelector,
  typeOptionsSelector,
  activeIngredientsSelector,
} from "selectors/chemicals";
import { farmsOptionsFilterSelector } from "selectors/farms";
import {
  blockSelector,
  patchSelector,
  blocListSelector,
  patchListSelector,
} from "selectors/areas";
import {
  fetchProductTypeUsageData,
  fetchProductTypeUsageDataList,
} from "actions/Reports/shedsReport/productTypeReport";
import DateRangeSelect from "components/Table/components/Filters/DateRangeSelect";
import { history } from "../../../../store";
import PrintReports from "./PrintProductTypeUsageTable/PrintReports";
import styles from "./ProductTypeUsageTable.module.css";
import Numeric from "components/Numeric/Numeric";
import { excelExport } from "utils/excelExport";
import { useReactToPrint } from "react-to-print";
import PrintReport from "./PrintTypeUsage/PrintReport";
import { fetchActiveIngredients } from "actions/Chemicals";
import moment from "moment-timezone";
import _, { get, pickBy, pick } from "lodash";
import { seasonsOptionsSelector } from "selectors/seasonsSelector";
const headerColumn = [
  "Type",
  "Matter",
  "Product",
  "Active ingredient",
  "Planned Qty",
  "Actual Qty",
  "Difference",
  "Total cost",
];
const ProductTypeUsage = ({
  location,
  route,
  productsList,
  isFetching,
  navigate,
}) => {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    type: [],
    matter: "",
    activeIngredient: [],
    blockIds: [],
    patchIds: [],
    chemicalIds: [],
    farmIds: [],
    seasons: [],
    from: "",
    to: "",
  });
  const farmsList = useSelector(farmsOptionsFilterSelector);
  const typeList = useSelector(chemicalTypeOptionsSelector);
  const blockList = useSelector(blocListSelector);
  const patchList = useSelector(patchListSelector);
  const [open, setOpen] = useState(false);
  const [filtersList, setFiltersList] = useState([]);
  const [expandedRow, setExpandedRow] = useState(false);
  const componentRef = useRef();
  const chemicalsList = useSelector((state) => state.chemical.list.content);
  const fetchPrintoutAllInformation = (check) => {
    return dispatch(fetchProductTypeUsageDataList(filtersList));
  };
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const printButton = () => {
    fetchPrintoutAllInformation().then(() => {
      handlePrint();
    });
  };
  const getData = useCallback(
    (params) => {
      setFiltersList(params);
      dispatch(fetchProductTypeUsageData(params));
    },
    [fetchProductTypeUsageData]
  );

  const handleExcelExport = useCallback(
    async (expandedRow, rowFilter) => {
      const data = await dispatch(fetchProductTypeUsageDataList(filtersList));
      const { content } = data;

      if (content.length) {
        const expanded = (expandedRow
          ? content.filter((item, index) => index == expandedRow)
          : content
        )
          .map(({ items }) => items)
          .flat()
          .map(
            ({
              actualQty,
              chemicalId,
              cost,
              diff,
              plannedQty,
              matter,
              typeName,
            }) => ({
              ...{ name: typeName || "-" },
              ...{ matter: matter || "-" },
              ...(chemicalsList &&
                !!chemicalsList.length && {
                  product: chemicalsList.find((item) => item.id === chemicalId)
                    ?.name,
                }),

              ...(chemicalsList &&
                !!chemicalsList.length && {
                  activeIngredient:
                    chemicalsList
                      .find((item) => item.id === chemicalId)
                      ?.activeIngredients.map((item) => item.activeIngredient)
                      .join(", ") || "-",
                }),
              ...{
                plannedQuantity: plannedQty.toFixed(2) || 0.0,
              },
              ...{
                actualQuantity: actualQty.toFixed(2) || 0.0,
              },
              ...{
                difference: diff.toFixed(2) || 0.0,
              },
              ...{
                totalCost: cost.toFixed(2) || 0.0,
              },
            })
          );
        const filterValue = expandedRow ? rowFilter : filters;
        const dataeFromFilter = filterValue.from
          ? filterValue.from && moment(filterValue.from).format("DD/MM/YYYY")
          : "";
        const dataeTOFilter = `${
          filterValue.to
            ? filterValue.to && moment(filterValue.to).format("DD/MM/YYYY")
            : ""
        } `;
        const dateCheker = dataeFromFilter && dataeTOFilter ? "-" : "";
        const farms = filterValue.farmIds?.length
          ? `Farm-${farmsList.options
              .filter((item) => filterValue.farmIds.some((f) => f == item.key))
              .map((item) => item.label)
              .join(", ")} `
          : "";
        const products = filterValue.chemicalIds?.length
          ? `Product-${chemicalsList
              .filter((item) =>
                filterValue.chemicalIds.some((f) => f == item.id)
              )
              .map((item) => item.name)
              .join(", ")} `
          : "";
        const blocks = filterValue.blockIds?.length
          ? `Block-${blockList.options
              .filter((item) => filterValue.blockIds.some((f) => f == item.key))
              .map((item) => item.label)
              .join(", ")} `
          : "";
        const patchs = filterValue.patchIds?.length
          ? `Patch-${patchList.options
              .filter((item) => filterValue.patchIds.some((f) => f == item.key))
              .map((item) => item.label)
              .join(", ")} `
          : "";
        const matter = filterValue.matter
          ? `Matter-${filterValue.matter} `
          : "";
        const activeIngredient = filterValue.activeIngredient?.length
          ? `Active ingredient-${filterValue.activeIngredient.join(", ")} `
          : "";
        const type = filterValue.type?.length
          ? `Type-${typeList.options
              .filter((item) => filterValue.type.some((f) => f == item.key))
              .map((item) => item.label)
              .join(", ")} `
          : "";

        const fileName = `product_usage_${`${dataeFromFilter}${dateCheker}`}${dataeTOFilter}${farms}${products}${blocks}${patchs}${matter}${activeIngredient}${type}`;
        excelExport(expanded, headerColumn, fileName);
      }
    },
    [chemicalsList, filtersList]
  );
  const tableData = useSelector(selectTableStructuredData);
  const printData = useSelector(selectExpotStructuredData);
  useEffect(() => {
    return setExpandedRow(false);
  }, [tableData.number]);

  const columnsConfig = useMemo(
    () => [
      {
        accessor: "name",
        id: "expander",
        Header: "Type",
        filterId: "date",
        withSort: false,
        cellWidth: 3,
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <span {...row.getToggleRowExpandedProps({})}>
              <Icon
                className={styles.chevronIcon}
                onClick={() => setExpandedRow(row.id)}
                name={`angle ${row.isExpanded ? "up" : "down"}`}
              />
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF",
                  }}
                >
                  {value || " - "}
                </div>
              )}
            </span>
          ) : row.depth == 1 ? (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF",
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          ) : (
            <span>
              {row.canExpand ? (
                value || "-"
              ) : (
                <div
                  className={styles.valueContainer}
                  style={{
                    borderLeft: "1px solid #DEDEDF",
                    borderBottom: "1px solid #DEDEDF",
                  }}
                >
                  {value || "-"}
                </div>
              )}
            </span>
          );
        },
        filter: {
          title: "Date",
          type: filterTypes.DateRangeSelect,
          selector: <DateRangeSelect loading={isFetching} />,
        },
        minWidth: 280,
      },
      {
        Header: "Matter",
        id: "matter",
        withSort: false,
        accessor: "matter",
        cellWidth: 3,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "seasons",
        filter: {
          title: "Season",
          type: filterTypes.SingleSelect,
          selector: seasonsOptionsSelector,
        },
      },
      {
        Header: "Planned Qty (L/Kg)",
        id: "plannedQuantity",
        withSort: false,
        accessor: "plannedQuantity",
        cellWidth: 2.2,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "blockIds",
        filter: {
          title: "Block",
          type: filterTypes.MultiSelect,
          selector: (state) => blockSelector(filters)(state),
        },
      },
      {
        Header: "Actual Qty (L/Kg)",
        id: "actualQuantity",
        withSort: false,
        accessor: "actualQuantity",
        cellWidth: 2.2,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "patchIds",
        filter: {
          title: "Patch",
          type: filterTypes.MultiSelect,
          selector: (state) => patchSelector(filters)(state),
        },
      },
      {
        Header: "Difference (L/Kg)",
        id: "difference",
        filterId: "activeIngredient",
        withSort: false,
        accessor: "difference",
        cellWidth: 2.2,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filter: {
          title: "Active ingredients",
          type: filterTypes.MultiSelect,
          selector: activeIngredientsSelector,
          withAsyncSearch: true,
          loaderAction: fetchActiveIngredients,
        },
      },
      {
        Header: "Total cost ($)",
        id: "totalCost",

        withSort: false,
        accessor: "totalCost",
        cellWidth: 2.4,
        Cell: ({ value, row }) =>
          row.canExpand ? (
            value || "-"
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value || "-"}
            </div>
          ),
        filterId: "matter",
        filter: {
          title: "Matter",
          type: filterTypes.SingleSelect,
          selector: matterOptionsSelector,
        },
      },
      {
        Header: "",
        id: "buttonContainer",
        withSort: false,
        accessor: "buttonContainer",
        filterId: "chemicalIds",
        filter: {
          title: "Product",
          type: filterTypes.MultiSelect,
          selector: chemicalsOptionsFilterSelector,
        },
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <div className={styles.exportIconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Export"}
                size="tiny"
                trigger={
                  <div className={styles.editButtonWrapper}>
                    <Button
                      onClick={async () => {
                        return (
                          await setExpandedRow(row.id),
                          handleExcelExport(row.id, filters)
                        );
                      }}
                      className={styles.editButton}
                      floated="right"
                      icon={<Icon name="download" />}
                      size="small"
                    />
                  </div>
                }
              />
            </div>
          ) : (
            <div
              className={styles.valueContainer}
              style={{ borderBottom: "1px solid #DEDEDF" }}
            >
              {value}
            </div>
          );
        },
      },
      {
        Header: "",
        id: "printButtonContainer",
        withSort: false,
        accessor: "printButtonContainer",
        filterId: "chemicalTypeIds",
        filter: {
          title: "Type",
          type: filterTypes.MultiSelect,
          selector: typeOptionsSelector,
        },
        Cell: ({ value, row }) => {
          return row.canExpand ? (
            <div className={styles.iconWrapper}>
              <Popup
                inverted
                position="bottom center"
                content={"Print"}
                size="tiny"
                trigger={
                  <Button
                    onClick={() => {
                      return setExpandedRow(row.id), printButton(true);
                    }}
                    className={styles.printButton}
                    floated="right"
                    icon={<Icon name="print" />}
                    size="small"
                  />
                }
              />
            </div>
          ) : (
            <div
              className={styles.valueContainer}
              style={{
                borderBottom: "1px solid #DEDEDF",
                borderRight: "1px solid #DEDEDF",
                marginRight: "15px",
              }}
            >
              {value}
            </div>
          );
        },
      },
      {
        accessor: "empty",
        id: "empty",
        filterId: "farmIds",
        filter: {
          title: "Farm",
          type: filterTypes.MultiSelect,
          selector: farmsOptionsFilterSelector,
        },
      },
    ],
    [filters]
  );

  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: location?.state?.reportNode,
      },
    });
  };
  return (
    <>
      <div style={{ display: "none" }}>
        {expandedRow ? (
          <PrintReport
            farmsList={farmsList}
            typeList={typeList}
            expandedRow={expandedRow}
            productsList={productsList}
            chemicals={filters.chemicalIds}
            farms={filters.farmIds}
            from={filters.from}
            type={filters.type}
            data={printData}
            to={filters.to}
            ref={componentRef}
          />
        ) : (
          <PrintReports
            farmsList={farmsList}
            productsList={productsList}
            chemicals={filters.chemicalIds}
            farms={filters.farmIds}
            from={filters.from}
            data={printData}
            to={filters.to}
            ref={componentRef}
          />
        )}
      </div>
      <TablePageHolder
        navigate={navigate}
        backButton={
          <Button
            floated="right"
            className={styles.backIconWrapper}
            onClick={handleBackClick}
          >
            <Icon className={styles.backIcon} name="angle left" />
          </Button>
        }
        actionsButtons={
          <>
            <Button
              size="small"
              floated="right"
              className={styles.printButtonForPage}
              onClick={() => {
                return setExpandedRow(false), handleExcelExport();
              }}
            >
              <div className={styles.iconWrapperForPage}>
                <Icon name="download" />
                Export
              </div>
            </Button>
            <Button
              size="small"
              floated="right"
              className={styles.printButtonForPage}
              onClick={() => {
                return setExpandedRow(false), printButton();
              }}
            >
              <div className={styles.iconWrapperForPage}>
                <Icon name="print" />
                Print
              </div>
            </Button>
          </>
        }
        rowId={expandedRow}
        withMoreButton={true}
        getData={getData}
        isFetching={tableData.isFetching}
        location={location}
        onRowClick={() => {}}
        open={open}
        setOpen={setOpen}
        setFilters={setFilters}
        pageTitle={route.name}
        tableType="report"
        route={route}
        tableColumns={columnsConfig}
        withSelection={false}
        tableData={tableData}
        withoutSort={false}
        firstColumnFixed={false}
        singleLine
        fixed={false}
        withTable={true}
        withHeader={true}
      />
    </>
  );
};

ProductTypeUsage.propTypes = {
  Can: PropTypes.func,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object,
};

export default connect((state, props) => {
  return {
    filterValues: state.packedBoxes.filters,
    productsList: state.chemical.list.content,
    isFetching: state.packedBoxes.data.isFetching,
    error: state.packedBoxes.error,
  };
})(ProductTypeUsage);

import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import {
  deleteQc,
  fetchQC,
  setActiveItemId,
  updateQC,
} from "actions/QualityControl/index";
import { activeItemSelector } from "selectors/qualityControl";
import HarvestUnitsImageModal from "../HarvestUnitsControl/components/Slider";
import { getQualityControlTableColumns } from "utils/qualityControl";
import { getHashParameters, removeHashParameters } from "utils/hashToObject";
import Sidebar from "./QualityControlSideBar/SideBar";
import styles from "./QualityControl.module.css";
import { Button } from "semantic-ui-react";
import AddSideBar from "./QualityControlSideBar/AddSideBar/AddSideBar";

const QualityControl = ({
  employees,
  isFetching,
  location,
  route,
  tableData,
  activeItem,
  navigate,
}) => {
  const dispatch = useDispatch();
  const [addsidebarOpen, setAddSidebarOpen] = useState(false);
  const [imageModalOpen, setimageModalOpen] = useState(false);
  const [blockName, setblockName] = useState(false);
  const [urlList, setUrlList] = useState(false);
  const [open, setOpen] = useState(false);
  let params = getHashParameters(location) || null;
  const getData = useCallback(
    (params) => {
      dispatch(fetchQC(params));
      dispatch(setActiveItemId(null));
    },
    [fetchQC]
  );
  const onRowClick = useCallback(
    (event, row) => {
      if (!activeItem && row) {
        dispatch(setActiveItemId(row.original.id));
      }
    },
    [activeItem, addsidebarOpen]
  );
  useEffect(() => {
    if (!activeItem && !!params.id && !!tableData.content.length) {
      dispatch(setActiveItemId(params.id));
    }
  }, [tableData, params.id]);
  const tableColumns = getQualityControlTableColumns(
    employees,
    setblockName,
    setimageModalOpen,
    setUrlList,
    isFetching
  );
  const onDeleteQc = () => {
    setActiveItemId(null);
    dispatch(deleteQc(activeItem?.id));
  };
  const onDeleteRounds = (data) => {
    dispatch(updateQC(activeItem?.id, data));
  };

  return (
    <>
      <HarvestUnitsImageModal
        open={imageModalOpen}
        units={tableData.content}
        blockName={blockName}
        urlList={urlList || []}
        onClose={() => {
          return setimageModalOpen(false);
        }}
      />
      <TablePageHolder
        navigate={navigate}
        sidebarComponent={
          addsidebarOpen ? (
            <AddSideBar
              activeItem={activeItem}
              onDeleteQc={onDeleteQc}
              onDeleteRounds={onDeleteRounds}
              employees={employees}
              setActiveItemId={setActiveItemId}
            />
          ) : (
            <Sidebar
              activeItem={activeItem}
              onDeleteQc={onDeleteQc}
              onDeleteRounds={onDeleteRounds}
              employees={employees}
              setActiveItemId={setActiveItemId}
            />
          )
        }
        mainButton={
          <>
            <Button
              primary
              className={styles.addButton}
              onClick={() => setAddSidebarOpen(true)}
            >
              Add QC task
            </Button>
          </>
        }
        onSidebarHidden={() => {
          return (
            removeHashParameters(location, ["id"], null, navigate),
            dispatch(setActiveItemId(null)),
            setAddSidebarOpen(false)
          );
        }}
        sidebarShown={!!activeItem || !!params.id || !!addsidebarOpen}
        rowId={false}
        getData={getData}
        isFetching={isFetching}
        location={location}
        onRowClick={onRowClick}
        open={open}
        setOpen={setOpen}
        pageTitle={route.name}
        route={route}
        tableColumns={tableColumns}
        withSelection={false}
        tableData={tableData}
        withoutSort={false}
        firstColumnFixed={true}
        singleLine
        fixed={false}
      />
    </>
  );
};

QualityControl.propTypes = {
  employees: PropTypes.array,
  isFetching: PropTypes.bool,
  location: PropTypes.object,
  route: PropTypes.object,
  tableData: PropTypes.array,
};

export default connect((state, props) => {
  return {
    employees: state.employee.list.content,
    tableData: state.qualityControl.data.content,
    isFetching: state.qualityControl.data.isFetching,
    activeItem: activeItemSelector(state),
  };
})(QualityControl);

import PropTypes from "prop-types";
import React, { useState } from "react";
import { Container, Grid, Header } from "semantic-ui-react";
import moment from "moment";

import PatchInfo from "./components/PatchInfo";

import CollapseBlock from "../../../components/CollapseBlock";
import SprayTasksCostsBreakdown from "../../../components/SprayTasksCostsBreakdown";
import { getChemicalsBreakdownForPatch } from "../../../actions/Farms/farms";

import "./index.css";
import RowsReport from "./components/RowsReport";
import SprayTasks from "./components/SprayTasks";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import GeneralTasksCostsBreakdown from "../../../components/GeneralTasksCostsBreakdown";
import TotalCostsBreakdown from "./components/TotalCostsBreakdown";
import ScannedTagBarcodes from "../../../components/ScannedTagBarcodes";
import DatePicker from "../../../components/DatePicker";
import styles from "../../../components/Filters/Filters.module.css";
import Irrigation from "./components/Irrigation";
import OperatingCostsBreakdown from "./components/OperatingCostBreakDown/OperatingCostsBreakdown";

const getSummaryPatchInfo = (patch) => {
  const today = moment();

  const { previousVarieties, futureVarieties } = patch.varieties.reduce(
    (prev, variety) => {
      const temp = { ...prev };

      today.isBefore(moment(variety.plantingAt))
        ? (temp.previousVarieties = [...temp.previousVarieties, variety])
        : (temp.futureVarieties = [...temp.futureVarieties, variety]);

      return temp;
    },
    { previousVarieties: [], futureVarieties: [] }
  );

  let currentVariety = patch.varieties
    .map((variety) => {
      const plantingMoment = moment(variety.plantingAt);
      const pickingMoment = moment(
        variety.pickingAt ? variety.pickingAt : today.add(1, "day")
      );
      /*  if (today.isBetween(plantingMoment, pickingMoment)) */ return variety;

      return false;
    })
    .filter((i) => !!i);

  // For the multiple patches Block
  if (patch.type === "BLOCK" && patch.hasSubAreas) {
    // If the only Crop’s Start date is in the future, display the future crop
    if (
      !currentVariety &&
      patch.varieties.length === 1 &&
      today.isBefore(moment(patch.varieties[0].plantingAt))
    )
      currentVariety = patch.varieties[0];

    // If the previous Crop picking date is in the past AND the future Crop Start date is in the future, display the future Crop
    if (!currentVariety && futureVarieties.length)
      currentVariety = futureVarieties[0];
    // If the planting End date of the last Crop is in the past, display this last crop as a current crop - Approved
    if (!currentVariety && previousVarieties.length)
      currentVariety = previousVarieties[0];
  }

  // For single patch block
  if (patch.type === "BLOCK" && !patch.hasSubAreas) {
    // display the only Crop/variety it has as a current crop
    if (!currentVariety && patch.varieties.length === 1)
      currentVariety = patch.varieties[0];
  }

  currentVariety = currentVariety || {};
  return !!currentVariety.length
    ? currentVariety.map((item) => ({
        size: patch.size || "-",
        currentCrop: item.variety ? item.variety.crop.name : "-",
        variety: item.variety ? item.variety.name : "-",
        plantedDate: item.plantingAt ? item.plantingAt : "-",
        pickingAt: item.pickingAt ? item.pickingAt : "-",
        numberOfPlants: item.numberOfPlants ? item.numberOfPlants : "-",
        withholdingPeriod: item.withholdingDays ? item.withholdingDays : "-",
        withholdingDays: patch.withholdingDays,
        withholdingPeriodDate: patch.withholdingPeriodDate,
        reentryDate: patch.reentryDate,
        reentryDays: patch.reentryDays,
        // varieties: patch.varieties
      }))
    : [
        {
          size: patch.size || "-",
        },
      ];
};

const PatchSidebar = ({
  patch,
  navigate,
  actions: { getChemicalsBreakdownForPatch },
}) => {
  const patchSummary = getSummaryPatchInfo(patch);
  const [sprayTasksSum, setTotalChemicalsSum] = useState(0);
  const [generalTasksSum, setGeneralTasksSum] = useState(0);
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);

  // Banana (id: 16)
  const thereAreBananas = patch.varieties.some(
    ({ variety }) => variety.crop.id === 16
  );

  return (
    <Container style={{ marginTop: 16 }}>
      <Header as="h1" className={"border"}>
        {patch.name}
      </Header>
      <PatchInfo className={"border"} patch={patchSummary} />
      <Grid style={{ marginTop: 10, marginBottom: 10 }}>
        <Grid.Row style={{ paddingBottom: 0 }}>
          <Header style={{ paddingLeft: 14 }} as={"h3"}>
            Select Dates:
          </Header>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 5 }}>
          <Grid.Column computer={8}>
            <DatePicker
              size="small"
              value={dateFrom}
              onChange={(date) => setDateFrom(date)}
              labelText={"From"}
              className={styles.datePicker}
            />
          </Grid.Column>
          <Grid.Column computer={8}>
            <DatePicker
              size="small"
              value={dateTo}
              onChange={(date) => setDateTo(date)}
              labelText={"To"}
              className={styles.datePicker}
              minDate={dateFrom}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <CollapseBlock
        fontSize={22}
        defaultClosed
        title={"Operations"}
        className={"border margin-bottom-20"}
      >
        <SprayTasks patch={patch} dateTo={dateTo} dateFrom={dateFrom} />
      </CollapseBlock>
      <CollapseBlock
        fontSize={22}
        defaultClosed
        title={"Harvest"}
        className={"border margin-bottom-20"}
      >
        <RowsReport area={patch} dateTo={dateTo} dateFrom={dateFrom} />
      </CollapseBlock>
      {thereAreBananas && (
        <CollapseBlock
          fontSize={22}
          defaultClosed
          title={"Tag Scanning Report"}
          className={"border margin-bottom-20"}
        >
          <ScannedTagBarcodes
            navigate={navigate}
            area={patch}
            dateFrom={dateFrom}
            dateTo={dateTo}
            excludeFilters={["tag_date"]}
          />
        </CollapseBlock>
      )}
      <CollapseBlock fontSize={22} defaultClosed title={"Costs"}>
        <SprayTasksCostsBreakdown
          navigate={navigate}
          area={patch}
          breakdownTitle={"Application Tasks Cost Breakdown:"}
          getChemicalsBreakdown={(dateFrom, dateTo) =>
            getChemicalsBreakdownForPatch(patch.id, dateFrom, dateTo)
          }
          dateFrom={dateFrom}
          dateTo={dateTo}
          setSummary={setTotalChemicalsSum}
          excludeFilters={["date"]}
        />
        <br />
        <GeneralTasksCostsBreakdown
          navigate={navigate}
          area={patch}
          dateFrom={dateFrom}
          dateTo={dateTo}
          patchName={patch.name}
          breakdownTitle={"Tasks Costs Breakdown:"}
          setGeneralTasksSummary={setGeneralTasksSum}
          excludeFilters={["task_costs_date"]}
        />
        <br />
        <TotalCostsBreakdown
          itemSize={patch.size}
          sprayTasksSum={sprayTasksSum}
          generalTasksSum={generalTasksSum}
        />
        <br />
        <OperatingCostsBreakdown expandedTable />
        <br />
        <Irrigation />
      </CollapseBlock>
    </Container>
  );
};

const mapStateToProps = ({ farms: { sprayTasksForArea } }) => ({
  sprayTasksForArea,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ getChemicalsBreakdownForPatch }, dispatch),
});

PatchSidebar.propTypes = {
  patch: PropTypes.object.isRequired,
  actions: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(PatchSidebar);

import React, { useCallback, useRef, useState } from "react";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import {
  fetchScoutingTasks,
  fetchScoutingFilterData,
  setCurrentScouting,
  archiveScoutingTasks,
} from "actions/Scouting/scouting";
import { abilitiesSelector } from "selectors/user";
import { activeItemSelector } from "selectors/scouting";
import { getScoutingTableColumns } from "utils/scoutingNew";
import { Button, Container, Icon } from "semantic-ui-react";
import TablePageHolder from "components/TablePageHolder/TablePageHolder";
import { chemicalShape } from "constants/Chemicals/types";
import { useReactToPrint } from "react-to-print";
import ScoutingSidebar from "../ScoutingList/components/ScoutingSidebar";
import styles from "./Scouting.module.css";
import ScoutingReport from "../ScoutingList/components/ScoutingReport/ScoutingReport";
import { keyBy } from "lodash";

const ScountingNew = React.forwardRef(
  (
    {
      Can,
      isArchivedView,
      location,
      route,
      isPrinting,
      isFetching,
      tableData,
      currentScouting,
      activeItem,
      navigate,
      filterData,
    },
    ref
  ) => {
    const componentRef = useRef();
    const [selectedItemsIds, setSelectedItems] = useState([]);
    const [archivedStatus, setArchivedStatus] = useState(false);
    const [itemsToPrint, setItemsToPrint] = useState(null);

    const tableColumns = getScoutingTableColumns(isFetching);

    const dispatch = useDispatch();
    const getData = useCallback(
      (params) => {
        !filterData && dispatch(fetchScoutingFilterData());
        setSelectedItems([]);
        dispatch(fetchScoutingTasks({ ...params }));
        setArchivedStatus(false);
      },
      [fetchScoutingTasks, filterData]
    );
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      ref: ref,
    });
    const printButton = () => {
      handlePrint();
    };
    const onRowClick = useCallback(
      (event, row) => {
        if (!activeItem && row) {
          dispatch(setCurrentScouting(row.original));
        }
      },
      [activeItem]
    );
    const onArchiveClick = useCallback(() => {
      const idsMap = keyBy(selectedItemsIds);
      const itemsIds = tableData.content
        .filter((_, index) => !!idsMap[index])
        .map(({ id }) => id);
      setSelectedItems([]);
      dispatch(archiveScoutingTasks(itemsIds));
      setArchivedStatus(true);
    }, [selectedItemsIds, tableData.content]);

    return (
      <>
        <div style={{ display: "none" }}>
          <ScoutingReport
            ref={componentRef}
            scoutingList={
              selectedItemsIds.length
                ? tableData.content.filter((item, index) => {
                    return selectedItemsIds.includes(`${index}`);
                  })
                : [activeItem]
            }
          />
        </div>
        <TablePageHolder
          actionsButtons={
            <Container className={styles.container}>
              <Button
                basic
                className={styles.actionButton}
                color="green"
                size="large"
                disabled={!selectedItemsIds.length || isArchivedView}
                onClick={() => onArchiveClick()}
              >
                <Icon name="archive" /> Archive
              </Button>
              <Button
                basic
                className={styles.actionButton}
                color="green"
                size="large"
                disabled={!selectedItemsIds.length || isPrinting}
                onClick={() => {
                  printButton();
                }}
              >
                <span>
                  {!isPrinting ? (
                    <Icon name="print" />
                  ) : (
                    <Icon name="circle notch loading icon" />
                  )}
                </span>
                Print
              </Button>
            </Container>
          }
          getData={getData}
          navigate={navigate}
          isFetching={isFetching}
          location={location}
          withoutSearch={true}
          onRowClick={onRowClick}
          onSidebarHidden={() => dispatch(setCurrentScouting(null))}
          sidebarShown={!!activeItem}
          pageTitle={route.name}
          route={route}
          selectedItems={selectedItemsIds}
          setSelectedItems={setSelectedItems}
          sidebarComponent={
            <ScoutingSidebar
              navigate={navigate}
              onPrintClick={() => printButton()}
              isPrinting={isPrinting}
              scouting={currentScouting}
              Can={Can}
              onClose={() => dispatch(setCurrentScouting(null))}
            />
          }
          tableColumns={tableColumns}
          tableData={tableData.content}
          ref={ref} // Ensure ref is passed here
        />
      </>
    );
  }
);

ScountingNew.propTypes = {
  Can: PropTypes.func,
  isArchivedView: PropTypes.bool,
  tableData: PropTypes.shape({
    content: PropTypes.arrayOf(chemicalShape),
  }).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  fetchChemicals: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  route: PropTypes.object,
  filterValues: PropTypes.object,
  location: PropTypes.object,
};

export default connect((state, props) => {
  const isArchivedView = props.location.pathname.includes("archived");

  return {
    isArchivedView,
    Can: abilitiesSelector(state),
    tableData: state.scouting.data,
    filterData: state.scouting.filterData,
    isPrinting: state.scouting.isPrinting,
    isFetching: state.scouting.isFetching,
    isDeleting: state.scouting.isDeleting,
    data: state.scouting.data,
    currentScouting: state.scouting.currentScouting,
    activeItem: activeItemSelector(state),
  };
})(ScountingNew);

import React, { useState } from "react";
import styles from "./CompletionModal.module.css";
import SecondStepForm from "./StepTwoForm";
import { Segment, Grid, Icon, Radio } from "semantic-ui-react";
import { useFormikContext } from "formik";
import moment from "moment";
import { employeeViewer } from "routes/Tasks/components/helper";
const DetailsSection = ({
  areas,
  filtredArea,
  employeeList,
  machineryList,
  setErrorChecker,
  onDelete,
  selectedAssigne,
  detailsOpen,
  setDetailsOpen,
  setOpenSections,
  openSections
}) => {
  const { setFieldValue, values } = useFormikContext();

  const from = `${moment(values.start).format("yyyy-MM-DD")}T${
    values.wtStart
  }:00`;
  const to = `${moment(values.end).format("yyyy-MM-DD")}T${values.wtEnd}:00`;
  const startDate = new Date(from);
  const endDate = new Date(to);
  const differenceMs = endDate - startDate;
  const differenceHours = differenceMs / (1000 * 60 * 60);

  const toggleSection = (areaIndex, index) => {
    const sectionKey = `${areaIndex}-${index}`;
    setOpenSections(prevOpenSections => ({
      ...prevOpenSections,
      [sectionKey]: !prevOpenSections[sectionKey]
    }));
  };

  const isSectionOpen = (areaIndex, index) => {
    const sectionKey = `${areaIndex}-${index}`;
    return openSections[sectionKey] || false;
  };
  return (
    <>
      <SecondStepForm
        fromDetail={false}
        setErrorChecker={setErrorChecker}
        differenceHours={differenceHours}
        disabled={detailsOpen}
      />
      <div className={styles.detailsBar}>
        <div className={styles.progressBarHeader}>
          Specify the time for each area and / or employee
        </div>
        <Radio
          className={styles.checkBox}
          onChange={() => (
            setOpenSections({}),
            setDetailsOpen(!detailsOpen),
            values.blocks.map((area, areaIndex) => {
              return area.assignee.map((a, assigneIndex) => {
                return a.workingTime.map((wt, wtInd) => {
                  return setFieldValue(
                    `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]`,
                    {
                      end: values.end,
                      lunchInterval: values.lunchInterval,
                      noOfPieces: values.noOfPieces,
                      price: values.price,
                      start: values.start,
                      wtEnd: values.wtEnd,
                      wtStart: values.wtStart
                    }
                  );
                });
              });
            })
          )}
          toggle
        />
      </div>
      {areas.map((item, areaIndex) => {
        return (
          <div>
            <div className={styles.areaWrapper}>
              {filtredArea[areaIndex]?.name}
            </div>
            {item.assignee.map((assignee, index) => {
              const detailedItem = values?.blocks[areaIndex]?.assignee[index];
              const from = `${moment(detailedItem?.start).format(
                "yyyy-MM-DD"
              )}T${detailedItem?.wtStart}:00`;
              const to = `${moment(detailedItem?.end).format("yyyy-MM-DD")}T${
                detailedItem?.wtEnd
              }:00`;
              const startDate = new Date(from);
              const endDate = new Date(to);
              const differenceMs = endDate - startDate;
              const differenceHours = differenceMs / (1000 * 60 * 60);

              const employee = employeeList.find(
                item => item.id === selectedAssigne[index]
              );
              const machinery = machineryList.find(
                item => item.id === values.equipments[index]
              );
              const isOpen = isSectionOpen(areaIndex, index);

              return (
                <Grid container as={Segment} className={styles.wrapper}>
                  <Grid.Row className={styles.wrapperRow}>
                    <div className={styles.iconContainer}>
                      <Grid.Column className={styles.chevronContainer}>
                        <div
                          disabled={!detailsOpen}
                          onClick={() =>
                            detailsOpen && toggleSection(areaIndex, index)
                          }
                          className={`${styles.toggleIcon} ${
                            isOpen ? styles.openTable : ""
                          }`}
                        >
                          <Icon
                            disabled={!detailsOpen}
                            className={
                              isOpen ? "tuf-chevron-down" : "tuf-chevron-right"
                            }
                          />
                        </div>
                      </Grid.Column>
                    </div>
                    <div className={styles.columnContainer}>
                      <Grid.Column
                        style={{ opacity: !detailsOpen ? 0.45 : 1 }}
                        className={styles.nameColumn}
                      >
                        <label className={styles.productHeader}>
                          {employeeViewer(
                            [{ employeeId: selectedAssigne[index] }],
                            employeeList
                          )}
                          {`${employee?.firstName} ${employee?.lastName}`}
                        </label>
                      </Grid.Column>
                      <Grid.Column className={styles.nameColumn}>
                        <label className={styles.productHeader}>
                          {machinery?.name || "-"}
                        </label>
                      </Grid.Column>
                      <Grid.Column
                        style={{ opacity: !detailsOpen ? 0.45 : 1 }}
                        className={styles.iconColumn}
                      >
                        <Icon
                          disabled={item.assignee.length < 2}
                          onClick={() => {
                            return (
                              setFieldValue(
                                `blocks`,
                                values.blocks.map(item => ({
                                  assignee: item.assignee.filter(
                                    (i, employeeIndex) =>
                                      index !== employeeIndex
                                  )
                                }))
                              ),
                              setFieldValue(
                                "assignees",
                                values.assignees.filter((_, i) => index !== i)
                              ),
                              setFieldValue(
                                "equipments",
                                values.equipments.filter((_, i) => index !== i)
                              ),
                              onDelete(index)
                            );
                          }}
                          className={styles.deleteBtn}
                          name="trash alternate"
                        />
                      </Grid.Column>
                    </div>
                  </Grid.Row>
                  {isOpen &&
                    assignee.workingTime.map((item, wtIndex) => {
                      const handleUpdateValue = (
                        activeField,
                        updatedValues
                      ) => {
                        return setFieldValue(
                          `blocks[${areaIndex}]assignee[${index}]workingTime[${wtIndex}]${activeField}`,
                          updatedValues
                        );
                      };
                      return (
                        <Grid.Row style={{ opacity: 1 }}>
                          <SecondStepForm
                            disabled={!detailsOpen}
                            setErrorChecker={setErrorChecker}
                            handleUpdateValue={handleUpdateValue}
                            differenceHours={differenceHours}
                            areaIndex={areaIndex}
                            index={index}
                            fromDetail={true}
                            area={item}
                            wtIndex={wtIndex}
                          />
                        </Grid.Row>
                      );
                    })}
                  {isOpen && (
                    <div className={styles.wtAddBtnWrapper}>
                      <div
                        className={styles.wtAddBtn}
                        onClick={() => {
                          return setFieldValue(
                            `blocks[${areaIndex}]assignee[${index}]workingTime`,
                            [
                              ...assignee.workingTime,
                              {
                                start: null,
                                end: null,
                                wtStart: null,
                                wtEnd: null,
                                price: 0,
                                noOfPieces: 0,
                                lunchInterval: 0
                              }
                            ]
                          );
                        }}
                      >
                        + Add working time
                      </div>
                    </div>
                  )}
                </Grid>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

export default DetailsSection;

import { actionType } from "../../constants/QualityControl";

const initialState = {
  data: { content: [] },
  qcForm: {
    name: "",
    employeeIds: [],
    fields: []
  },
  parameters: [],
  activeItem: {
    id: null,
    isEditing: false
  },
  isFetching: false,
  error: null
};

const CHECKBOX_INPUT = {
  type: "CHECKBOX",
  label: ""
};

export default (state = initialState, action) => {
  if (!action) {
    return state;
  }

  switch (action.type) {
    case actionType.PARAMETERS_QC_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.FETCH_QC_FORMS_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    case actionType.FETCH_QC_FORMS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
          isFetching: false
        }
      };
    case actionType.QC_FORM_CHANGE:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          [action.payload.name]: action.payload.value
        }
      };

    case actionType.SET_ACTIVE_ITEM_ID:
      return {
        ...state,
        activeItem: {
          id: action.payload,
          error: null
        }
      };
    case actionType.FETCH_QC_START:
    case actionType.ADD_QC_START:
    case actionType.UPDATE_QC_START:
    case actionType.DELETE_QC_START:
      return {
        ...state,
        data: {
          ...state.data,
          isFetching: true
        }
      };
    case actionType.FETCH_QC:
      return {
        ...state,
        data: {
          ...state.data,
          content: action.payload,
          isFetching: false
        }
      };
    case actionType.ADD_QC:
      return {
        ...state,
        data: {
          ...state.data,
          content: [...state.data.content, action.payload],
          isFetching: false
        }
      };
    case actionType.UPDATE_QC:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(item => {
            return item.id === action.payload.id ? action.payload : item;
          }),
          isFetching: false
        }
      };
    case actionType.DELETE_QC:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(item => {
            return item.id !== action.payload;
          }),
          isFetching: false
        }
      };
    case actionType.QC_FORM_SET_FORM_SET:
      return {
        ...state,
        qcForm: action.payload
      };
    case actionType.ADD_FORM_INPUT:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          fields: [...state.qcForm.fields, CHECKBOX_INPUT]
        }
      };
    case actionType.EDIT_FORM_INPUT:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          fields: state.qcForm.fields.map((field, i) => {
            if (i === action.payload.id)
              return {
                ...field,
                [action.payload.name]: action.payload.value
              };

            return field;
          })
        }
      };
    case actionType.REMOVE_FORM_INPUT:
      return {
        ...state,
        qcForm: {
          ...state.qcForm,
          fields: state.qcForm.fields.filter((_, i) => i !== action.payload)
        }
      };

    case actionType.QC_FORM_CLEAR:
      return {
        ...state,
        qcForm: initialState.qcForm
      };

    case actionType.QC_FORM_ARCHIVE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(form => {
            if (form.id === action.payload.id)
              return { ...form, archived: !form.archived };
            return form;
          })
        }
      };

    case actionType.QC_FORM_UPDATE:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.map(form => {
            if (form.id === action.payload.id) return action.payload;
            return form;
          })
        }
      };
    case actionType.PARAMETERS_QC:
      return {
        ...state,
        parameters: action.payload,

        isFetching: false
      };
    case actionType.QC_CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case actionType.QC_FORM_CREATE_FAIL:
    case actionType.ADD_QC_FAIL:
    case actionType.UPDATE_QC_FAIL:
    case actionType.QC_FORM_UPDATE_FAIL:
    case actionType.QC_FORM_ARCHIVE_FAIL:
    case actionType.FETCH_QC_FORMS_FAIL:
    case actionType.DELETE_QC_FAIL:
    case actionType.FETCH_QC_FAIL:
    case actionType.PARAMETERS_QC_FAIL:
      return {
        ...state,
        error: action.payload,
        data: {
          ...state.data,
          isFetching: false
        }
      };

    default:
      return state;
  }
};

import React, { useState, useEffect, useCallback } from "react";
import DatePicker from "react-multi-date-picker";
import moment from "moment";
import { Icon, Input } from "semantic-ui-react";
import "./DateRangePicker.css";
import useOnClickOutside from "./useOutside";

const DateRangePickerComponent = ({
  setFieldValue,
  values,
  fromDetail,
  handleUpdateValue,
  detailedItem,
  disabled
}) => {
  const dateTimeRef = React.useRef();
  const [dates, setDates] = useState([]);
  const dateStart = !fromDetail ? values.start : detailedItem.start;
  const dateEnd = !fromDetail ? values?.start : detailedItem?.end;
  const handleDatePickerClose = useCallback(
    () => dateTimeRef.current.closeCalendar(),
    [dateTimeRef]
  );
  useEffect(() => {
    if (dateStart && dateEnd) {
      const changeDate = [
        new Date(moment(dateStart).format("YYYY/MM/DD")),
        new Date(moment(dateEnd).format("YYYY/MM/DD"))
      ];
      setDates(changeDate);
    }
  }, []);
  const handleChange = (a, b) => {
    const start = b.validatedValue[0];
    const end = b.validatedValue[1];
    return !fromDetail
      ? (values.blocks.map((area, areaIndex) => {
          return area.assignee.map((a, assigneIndex) => {
            return a.workingTime.map((wt, wtInd) => {
              return setFieldValue(
                `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]start`,
                `${moment(start, "DD/MM/YYYY").format("YYYY-MM-DD")}T00:00:00`
              );
            });
          });
        }),
        values.blocks.map((area, areaIndex) => {
          return area.assignee.map((a, assigneIndex) => {
            return a.workingTime.map((wt, wtInd) => {
              return setFieldValue(
                `blocks[${areaIndex}]assignee[${assigneIndex}]workingTime[${wtInd}]end`,
                `${moment(end, "DD/MM/YYYY").format("YYYY-MM-DD")}T23:59:59`
              );
            });
          });
        }),
        setFieldValue(
          "start",
          `${moment(start, "DD/MM/YYYY").format("YYYY-MM-DD")}T00:00:00`
        ),
        setFieldValue(
          "end",
          `${moment(end, "DD/MM/YYYY").format("YYYY-MM-DD")}T23:59:59`
        ))
      : (handleUpdateValue(
          "start",
          `${moment(start, "DD/MM/YYYY").format("YYYY-MM-DD")}T00:00:00`
        ),
        handleUpdateValue(
          "end",
          `${moment(end, "DD/MM/YYYY").format("YYYY-MM-DD")}T23:59:59`
        ));
  };
  useOnClickOutside(dateTimeRef, handleDatePickerClose);
  return (
    <DatePicker
      ref={dateTimeRef}
      disabled={disabled}
      className="range-calendar"
      value={dates}
      onChange={(a, b) => handleChange(a, b)}
      range
      format={"DD/MM/YYYY"}
      calendarPosition="bottom-center"
      numberOfMonths={2}
      render={
        <Input
          placeholder={"dd/mm/yyyy-dd/mm/yyyy"}
          icon={<Icon name="calendar outline" />}
          iconPosition="right"
        />
      }
    />
  );
};

export default DateRangePickerComponent;

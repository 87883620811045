import React, { useState } from "react";
import {
  Icon,
  Segment,
  Checkbox,
  Form,
  Label,
  Dropdown
} from "semantic-ui-react";
import moment from "moment";
import Datetime from "react-datetime";
import { areaFilterOptions } from "selectors/areas";
import styles from "../../ClockIn.module.css";
import { useSelector } from "react-redux";
import { employeeViewer } from "routes/Tasks/components/helper";
import AreasList from "routes/WorkingTime/components/AreasList";

const AllEmployees = ({
  employee,
  values,
  setFieldValue,
  setValues,
  areas
}) => {
  const [openRows, setOpenRows] = useState({});
  const areasOptions = useSelector(areaFilterOptions).options.map(item => ({
    ket: item.key,
    text: item.label,
    value: item.value
  }));
  const toggleRow = id => {
    setOpenRows(prevOpenRows => ({
      ...prevOpenRows,
      [id]: !prevOpenRows[id]
    }));
  };
  return (
    <>
      <div className={styles.wrapper}>
        {employee.map(item => (
          <div key={item.id} className={styles.employeeRow}>
            <Checkbox
              onClick={() => {
                return values.children.map(e => e.employeeId).includes(item.id)
                  ? setFieldValue(
                      "children",
                      values.children.filter(i => i.employeeId !== item.id)
                    )
                  : setFieldValue("children", [
                      ...values.children,
                      {
                        employeeId: item.id,
                        generalTaskId: values.generalTaskId,
                        areas: values.areas,
                        startAt: values.startAt
                      }
                    ]);
              }}
              className={styles.checkBox}
              checked={values.children.map(i => i.employeeId).includes(item.id)}
            />
            {`${item.firstName} ${item.lastName}`}
          </div>
        ))}
      </div>
      {values.children.map((item, index) => {
        const activeEmployee = employee.find(e => e.id === item.employeeId);
        return (
          <Segment key={item.employeeId} className={styles.segment}>
            <div className={styles.rowFieldWrapper}>
              <div className={styles.employeeIconWrapper}>
                <span className={styles.iconRowField}>
                  <Icon
                    className={styles.rowChevronIcon}
                    onClick={() => toggleRow(item.employeeId)}
                    name={
                      openRows[item.employeeId] ? "chevron up" : "chevron down"
                    }
                  />
                </span>
                <span className={styles.nameRowField}>
                  {employeeViewer([{ employeeId: item.employeeId }], employee)}
                  {`${activeEmployee.firstName} ${activeEmployee.lastName}`}
                </span>
              </div>
              <span className={styles.iconRowField}>
                <Icon
                  onClick={() =>
                    setFieldValue(
                      "children",
                      values.children.filter(
                        i => i.employeeId !== item.employeeId
                      )
                    )
                  }
                  className={styles.deleteIcon}
                  name="trash alternate"
                />
              </span>
            </div>
            {openRows[item.employeeId] && (
              <div className={styles.openRows}>
                <Form.Group widths={16}>
                  <Form.Field required width={8}>
                    <label className={styles.formLabel}>Date and time</label>
                    <div className={styles.calendarWrapper}>
                      <Datetime
                        value={
                          values.children[index].startAt
                            ? moment(values.children[index].startAt)
                            : null
                        }
                        dateFormat="DD/MM/YYYY"
                        timeFormat="HH:mm"
                        inputProps={{
                          placeholder: "dd/mm/yyyy hh:mm",
                          readOnly: false
                        }}
                        onChange={selectedDate => {
                          const formattedDate = selectedDate
                            ? moment(selectedDate).format("YYYY-MM-DDTHH:mm:ss")
                            : null;
                          setFieldValue(
                            `children[${index}].startAt`,
                            formattedDate
                          );
                        }}
                        className={styles.expirCalendar}
                      />
                      <div className={styles.calendarIcon}>
                        <Icon name="calendar outline" />
                      </div>
                    </div>
                  </Form.Field>
                  <Form.Field required width={8}>
                    <label className={styles.formLabel}>Areas </label>
                    <AreasList
                      text={
                        !!values.children[index]?.areas?.length &&
                        `${values.children[index]?.areas?.length} areas`
                      }
                      onChange={(_, data) => {
                        return setFieldValue(`children[${index}].areas`, [
                          ...values.children[index]?.areas,
                          data
                        ]);
                      }}
                      options={areasOptions.filter(
                        i => !values.children[index]?.areas.includes(i.value)
                      )}
                    />
                  </Form.Field>
                </Form.Group>
                <Form.Group>
                  <Form.Field width={8} />
                  <Form.Field width={8}>
                    <Form.Group>
                      {!!values.children[index]?.areas?.length && (
                        <Form.Field>
                          <div className={styles.areasRow}>
                            <span className={styles.areasRowHead}>Areas:</span>
                            {values.children[index]?.areas?.map(item => {
                              return areas.map(({ id, name, parent }) => {
                                return (
                                  id === (item?.areaId || item) && (
                                    <Label className={styles.addedItem}>
                                      <span className={styles.addedItemValue}>
                                        {" "}
                                        {parent
                                          ? `${parent.name}/ ${name}`
                                          : name}
                                      </span>
                                      <Icon
                                        className={styles.closeIcon}
                                        name="close"
                                        onClick={() => {
                                          const filteredValues = values.children[
                                            index
                                          ]?.areas?.filter(i => item !== i);
                                          setFieldValue(
                                            `children[${index}].areas`,
                                            filteredValues
                                          );
                                        }}
                                      />
                                    </Label>
                                  )
                                );
                              });
                            })}
                          </div>
                        </Form.Field>
                      )}
                    </Form.Group>
                  </Form.Field>
                </Form.Group>
              </div>
            )}
          </Segment>
        );
      })}
    </>
  );
};

export default AllEmployees;

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Dropdown,
  Grid,
  Segment,
  Icon,
  Checkbox,
  Popup,
} from "semantic-ui-react";
import TaskCard from "./TaskCard";
import logo from "assets/img/tuf_logo.svg";
import moment from "moment";
import styles from "./CardTable.module.css";
import { fetchGeneralTaskList, updateGeneralTask } from "actions/Tasks";
import DateModal from "./DateModal";
import { activeItemSelector } from "selectors/generalTasks";

const CardTable = ({
  list,
  data,
  selectMode,
  setSelectMode,
  selectedItemsIds,
  handleSelectAllItem,
  handleSelectChange,
  selectedItemsIdsChange,
  isArchivedView,
  handleTaskIdClick,
  onArchiveClick,
  onDeleteClick,
  onTaskPrint,
  onRowClick,
  navigate,
}) => {
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [taskData, setTaskData] = useState({});
  const localTime = moment().format("YYYY-MM-DDTHH:mm:ss");
  const dispatch = useDispatch();

  const dropdown = (activeTabName) => (
    <Dropdown
      className={styles.selectionDropdown}
      icon="ellipsis horizontal"
      iconposition="right"
      floating
    >
      <Dropdown.Menu direction="left">
        <Dropdown.Item
          className={styles.dropdownItem}
          onClick={() => {
            selectedItemsIdsChange([]);
            setActiveTab(activeTabName);
            if (activeTabName === activeTab) {
              setSelectMode(!selectMode);
            } else {
              setSelectMode(true);
            }
          }}
        >
          {selectMode && activeTabName === activeTab
            ? "Cancel selection"
            : "Select"}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const archiveIcon = () => (
    <div style={{ display: "flex", justifyContent: "space-around" }}>
      <Icon
        onClick={() => onArchiveClick(null)}
        disabled={!selectedItemsIds.length}
        className={styles.archiveIcon}
        name="archive"
      />
      {isArchivedView && (
        <Icon
          onClick={() => onDeleteClick(null)}
          disabled={!selectedItemsIds.length}
          className={styles.taskDeleteIcon}
          name="trash alternate"
        />
      )}
    </div>
  );

  const renderTaskCards = (status, color) => {
    const filteredTasks = data.content.filter((task) => task.status === status);

    return filteredTasks.length ? (
      filteredTasks.map((item) => {
        const completedAreas = item.areas.filter((item) => item.completed);
        const progressPrecent = Math.ceil(
          (completedAreas.length * 100) / item.areas.length
        );
        return (
          <TaskCard
            navigate={navigate}
            key={item.id}
            progressPrecent={`${progressPrecent}%`}
            isArchivedView={isArchivedView}
            handleTaskIdClick={handleTaskIdClick}
            onArchiveClick={onArchiveClick}
            onRowClick={onRowClick}
            onDeleteClick={onDeleteClick}
            onTaskPrint={onTaskPrint}
            activeTab={activeTab}
            activeTabName={status}
            handleSelectChange={handleSelectChange}
            selectedItemsIds={selectedItemsIds}
            selectMode={selectMode}
            item={item}
            localTime={localTime}
            color={color}
          />
        );
      })
    ) : (
      <div className={styles.card}>
        <div className={styles.emptyTableWrapper}>
          <img src={logo} alt="logo" />
          <span className={styles.emptyTableText}>
            {` You have no tasks ${(
              status
                .toLowerCase()
                .charAt(0)
                .toUpperCase() + status.toLowerCase().slice(1)
            ).replace(/_/g, " ")} now.`}
            <p>
              Drag the card here to give it "
              {(
                status
                  .toLowerCase()
                  .charAt(0)
                  .toUpperCase() + status.toLowerCase().slice(1)
              ).replace(/_/g, " ")}
              " status
            </p>
          </span>
        </div>
      </div>
    );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = async (e, status) => {
    const itemId = e.dataTransfer.getData("itemId");
    const updateTask = data.content.find((item) => item.id == itemId);

    if (updateTask.expireAt > localTime || status === "COMPLETED") {
      const dataToSend = {
        areas: updateTask.areas,
        assignedTo: updateTask.assignedTo,
        attachments: updateTask.attachments,
        endAt: updateTask.endAt,
        equipments: updateTask.equipments,
        flowRate: updateTask.flowRate,
        generalTaskTypeId: updateTask.generalTaskTypeId,
        name: updateTask.name,
        paymentMethod: updateTask.paymentMethod,
        runningTimeMin: updateTask.runningTimeMin,
        startAt: updateTask.startAt,
        status: status,
        subTaskName: updateTask.subTaskName,
        totalIrrigationWater: updateTask.totalIrrigationWater,
        expireAt: updateTask.expireAt,
      };

      if (status !== updateTask.status) {
        await dispatch(updateGeneralTask(itemId, dataToSend));
        dispatch(fetchGeneralTaskList());
      }
    } else {
      setTaskData({
        status,
        updateTask,
      });

      if (status !== updateTask.status && updateTask.expireAt < localTime) {
        setOpen(true);
      }
    }
  };

  const dataChecker = (status) =>
    list.filter(
      (task) =>
        task.status === status &&
        task.archived === isArchivedView &&
        task.expireAt < localTime
    );
  const filtredList = (status) => {
    return list.filter(
      (i) => i.status === status && i.archived === isArchivedView
    );
  };
  return (
    <>
      <DateModal open={open} setOpen={setOpen} taskData={taskData} />;
      <div className={styles.container}>
        <Grid className={styles.tabContainer} columns={4}>
          <div style={{ height: "50px" }} className={styles.tabContainerRow}>
            {["OPEN", "IN_PROGRESS", "COMPLETED"].map((status) => {
              const filtredData = data.content.filter(
                (task) => task.status === activeTab
              );
              return (
                <div key={status} className={styles.menuItem} name={status}>
                  <div className={styles.iconWrapper}>
                    {selectMode &&
                      activeTab === status &&
                      (!!selectedItemsIds.length &&
                      selectedItemsIds.length !==
                        data.content.filter((task) => task.status === activeTab)
                          .length ? (
                        <div
                          className={styles.lineCheckboxMinus}
                          onClick={() => handleSelectAllItem(filtredData)}
                        >
                          <Icon className={styles.minusIcon} name="minus" />
                        </div>
                      ) : (
                        <Checkbox
                          className={styles.lineAllCheckbox}
                          checked={
                            selectedItemsIds.length ===
                            data.content.filter(
                              (task) => task.status === activeTab
                            ).length
                          }
                          onChange={() => handleSelectAllItem(filtredData)}
                        />
                      ))}{" "}
                    {`${(
                      status
                        .toLowerCase()
                        .charAt(0)
                        .toUpperCase() + status.toLowerCase().slice(1)
                    ).replace(/_/g, " ")} (${
                      filtredList(status, "#9DACBA").length
                    })`}
                    {status === "OPEN" && !!dataChecker("OPEN").length > 0 ? (
                      <Popup
                        position="right center"
                        className={styles.expiredPopup}
                        trigger={
                          <Icon
                            className={styles.infoButton}
                            name="info circle"
                          />
                        }
                        content={`There are ${
                          dataChecker("OPEN").length
                        } expired tasks. Please, check the details`}
                        size="small"
                        inverted
                      />
                    ) : status === "IN_PROGRESS" &&
                      !!dataChecker("IN_PROGRESS").length > 0 ? (
                      <Popup
                        position="right center"
                        className={styles.expiredPopup}
                        trigger={
                          <Icon
                            className={styles.infoButton}
                            name="info circle"
                          />
                        }
                        content={`There are ${
                          dataChecker("IN_PROGRESS").length
                        } expired tasks. Please, check the details`}
                        size="small"
                        inverted
                      />
                    ) : null}
                  </div>
                  <div className={styles.tasksIconWrapper}>
                    {selectMode && activeTab === status && archiveIcon()}
                    {dropdown(status)}
                  </div>
                </div>
              );
            })}
          </div>
          <div className={styles.cardContainerRow} onDragOver={handleDragOver}>
            <div
              onDrop={(e) => handleDrop(e, "OPEN")}
              className={styles.columnContainer}
            >
              <Grid.Column>
                <Segment className={styles.columnContent}>
                  {renderTaskCards("OPEN", "#9DACBA")}
                </Segment>
              </Grid.Column>
            </div>
            <div
              onDrop={(e) => handleDrop(e, "IN_PROGRESS")}
              className={styles.columnContainer}
            >
              <Grid.Column>
                <Segment className={styles.columnContent}>
                  {renderTaskCards("IN_PROGRESS", "#F0AC47")}
                </Segment>
              </Grid.Column>
            </div>
            <div
              onDrop={(e) => handleDrop(e, "COMPLETED")}
              className={styles.columnContainer}
            >
              <Grid.Column>
                <Segment className={styles.columnContent}>
                  {renderTaskCards("COMPLETED", "#59B655")}
                </Segment>
              </Grid.Column>
            </div>
          </div>
        </Grid>
      </div>
    </>
  );
};

export default connect((state) => ({
  activeItem: activeItemSelector(state),
  data: state.newTasks.data,
  list: state.newTasks.list.content,
  isFetching: state.newTasks.isFetching,
}))(CardTable);

import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef
} from "react";
import PropTypes from "prop-types";
import {
  useRowSelect,
  useTable,
  useSortBy,
  useResizeColumns,
  usePagination,
  useExpanded
} from "react-table";
import Table from "./components/Table";
import { debounce, omit, xor } from "lodash";
import { useLocation } from "react-router-dom";
import {
  removeHashParameter,
  removeHashParameters,
  setHashParameter
} from "utils/hashToObject";
import { toEndDateString, toStartDateString } from "../../utils/dateUtils";
import filterTypes from "./filterTypes";
import {
  addSelectColumn,
  getTableInitialState,
  changeHandler,
  parseFilters
} from "./helpers";
import { useDispatch } from "react-redux";
import { resetAllFilters } from "actions/Filters";
import { harvestUnitsActiveFilters } from "actions/HarvestUnits/harvestUnits";

const TableContainer = ({
  navigate,
  handleTaskIdClick,
  onDeleteClick,
  taskNew,
  defaultDate,
  dateType,
  actionButtons,
  withoutSearch,
  withPagination,
  backButton,
  basic,
  data,
  defaultSort,
  pendingMovementsLength,
  firstColumnFixed,
  withoutSort,
  open,
  setOpen,
  setFilters,
  printButton,
  printData,
  excel,
  handleExcelExport,
  printColumns,
  className,
  columns,
  rowId,
  inTable,
  containerClassName,
  currentPage: initialPageIndex,
  fixed,
  getData,
  filterChange,
  loading,
  onRowClick,
  searchPlaceholder,
  selectedItems,
  withHeader,
  striped,
  pageCount,
  withBorder,
  pageSize: initialPageSize,
  tableId,
  totalElements,
  withSelection,
  setSelectedItems,
  selectedItemsIdsChange,
  withSearch,
  dataRefetch,
  sortingDifferenceNames,
  wideTableClass,
  singleLine,
  pendingMovements,
  withTable,
  tableType,
  withMoreButton,
  sectionModal,
  numberofParent,
  activeTab,
  timeTracking,
  handleItemClick,
  onArchiveClick,
  selectMode,
  setSelectMode,
  selectedItemsIds,
  handleSelectChange,
  handleSelectAllItem,
  isArchivedView,
  taskTableType,
  list,
  setTaskTableType,
  changeFilter = false,
  setChangeFilter = () => {},
  onTaskPrint
}) => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const location = useLocation();
  const filtersOptions = useMemo(
    () =>
      columns
        .filter(column => column.filter)
        .map(({ id, title, filterId, filter }) => ({
          id,
          title,
          filterId,
          ...filter
        })),
    [columns]
  );

  const tableRef = useRef(null);

  const [selectedFilters, setSelectedFilters] = useState(() =>
    parseFilters(filtersOptions, location)
  );
  useEffect(() => setFilters && setFilters(selectedFilters), [selectedFilters]);
  const initialState = useMemo(
    () =>
      getTableInitialState({
        pageIndex: initialPageIndex,
        pageSize: initialPageSize,
        defaultSort,
        selectedItems
      }),
    [defaultSort, initialPageIndex, initialPageSize, selectedItems]
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      defaultCanSort: true,
      disableMultiSort: true,
      disableSortRemove: false,
      initialState,
      manualPagination: true,
      manualSortBy: true,
      pageCount
    },
    useResizeColumns,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    hooks => {
      if (withSelection) {
        addSelectColumn(hooks);
      }
    }
  );
  const { sortBy, pageIndex, pageSize, selectedRowIds } = tableInstance.state;

  const sortByFormatted = useMemo(() => {
    if (sortingDifferenceNames) {
      const nameToSort = sortingDifferenceNames.find(
        item => item.columnName === sortBy[0]?.id
      );
      if (nameToSort?.sortName) {
        return [{ ...sortBy[0], id: nameToSort.sortName }];
      }
    }

    return sortBy;
  }, [sortBy]);

  const debouncedChangeHandler = useMemo(
    () => debounce(changeHandler, 500),
    []
  );
  const onSearchChange = useCallback((_, { value: search }) => {
    setSearch(search);
  }, []);

  const onFilterChange = useCallback(
    (filterKey, value) => {
      const currentFilterElement =
        filtersOptions.find(({ filterId }) => filterId === filterKey) || "date";
      if (value === null) {
        const newValue = delete changeFilter[filterKey];
        !value && setChangeFilter(newValue);
        setSelectedFilters(filters => omit(filters, filterKey));
        removeHashParameter(location, filterKey, null, navigate);
      } else if (currentFilterElement === "date") {
        setSelectedFilters(filters => ({
          ...filters,
          [filterKey]:
            filterKey == "from"
              ? toStartDateString(value)
              : toEndDateString(value)
        }));
        setHashParameter(location, filterKey, value, null, navigate);
      } else if (currentFilterElement.type === filterTypes.MultiSelect) {
        setSelectedFilters(filters => {
          const newValue = xor(filters[filterKey], [value]);
          if (!newValue.length) {
            removeHashParameter(location, filterKey, null, navigate);

            delete filters[filterKey];
            return { ...filters };
          } else {
            !newValue.filter(i => i != value).length ||
              (changeFilter &&
                !changeFilter[filterKey] &&
                delete changeFilter[filterKey]);
            setHashParameter(location, filterKey, newValue, null, navigate);

            return {
              ...filters,
              [filterKey]: newValue.includes(`${value}`)
                ? newValue.filter(i => i != value)
                : newValue
            };
          }
        });
      } else {
        setSelectedFilters(filters => ({ ...filters, [filterKey]: value }));
        setHashParameter(location, filterKey, value, null, navigate);
      }
    },
    [filtersOptions, setSelectedFilters, location]
  );

  const clearFilters = useCallback(() => {
    setSelectedFilters({});
    setChangeFilter({});
    filterChange(null, null);
    dispatch(resetAllFilters());
    dispatch(harvestUnitsActiveFilters({}));
    removeHashParameters(
      location,
      Object.keys({ ...selectedFilters, ...changeFilter }),
      null,
      navigate
    );
  }, [selectedFilters, location]);

  const setUrlParam = useCallback(
    (name, value) => {
      if (value) {
        setHashParameter(location, name, value, null, navigate);
      } else {
        removeHashParameter(location, name, null, navigate);
      }
    },
    [location]
  );

  useEffect(() => {
    const filters = [
      ...new URLSearchParams({ ...selectedFilters, ...changeFilter })
    ];
    debouncedChangeHandler({
      filters,
      page: pageIndex,
      search,
      size: pageSize,
      sortBy: sortByFormatted,
      getData,
      data
    });
  }, [
    pageSize,
    sortByFormatted,
    selectedFilters,
    search,
    pageIndex,
    getData,
    dataRefetch,
    isArchivedView
  ]);

  useEffect(
    function resetToFirstPage() {
      tableInstance.gotoPage(0);
    },
    [pageSize, sortBy, search, selectedFilters]
  );
  useEffect(
    function setParamsByToURL() {
      const sort = sortBy.map(({ id, desc }) =>
        [id, desc ? "asc" : "desc"].join(",")
      );

      setUrlParam("sort", sort.length ? sort : null);
    },
    [sortBy]
  );

  useEffect(function cancelChangeHandlerOnClose() {
    return () => debouncedChangeHandler.cancel();
  }, []);

  useEffect(() => {
    if (setSelectedItems) {
      setSelectedItems(Object.keys(selectedRowIds));
    }
  }, [selectedRowIds]);
  return (
    <Table
      navigate={navigate}
      list={list}
      handleTaskIdClick={handleTaskIdClick}
      filterChange={filterChange}
      onTaskPrint={onTaskPrint}
      onDeleteClick={onDeleteClick}
      taskTableType={taskTableType}
      setTaskTableType={setTaskTableType}
      selectedItemsIdsChange={selectedItemsIdsChange}
      selectedItemsIds={selectedItemsIds}
      activeTab={activeTab}
      timeTracking={timeTracking}
      handleItemClick={handleItemClick}
      onArchiveClick={onArchiveClick}
      dateType={dateType}
      taskNew={taskNew}
      defaultDate={defaultDate}
      withoutSearch={withoutSearch}
      withPagination={withPagination}
      withMoreButton={withMoreButton}
      numberofParent={numberofParent}
      tableType={tableType}
      withTable={withTable}
      wideTableClass={wideTableClass}
      pendingMovementsLength={pendingMovementsLength}
      actionButtons={actionButtons}
      backButton={backButton}
      withoutSort={withoutSort}
      withHeader={withHeader}
      basic={basic}
      className={className}
      firstColumnFixed={firstColumnFixed}
      clearFilters={clearFilters}
      containerClassName={containerClassName}
      filtersOptions={filtersOptions}
      fixed={fixed}
      open={open}
      inTable={inTable}
      rowId={rowId}
      printData={printData}
      excel={excel}
      handleExcelExport={handleExcelExport}
      setOpen={setOpen}
      printColumns={printColumns}
      printButton={printButton}
      withBorder={withBorder}
      loading={loading}
      onFilterChange={onFilterChange}
      onRowClick={onRowClick}
      onSearchChange={onSearchChange}
      pageCount={pageCount}
      search={search}
      searchPlaceholder={searchPlaceholder}
      selectedFilters={{ ...selectedFilters, ...changeFilter }}
      striped={striped}
      tableId={tableId}
      data={data}
      tableInstance={tableInstance}
      totalElements={totalElements}
      withSearch={withSearch}
      singleLine={singleLine}
      pendingMovements={pendingMovements}
      sectionModal={sectionModal}
      selectMode={selectMode}
      setSelectMode={setSelectMode}
      handleSelectChange={handleSelectChange}
      handleSelectAllItem={handleSelectAllItem}
      isArchivedView={isArchivedView}
    />
  );
};

TableContainer.propTypes = {
  actionButtons: PropTypes.node,
  backButton: PropTypes.node,
  basic: PropTypes.oneOfType([PropTypes.oneOf(["very"]), PropTypes.bool]),
  className: PropTypes.string,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSort: PropTypes.string,
  loading: PropTypes.bool,
  fixed: PropTypes.bool,
  getData: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  striped: PropTypes.bool,
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  currentPage: PropTypes.number,
  totalElements: PropTypes.number,
  withSearch: PropTypes.bool,
  // Please be aware that this value is not reactive and could be set only on initializing
  withSelection: PropTypes.bool,
  singleLine: PropTypes.bool
};

TableContainer.defaultProps = {
  actionButtons: null,
  backButton: null,
  basic: "very",
  searchPlaceholder: "Search",
  inTable: false,
  striped: false,
  fixed: true,
  loading: false,
  pageCount: 1,
  currentPage: 0,
  totalElements: 1,
  pageSize: 20,
  withSearch: false,
  withSelection: false,
  singleLine: false
};

export default TableContainer;

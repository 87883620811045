import React, { useState } from "react";
import { useFormikContext } from "formik";
import { Icon, Form, Dropdown } from "semantic-ui-react";
import styles from "./CompletionModal.module.css";
import { assigneeFilterOptions } from "selectors/harvestUnits";
import { machineryFilterOptions } from "selectors/generalTasks";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { uniq } from "lodash";
import WorkingTime from "routes/SprayDiary/CreateSprayTask/WorkingTimes/WorkingTime";

const ComboForm = ({
  selectedItemsIds,
  setErrorChecker,
  activeArea,
  index,
  combo,
  values,
  onDelete,
  onChange,
  employeeList
}) => {
  const { errors, setFieldError } = useFormikContext();
  const [error, setError] = useState(null);
  const assigneeOptions = useSelector(assigneeFilterOptions).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );
  const machineryOptions = useSelector(machineryFilterOptions).options.map(
    item => ({
      key: item.key,
      text: item.label,
      value: item.value
    })
  );
  useEffect(() => {
    setErrorChecker(error);
  }, [error]);
  return (
    <>
      <div className={styles.assigneeWrapper}>
        <div className={styles.assigneeHeader}>
          Assignee {index + 1}
          <Icon
            className={styles.deleteBtn}
            disabled={values?.assignees.length < 2}
            name="trash alternate"
            onClick={() => {
              return (
                setErrorChecker(null),
                onChange(
                  "assignees",
                  values?.assignees.filter((_, i) => index !== i)
                ),
                onChange(
                  "equipments",
                  values?.equipments.filter((_, i) => index !== i)
                ),
                onChange(
                  `blocks`,
                  values.blocks.map(item => ({
                    assignee: item.assignee.filter(
                      (i, employeeIndex) => index !== employeeIndex
                    )
                  }))
                ),
                onDelete(index)
              );
            }}
          />
        </div>
        <div className={styles.comboContainer}>
          <Form.Group style={{ gap: "40px" }}>
            <Form.Field width={8}>
              <Dropdown
                placeholder="Select Assignee"
                search
                selection
                options={assigneeOptions}
                onChange={async (_, { value }) => {
                  return (
                    await onChange(`assignees[${index}]`, value),
                    onChange(
                      "blocks",
                      selectedItemsIds.map((area, areaIndex) => {
                        const employee = employeeList.filter(item =>
                          values.assignees.includes(item.id)
                        );
                        return {
                          assignee: values.assignees.map((a, assigneIndex) => {
                            return {
                              employee: employee,
                              workingTime: [
                                {
                                  start: null,
                                  end: null,
                                  wtStart: null,
                                  wtEnd: null,
                                  price: 0,
                                  noOfPieces: 0,
                                  lunchInterval: 0
                                }
                              ]
                            };
                          })
                        };
                      })
                    ),
                    values.assignees.some(item => value === item)
                      ? setError(true)
                      : setError(null)
                  );
                }}
                value={combo || ""}
              />
            </Form.Field>
            <Form.Field width={8}>
              <Dropdown
                placeholder="Select Machinery"
                search
                selection
                options={machineryOptions}
                value={values.equipments[index] || ""}
                onChange={(_, { value }) => {
                  return onChange(`equipments[${index}]`, value);
                }}
              />
            </Form.Field>
          </Form.Group>
          {error && (
            <div
              style={{
                width: "100%",
                marginTop: "16px",
                color: "#8C0D24"
              }}
            >
              This assignee has already been added
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ComboForm;

import React, { useState } from "react";
import { Table, Checkbox, Icon, Confirm } from "semantic-ui-react";
import {
  areasGrouper,
  employeeViewer,
  wtBigSmallFinder,
  wtCreater
} from "../../components/helper";
import { Chip } from "routes/Reports/Harvest/components/Chip";
import logo from "assets/img/tuf_logo.svg";
import styles from "./Table.module.css";
import { useDispatch } from "react-redux";
import { updateGeneralTask, deleteGeneralTaskWorkingTime } from "actions/Tasks";
import Loader from "components/Loader";

const AreaTable = ({
  isFetching,
  activeWorkingTime,
  task,
  handleRowClick,
  areas,
  assignedTo,
  areaType,
  activeTab,
  selectedItemsIds,
  handleSelectAllItem,
  handleSelectChange,
  columns,
  employeeList,
  setSelectedItems,
  setOpenEditModal,
  setActiveArea,
  activeArea,
  archivedList
}) => {
  const dispatch = useDispatch();
  const [openCondirm, setOpenConfirm] = useState(false);
  const newData = areaType === "ALL" ? areas : areasGrouper(areas);
  const handleCellClick = (e, item) => {
    const isCheckboxClicked =
      e.target?.classList.contains("checkbox") ||
      e.target?.classList.contains("btnWrapper") ||
      e.target.tagName.toLowerCase() === "label" ||
      e.target.tagName.toLowerCase() === "i";
    if (!isCheckboxClicked) {
      handleRowClick(item);
    }
  };
  const handleUpdate = async () => {
    const updatedAreas = Array.isArray(activeArea)
      ? activeArea.map(item => ({ areaId: item, completed: false }))
      : [{ areaId: activeArea, completed: false }];
    const dataToSend = {
      ...task,
      areas: [
        ...task.areas.filter(item =>
          updatedAreas.every(area => area.areaId !== item.areaId)
        ),
        ...updatedAreas
      ]
    };

    return (
      setOpenConfirm(false),
      setActiveArea(null),
      await dispatch(updateGeneralTask(task.id, dataToSend)),
      activeWorkingTime?.forEach(
        async item =>
          await dispatch(deleteGeneralTaskWorkingTime(task.id, item.id))
      )
    );
  };
  return (
    <>
      {isFetching ? (
        <span className={styles.areatableLoader}>
          <Loader />
        </span>
      ) : areas.length ? (
        <div className={styles.tableContainer}>
          <Table className={styles.areaTable} columns={columns}>
            <Table.Header>
              <Table.Row>
                {activeTab === "Open" && (
                  <Table.HeaderCell className={styles.allCheck}>
                    {!!selectedItemsIds?.length &&
                    selectedItemsIds?.length !== areas?.length ? (
                      <div
                        className={styles.lineCheckboxMinus}
                        onClick={() => handleSelectAllItem(newData)}
                      >
                        <Icon className={styles.minusIcon} name="minus" />
                      </div>
                    ) : (
                      <Checkbox
                        className={styles.lineAllCheckbox}
                        checked={
                          !!selectedItemsIds?.length &&
                          selectedItemsIds?.length === areas?.length
                        }
                        onChange={() => handleSelectAllItem(newData)}
                      />
                    )}
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell className={styles.header}>
                  Area
                </Table.HeaderCell>
                {areaType !== "ALL" && (
                  <Table.HeaderCell className={styles.header}>
                    Patch
                  </Table.HeaderCell>
                )}
                <Table.HeaderCell className={styles.header}>
                  Crop
                </Table.HeaderCell>
                <Table.HeaderCell className={styles.header}>
                  Variety
                </Table.HeaderCell>
                <Table.HeaderCell className={styles.header}>
                  Size
                </Table.HeaderCell>
                {activeTab === "Completed" && (
                  <>
                    <Table.HeaderCell className={styles.header}>
                      Piece N
                    </Table.HeaderCell>
                    <Table.HeaderCell className={styles.header}>
                      Assignees
                    </Table.HeaderCell>
                    <Table.HeaderCell className={styles.header}>
                      Working time
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      className={styles.header}
                    ></Table.HeaderCell>
                  </>
                )}
              </Table.Row>
            </Table.Header>
            <Table.Body className={styles.areaTableBody}>
              {newData.map(item => {
                const uniqueArray = Array.from(
                  new Set(item.varieties.map(obj => obj.variety))
                );
                const filterId = item?.children?.length
                  ? item.children.map(i => i.id)
                  : [item.id];
                const filtredWt = task.workingTimes?.filter(wt =>
                  wt.areas.map(a => a.areaId).some(i => filterId.includes(i))
                );
                const totalPiece = filtredWt?.reduce(
                  (prev, curr) => prev + curr.piecesPerEmployee,
                  0
                );
                const wtList = filtredWt
                  ?.map(item => {
                    return [item.startAt, item.endAt];
                  })
                  .flat();
                const areaId =
                  areaType !== "ALL"
                    ? item?.children?.length
                      ? item.children.map(i => i.id)
                      : item.id
                    : item?.children?.length
                    ? item.children[0].id
                    : item.id;

                return (
                  <Table.Row
                    className={styles.areaTableBodyRow}
                    onClick={e => handleCellClick(e, item)}
                  >
                    {activeTab === "Open" && (
                      <Table.Cell className={styles.areaTableBodyCell}>
                        <div
                          className="checkbox"
                          style={{
                            width: "50px",
                            paddingLeft: "4px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: !!(Array.isArray(areaId)
                              ? !!selectedItemsIds.filter(i =>
                                  areaId.includes(i)
                                ).length
                              : selectedItemsIds.includes(areaId))
                              ? "#59B655"
                              : "#9DACBA",
                            height: "50px",
                            borderTopLeftRadius: "8px",
                            borderBottomLeftRadius: "8px",
                            color: "white",
                            fontSize: "12px",
                            fontFamily: "Roboto"
                          }}
                        >
                          <Checkbox
                            className={styles.lineCheckbox}
                            checked={
                              !!(Array.isArray(areaId)
                                ? !!selectedItemsIds.filter(i =>
                                    areaId.includes(i)
                                  ).length
                                : selectedItemsIds.includes(areaId))
                            }
                            onChange={() => handleSelectChange(areaId)}
                          />
                        </div>
                      </Table.Cell>
                    )}
                    <Table.Cell
                      className={
                        activeTab === "Open"
                          ? styles.areaTableBodyBlockCell
                          : styles.areaCompleteTableBodyBlockCell
                      }
                    >
                      {areaType !== "ALL" ? (
                        `Block ${item.name}`
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column"
                          }}
                        >
                          <span> {`Block ${item?.name}`}</span>
                          <span style={{ color: "#666666" }}>
                            {item?.children.length
                              ? `Patch ${item?.children[0]?.name}`
                              : ""}
                          </span>
                        </div>
                      )}
                    </Table.Cell>
                    {areaType !== "ALL" && (
                      <Table.Cell className={styles.areaTableBodyCell}>
                        {item.children.length ? (
                          <Chip
                            color="green"
                            label="Patches"
                            items={item.children.map(i => i.name)}
                          />
                        ) : (
                          "-"
                        )}
                      </Table.Cell>
                    )}
                    <Table.Cell className={styles.areaTableBodyCell}>
                      {uniqueArray.length > 1 ? (
                        <Chip
                          color="green"
                          label="Crops"
                          items={[...uniqueArray.map(i => i.crop.name)]}
                        />
                      ) : (
                        uniqueArray[0]?.crop.name || "-"
                      )}
                    </Table.Cell>
                    <Table.Cell className={styles.areaTableBodyCell}>
                      {" "}
                      {uniqueArray.length > 1 ? (
                        <Chip
                          color="green"
                          label="Varieties"
                          items={[...uniqueArray.map(i => i.name)]}
                        />
                      ) : (
                        uniqueArray[0]?.name || "-"
                      )}
                    </Table.Cell>
                    <Table.Cell className={styles.areaTableBodyCell}>
                      {item.size ? `${item.size} Ha` : "-"}
                    </Table.Cell>
                    {activeTab === "Completed" && (
                      <>
                        <Table.Cell className={styles.areaTableBodyCell}>
                          {item.size && totalPiece
                            ? (totalPiece / item.size).toFixed(2)
                            : "-"}
                        </Table.Cell>
                        <Table.Cell className={styles.areaTableBodyCell}>
                          {employeeViewer(
                            filtredWt.map(item => ({
                              employeeId: item.employee.id
                            })),
                            [...employeeList, ...archivedList]
                          )}
                        </Table.Cell>
                        <Table.Cell className={styles.areaTableBodyWtCell}>
                          <div className={styles.dateContainer}>
                            <span> {wtBigSmallFinder(wtList)}</span>
                            <span style={{ color: "#666666" }}>
                              {wtCreater(
                                filtredWt,
                                item.startAt,
                                item.endAt,
                                item
                              )}
                            </span>
                          </div>
                        </Table.Cell>
                        <Table.Cell className={styles.areaTableBodyCell}>
                          <div
                            style={{
                              height: "50px",
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "center",
                              paddingRight: "10px"
                            }}
                            className="btnWrapper"
                          >
                            <Icon
                              className={styles.editIcon}
                              onClick={async () => {
                                return (
                                  await setSelectedItems([]),
                                  setActiveArea(
                                    item.children.length
                                      ? item.children.map(i => i.id)
                                      : [item.id]
                                  ),
                                  setOpenEditModal(true)
                                );
                              }}
                              name="edit"
                            />
                            <Icon
                              onClick={() => (
                                setActiveArea(
                                  item.children.length
                                    ? item.children.map(i => i.id)
                                    : item.id
                                ),
                                setOpenConfirm(true)
                              )}
                              className={styles.undoIcon}
                              name="undo"
                            />
                          </div>
                        </Table.Cell>
                      </>
                    )}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          <Confirm
            className={styles.confirmModal}
            content={`Move the ${
              Array.isArray(activeArea) ? ` ${activeArea.length} areas` : "area"
            }   back in "To do" list?`}
            open={openCondirm}
            onCancel={() => setOpenConfirm(false)}
            onConfirm={() => handleUpdate()}
          />
        </div>
      ) : (
        <div className={styles.card}>
          <div className={styles.emptyTableWrapper}>
            <img src={logo} alt="logo" />
            <span className={styles.emptyTableText}>
              You have no {`${activeTab.toLowerCase()}`} tasks yet.
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default AreaTable;

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import moment from "moment";
import { Button, Icon, Input } from "semantic-ui-react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import styles from "./DateRangePicker.module.css";

const DateRangePicker = props => {
  const [trigger, setTrigger] = useState(false);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState(null);

  const calendar = useRef(null);
  const input = useRef(null);

  useEffect(() => {
    document.body.addEventListener("click", handleBlur);
    return () => {
      document.body.removeEventListener("click", handleBlur);
    };
  }, [focused]);

  const handleBlur = event => {
    if (
      (!calendar.current ||
        (calendar.current &&
          !ReactDOM.findDOMNode(calendar.current).contains(event.target))) &&
      !focused
    ) {
      toggleShowCalendar(false);
    }
  };

  const toggleShowCalendar = (show = false) => {
    setTrigger(show);
  };

  const handleChange = e => {
    const { onChange, setDateDropdownChecker } = props;
    setDateDropdownChecker && setDateDropdownChecker(false);
    setInputValue(e);
    onChange({
      start: moment(e[0]).format("YYYY-MM-DD"),
      end: moment(e[1]).format("YYYY-MM-DD")
    });
    toggleShowCalendar(false);
  };

  const onFocus = () => {
    setFocused(true);
    setTrigger(true);
  };

  const onBlur = e => {
    const { onChange, disableClear } = props;
    if (
      moment(inputValue, "DD/MM/YYYY").isValid() ||
      (inputValue === "" && !disableClear)
    ) {
      onChange(
        inputValue ? moment(inputValue, "DD/MM/YYYY").format("YYYY-MM-DD") : ""
      );
    }
    setInputValue(null);
    setFocused(false);

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const onKeyPress = event => {
    if (event.key === "Enter") {
      ReactDOM.findDOMNode(input.current)
        .querySelector("input")
        .blur();
      toggleShowCalendar(false);
      event.preventDefault();
      event.stopPropagation();
    }
  };

  const {
    disabled,
    loading,
    placeholder,
    value,
    labelText,
    labelClass,
    disableClear,
    position,
    error,
    className,
    size,
    noCalendarIcon,
    minDate,
    maxDate,
    name
  } = props;

  const currentRangeValue = value
    ? `${moment(value.start).format("DD/MM/YYYY")} - ${moment(value.end).format(
        "DD/MM/YYYY"
      )}`
    : "";
  const extraProps = {
    ...(minDate ? { minDate: moment(minDate).toDate() } : {}),
    ...(maxDate ? { maxDate: moment(maxDate).toDate() } : {})
  };

  return (
    <span ref={calendar} className={`${styles.inputHolder} ${className || ""}`}>
      {labelText && <label className={labelClass || null}>{labelText} </label>}
      <Input
        disabled={disabled}
        loading={loading}
        fluid
        className={styles.datePickerInput}
        size={size || "large"}
        onFocus={onFocus}
        value={currentRangeValue}
        placeholder={placeholder || "DD/MM/YYYY"}
        icon={
          noCalendarIcon ? null : (
            <Icon className={styles.calendarIcon} name="tuf-calendar-alt" />
          )
        }
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        ref={input}
        error={error}
        name={name}
      />

      {trigger && (
        <div
          className={`${styles.calendarHolder} ${
            position ? styles[position] : ""
          }`}
        >
          <Calendar
            selectRange={true}
            value={
              value.start && value.end
                ? [new Date(value.start), new Date(value.end)]
                : [new Date(), new Date()]
            }
            onChange={e => handleChange(e)}
            {...extraProps}
          />
          {!disableClear && (
            <div className={styles.buttonHolder}>
              <Button
                type="button"
                onClick={() => props.onChange(null)}
                className="button-text"
              >
                Clear Date
              </Button>
            </div>
          )}
        </div>
      )}
    </span>
  );
};

export default DateRangePicker;

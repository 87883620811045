import PropTypes from "prop-types";
import React from "react";
import DatePicker from "../DatePicker";
import { Dropdown, Grid } from "semantic-ui-react";
import { range } from "lodash";
import styles from "./styles.module.css";
import moment from "moment";

const to2Digit = (value) =>
  `${value}`.length === 1 ? `0${value}` : `${value}`;

const hoursOptions = range(0, 24, 1).map((i) => ({
  text: to2Digit(i),
  key: to2Digit(i),
  value: to2Digit(i),
}));
const minutesOptions = range(0, 60, 1).map((i) => ({
  text: to2Digit(i),
  key: to2Digit(i),
  value: to2Digit(i),
}));

const DateTimePicker = ({ onChange, name, value }) => {
  const date = moment(value);
  const hours = date.format("HH");
  const minutes = date.format("mm");

  const handleDateChange = (_newDate) => {
    const newDate = moment(_newDate);
    date
      .year(newDate.year())
      .month(newDate.month())
      .date(newDate.date());
    onChange({ target: { name, value: date.toDate() } });
  };
  const handleHoursChange = (hours) => {
    date.hours(hours);
    onChange({ target: { name, value: date.toDate() } });
  };
  const handleMinutesChange = (minutes) => {
    date.minutes(+minutes);
    onChange({ target: { name, value: date.toDate() } });
  };

  return (
    <Grid>
      <Grid.Row style={{ padding: 0 }}>
        <Grid.Column width={8}>
          <DatePicker
            noCalendarIcon
            disableClear
            className={styles.datePart}
            onChange={handleDateChange}
            name={name}
            value={value}
          />
        </Grid.Column>
        <Grid.Column width={4} style={{ padding: "0 10px 0 10px" }}>
          <Dropdown
            style={{ height: "100%" }}
            name={"hours"}
            value={hours}
            options={hoursOptions}
            selection
            fluid
            onChange={(_, data) => handleHoursChange(data.value)}
          />
        </Grid.Column>
        <Grid.Column width={4} style={{ padding: "0 10px 0 10px" }}>
          <Dropdown
            style={{ height: "100%" }}
            name={"minutes"}
            value={minutes}
            options={minutesOptions}
            selection
            fluid
            onChange={(_, data) => handleMinutesChange(data.value)}
          />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

DateTimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default DateTimePicker;

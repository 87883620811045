import {
  ARCHIVED_RECOMMENDATIONS,
  ADD_RECOMMENDATION,
  EDIT_RECOMMENDATION,
  RECOMMENDATIONS_LIST
} from "../../constants/shared/routes";

export const routesConfig = [
  {
    group: "main", // group name for menu rendering
    name: "Dashboard", // menu name
    href: "/dashboard", //menu link
    path: ["/", "/dashboard"], //path rule
    component: "Dashboard", // component name
    exact: true, // exact for Path
    icon: "tuf-th-large", // icon name for menu
    menu: true, // show in menu
    className: null, // class for menu item
    private: true
  },
  {
    group: "main",
    name: "Add New Farm",
    href: "/farms/add_new_farm",
    path: ["/farms/add_new_farm", "/farms/add_new_farm/:step"],
    component: "AddNewFarm",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Edit Farm",
    href: "/farms/edit_farm",
    path: [
      "/farms/edit_farm/:id",
      "/farms/edit_farm/:id/add_block",
      "/farms/edit_farm/:id/:blockId"
    ],
    component: "EditFarm",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Farms records",
    href: "/farms",
    path: ["/farms/records"],
    component: "FarmsRecords",
    exact: false,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Farms",
    href: "/farms",
    path: ["/farms/:farmId", "/farms"],
    component: "Farms",
    exact: false,
    icon: "object group",
    menu: true,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Update Scouting",
    href: "/scouting/edit/:id",
    path: ["/scouting/edit/:id"],
    component: "UpdateScouting",
    exact: false,
    icon: "find",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Scouting",
    exact: false,
    icon: "find",
    menu: true,
    className: null,
    private: true,
    children: [
      {
        group: "sub",
        module: "scouting",
        name: "Scouting",
        href: "/scouting",
        path: "/scouting",
        component: "ScoutingList",
        exact: true,
        icon: "find",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "scouting",
        name: "Archived Tasks",
        href: "/scouting/archived",
        path: "/scouting/archived",
        component: "ScoutingList",
        exact: true,
        icon: "archive",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "scouting",
        name: "Draft Tasks",
        href: "/scouting/draft",
        path: "/scouting/draft",
        component: "ScoutingList",
        exact: true,
        icon: "file",
        menu: true,
        className: null,
        private: true
      }
    ]
  },
  {
    group: "main",
    name: "Recommendations",
    exact: false,
    icon: "warning circle",
    menu: true,
    className: null,
    private: false,
    children: [
      {
        group: "sub",
        name: "Recommendations",
        href: RECOMMENDATIONS_LIST,
        path: RECOMMENDATIONS_LIST,
        component: "RecommendationsList",
        exact: true,
        icon: "warning circle",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Archived tasks",
        href: ARCHIVED_RECOMMENDATIONS,
        path: ARCHIVED_RECOMMENDATIONS,
        component: "RecommendationsList",
        exact: true,
        icon: "archive",
        menu: true,
        className: null,
        private: true
      }
    ]
  },
  {
    group: "main",
    name: "Recommendation",
    href: "/recommendations/add",
    path: [ADD_RECOMMENDATION, EDIT_RECOMMENDATION],
    component: "RecommendationForm",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Spray records",
    href: "/spray_diary/records",
    path: ["/spray_diary/records"],
    component: "SprayRecords",
    exact: false,
    icon: "tuf-spray-can",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Spray Task",
    href: "/spray_diary/create_spray_task",
    path: [
      "/spray_diary/create_spray_task",
      "/spray_diary/create_spray_task/:id"
    ],
    component: "CreateSprayTask",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Spray Diary",
    href: "/spray_diary",
    path: [
      "/spray_diary/:activeFilters/:activeTask",
      "/spray_diary/:activeFilters",
      "/spray_diary//:activeTask",
      "/spray_diary"
    ],
    component: "SprayTasks",
    exact: false,
    icon: "tuf-spray-can",
    menu: true,
    className: null,
    private: true
  },
  {
    group: "main",
    // module: 'harvest',
    name: "Harvest",
    icon: "leaf",
    menu: true,
    className: null,
    private: true,
    exact: false,
    children: [
      {
        group: "sub",
        module: "harvest",
        name: " Pick To Pack",
        href: "/harvest",
        path: "/harvest",
        component: "Harvest",
        exact: true,
        icon: "leaf",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Scanned bins",
        href: "/harvest/units_control",
        path: "/harvest/units_control",
        component: "HarvestUnitsControl",
        exact: true,
        icon: "leaf",
        menu: true,
        className: null,
        private: true,
        dropDownID: "fieldScanninBarcodes"
      },
      {
        group: "sub",
        name: "Quality control checklist",
        href: "/harvest/qc",
        path: "/harvest/qc",
        component: "QualityControl",
        exact: true,
        icon: "hand paper",
        menu: true,
        className: null,
        private: true,
        dropDownID: "fieldScanninBarcodes"
      },
      {
        group: "sub",
        module: "harvest",
        name: "Pick to pack barcodes",
        href: "/harvest/generate_barcodes",
        path: ["/harvest/generate_barcodes"],
        component: "EmployeesBarcodesGenerator",
        exact: true,
        icon: "leaf",
        menu: true,
        className: null,
        private: true,
        dropDownID: "barcodesSettings"
      },
      {
        group: "sub",
        module: "harvest",
        name: "Field scanning barcodes",
        href: "/harvest/generate_general_barcodes",
        path: ["/harvest/generate_general_barcodes"],
        component: "GeneralBarcodesGenerator",
        exact: true,
        icon: "leaf",
        menu: true,
        className: null,
        private: true,
        dropDownID: "barcodesSettings"
      },
      /*     {
        group: "sub",
        module: "harvest",
        name: "Scanned general barcodes",
        href: "/harvest/scanned_general_barcodes",
        path: ["/harvest/scanned_general_barcodes"],
        component: "ScannedGeneralBarcodes",
        exact: true,
        icon: "leaf",
        menu: false,
        className: null,
        private: true,
        dropDownID: "barcodesSettings"
      }, */
      /*    {
        group: "sub",
        module: "harvest",
        name: "Scanned general barcodes",
        href: "/harvest/scanned_general_barcodes/:id",
        path: ["/harvest/scanned_general_barcodes/:id"],
        component: "UpdateScannedGeneralBarcode",
        exact: true,
        icon: "leaf",
        menu: false,
        className: null,
        private: true,
        dropDownID: "barcodesSettings"
      }, */
      {
        group: "sub",
        name: "Add QC Form",
        href: "/harvest/qc/form",
        path: ["/harvest/qc/form"],
        component: "AddEditQCForm",
        exact: true,
        menu: false,
        private: true
      },
      {
        group: "sub",
        name: "Edit QC Form",
        href: "/harvest/qc/form/:id",
        path: ["/harvest/qc/form/:id"],
        component: "AddEditQCForm",
        exact: true,
        menu: false,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Tag barcodes",
        href: "/harvest/generate_tag_barcodes",
        path: ["/harvest/generate_tag_barcodes"],
        component: "TagBarcodesGenerator",
        exact: true,
        icon: "leaf",
        menu: true,
        className: null,
        private: true,
        dropDownID: "barcodesSettings"
      },
      // Dropdowns
      {
        group: "dropdown",
        module: "harvest",
        name: "Field scanning",
        icon: "leaf",
        menu: true,
        private: true,
        id: "fieldScanninBarcodes",
        children: [{ href: "#" }]
      },
      {
        group: "dropdown",
        module: "harvest",
        name: "Barcodes",
        icon: "leaf",
        menu: true,
        private: true,
        id: "barcodesSettings",
        children: [{ href: "#" }]
      }
    ]
  },
  {
    group: "main",
    name: "Create Task",
    href: "/tasks/create",
    path: "/tasks/create",
    module: "general_tasks",
    component: "CreateTaskOptions",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Update Task",
    href: "/tasks/update/:taskTypeID",
    path: "/tasks/update/:taskTypeID",
    module: "general_tasks",
    component: "CreateOrUpdateTask",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Create Task",
    href: "/tasks/create/:taskTypeID",
    path: "/tasks/create/:taskTypeID",
    module: "general_tasks",
    component: "CreateOrUpdateTask",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Tasks",
    icon: "tasks",
    menu: true,
    className: null,
    private: true,
    children: [
      {
        group: "sub",
        name: "Tasks",
        icon: "tasks",
        href: "/tasks",
        path: [
          "/tasks/:activeTask/:activeFilters",
          "/tasks/:activeFilters",
          "/tasks//:activeTask",
          "/tasks"
        ],
        module: "general_tasks",
        component: "GeneralTasksList",
        exact: true,
        menu: true,
        className: null,
        private: false
      },
      {
        group: "sub",
        name: "Tasks new",
        icon: "tasks",
        href: "/tasks_new",
        path: ["/tasks_new"],
        module: "general_tasks",
        component: "NewTask",
        exact: true,
        menu: true,
        className: null,
        private: false
      },
      {
        group: "sub",
        name: "Slashing",
        href: "/tasks_new/slashing/:taskId",
        path: "/tasks_new/slashing/:taskId",
        component: "SlashingTask",
        exact: true,
        icon: null,
        menu: false,
        className: null,
        private: true
      },
      {
        group: "main",
        name: "Add task",
        href: "/tasks_new/add",
        path: "/tasks_new/add",
        module: "general_tasks",
        component: "AddTaskNew",
        exact: true,
        icon: null,
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Task Customization",
        href: "/tasks_customization",
        path: "/tasks_customization",
        component: "TaskCustomization",
        exact: true,
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "general_tasks",
        name: "Alt labour cost",
        icon: "tasks",
        href: "/alt_labour_cost_entering",
        path: ["/alt_labour_cost_entering"],
        component: "CostEntering",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "New Cost",
        href: "/alt_labour_cost_entering/add_new_alt_cost",
        path: ["/alt_labour_cost_entering/add_new_alt_cost"],
        component: "AddCost",
        exact: true,
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "general_tasks",
        name: "Operating costs",
        icon: "tasks",
        href: "/cost_entering",
        path: ["/cost_entering"],
        component: "OperatingCost",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "New Cost",
        href: "/cost_entering/add_new_cost",
        path: ["/cost_entering/add_new_cost"],
        component: "AddAltCost",
        exact: true,
        menu: false,
        className: null,
        private: true
      },
      {
        icon: "tasks",
        group: "sub",
        name: "Archived tasks",
        href: "/tasks_new/archived",
        path: "/tasks_new/archived",
        component: "NewTask",
        exact: true,
        menu: true,
        private: true,
        module: "general_tasks"
      },
      {
        icon: "tasks",
        group: "sub",
        name: "Time tracking",
        href: "/working_time",
        path: "/working_time",
        component: "WorkingTime",
        exact: true,
        menu: true,
        private: true,
        module: "general_tasks"
      }
    ]
  },

  {
    group: "main",
    // module: 'harvest',
    name: "Packing shed",
    icon: "warehouse",
    menu: true,
    className: null,
    private: true,
    exact: false,
    children: [
      {
        group: "sub",
        module: "harvest",
        name: "Packed boxes",
        href: "/harvest/packed_boxes",
        path: ["/harvest/packed_boxes"],
        component: "PackedBoxes",
        exact: true,
        icon: "warehouse",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Scan packed boxes",
        href: "/harvest/packed_boxes/scan",
        path: ["/harvest/packed_boxes/scan"],
        component: "ScanPackedBoxes",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Edit packed box",
        href: "/harvest/packed_boxes/edit",
        path: ["/harvest/packed_boxes/edit", "/harvest/packed_boxes/edit/:id"],
        component: "EditPackedBoxes",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Edit packed box",
        href: "/harvest/packed_boxes/batch_edit",
        path: ["/harvest/packed_boxes/batch_edit"],
        component: "BatchEditPackedBoxes",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Stock",
        href: "/harvest/stock",
        path: ["/harvest/stock"],
        component: "Stock",
        exact: true,
        icon: "warehouse",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Add stock item",
        href: "/harvest/stock/item",
        path: ["/harvest/stock/item", "/harvest/stock/item/:id"],
        component: "AddStockItem",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Pallets",
        href: "/harvest/pallets",
        path: ["/harvest/pallets"],
        component: "Pallets",
        exact: true,
        icon: "warehouse",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Add pallet",
        href: "/harvest/pallets/pallet",
        path: ["/harvest/pallets/pallet", "/harvest/pallets/pallet/:id"],
        component: "AddPallet",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Dispatches",
        href: "/harvest/dispatches",
        path: ["/harvest/dispatches"],
        component: "Dispatches",
        exact: true,
        icon: "warehouse",
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Add dispatch",
        href: "/harvest/dispatches/dispatch",
        path: [
          "/harvest/dispatches/dispatch",
          "/harvest/dispatches/dispatch/:id"
        ],
        component: "AddDispatch",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Box units",
        href: "/harvest/box_units",
        path: ["/harvest/box_units"],
        component: "BoxUnits",
        exact: true,
        icon: "warehouse",
        menu: true,
        className: null,
        private: true,
        dropDownID: "harvestSettings"
      },
      {
        group: "sub",
        module: "harvest",
        name: "Add box unit",
        href: "/harvest/box_units/add_box_units",
        path: [
          "/harvest/box_units/add_box_units",
          "/harvest/box_units/add_box_units/:id"
        ],
        component: "AddBoxUnits",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Packing sheds",
        href: "/harvest/packing_sheds",
        path: ["/harvest/packing_sheds"],
        component: "PackingSheds",
        exact: true,
        icon: "warehouse",
        menu: true,
        className: null,
        private: true,
        dropDownID: "harvestSettings"
      },
      {
        group: "sub",
        module: "harvest",
        name: "Add packing shed",
        href: "/harvest/packing_sheds/add_packing_sheds",
        path: [
          "/harvest/packing_sheds/add_packing_sheds",
          "/harvest/packing_sheds/add_packing_sheds/:id"
        ],
        component: "AddPackingSheds",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Clients/Buyers",
        href: "/harvest/vendors",
        path: ["/harvest/vendors"],
        component: "Vendors",
        exact: true,
        icon: "warehouse",
        menu: true,
        className: null,
        private: true,
        dropDownID: "harvestSettings"
      },
      {
        group: "sub",
        module: "harvest",
        name: "Add vendor",
        href: "/harvest/vendors/vendor",
        path: ["/harvest/vendors/vendor", "/harvest/vendors/vendor/:id"],
        component: "AddVendor",
        exact: true,
        icon: "warehouse",
        menu: false,
        className: null,
        private: true
      },
      // Dropdowns
      {
        group: "dropdown",
        module: "harvest",
        name: "Settings",
        icon: "warehouse",
        menu: true,
        private: true,
        id: "harvestSettings",
        children: [{ href: "#" }]
      }
    ]
  },
  {
    group: "main",
    name: "Labor",
    menu: true,
    className: null,
    icon: "tuf-users",
    private: true,
    children: [
      {
        group: "sub",
        name: "Employees",
        href: "/labor_management",
        path: "/labor_management",
        component: "Employees",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Archived employees",
        href: "/labor_management/archived",
        path: "/labor_management/archived",
        component: "Employees",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Add Employee",
        href: "/labor_management/employee",
        path: ["/labor_management/employee", "/labor_management/employee/:id"],
        component: "AddEditEmployee",
        exact: true,
        icon: "tint",
        menu: false,
        className: null,
        private: true
      },
      // TODO: make private
      {
        group: "sub",
        name: "Groups",
        href: "/labor_groups",
        path: ["/labor_groups"],
        component: "LaborGroups",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Archived groups",
        href: "/labor_groups/archived",
        path: "/labor_groups/archived",
        component: "LaborGroups",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Add Labor Group",
        href: "/labor_groups/group",
        path: ["/labor_groups/group"],
        component: "AddEditLaborGroup",
        exact: true,
        icon: "tuf-users",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Edit Labor Group",
        href: "/labor_groups/group/:id",
        path: ["/labor_groups/group/:id"],
        component: "AddEditLaborGroup",
        exact: true,
        icon: "tuf-users",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Edit Labor Group",
        href: "/labor_groups/group_assign_employees",
        path: ["/labor_groups/group_assign_employees"],
        component: "AssignEmployees",
        exact: true,
        icon: "tuf-users",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Contractors",
        href: "/labor_management/contractors",
        path: "/labor_management/contractors",
        component: "Contractors",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Archived contractors",
        href: "/labor_management/contractors/archived",
        path: "/labor_management/contractors/archived",
        component: "Contractors",
        exact: true,
        menu: true,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Workers Payroll",
        href: "/labor_management/payrolls",
        path: "/labor_management/payrolls",
        component: "WorkersPayroll",
        exact: true,
        icon: "tuf-users",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        name: "Add contractor",
        href: "/labor_management/contractors/contractor",
        path: [
          "/labor_management/contractors/contractor",
          "/labor_management/contractors/contractor/:id"
        ],
        component: "AddContractor",
        exact: true,
        icon: "tint",
        menu: false,
        className: null,
        private: true
      }
    ]
  },
  {
    group: "main",
    name: "Add New Chemical",
    href: "/chemicals/add_new_chemical",
    path: ["/chemicals/add_new_chemical", "/chemicals/add_new_chemical/:id"],
    component: "AddNewChemical",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Chemicals and Fertilisers",
    icon: "tint",
    menu: true,
    className: null,
    private: true,
    children: [
      {
        group: "sub",
        name: "Products",
        href: "/chemicals",
        path: "/chemicals",
        component: "Chemicals",
        exact: true,
        menu: true,
        private: false
      },
      {
        group: "sub",
        name: "Sheds",
        module: "inventory",
        href: "/chemicals/sheds",
        path: ["/chemicals/sheds//:activeTask", "/chemicals/sheds"],
        component: "Sheds",
        exact: true,
        menu: true,
        private: true
      },
      {
        group: "sub",
        name: "Inventory movements",
        href: "/chemicals/inventoryMovements",
        path: "/chemicals/inventoryMovements",
        component: "InventoryMovements",
        exact: true,
        menu: true,
        private: true,
        module: "inventory"
      },
      {
        group: "sub",
        name: "Pending movements",
        module: "inventory",
        href: "/chemicals/pendingMovements",
        path: "/chemicals/pendingMovements",
        component: "PendingMovements",
        exact: true,
        menu: true,
        private: true,
        storeAccesser: "pendingMovements",
        isAvailable: () => {
          const pendingMovementsList = JSON.parse(
            localStorage.getItem("pendingMovements")
          );
          return (
            pendingMovementsList && pendingMovementsList.list.content.length > 0
          );
        },
        getNotificationsCount: payload => {
          return payload && payload.list.content.length;
        }
      },
      {
        group: "sub",
        name: "Purchase receipt",
        href: "/chemicals/purchaseHistory",
        path: "/chemicals/purchaseHistory",
        component: "PurchaseHistory",
        exact: false,
        menu: true,
        private: true,
        module: "inventory",
        storeAccesser: "purchaseHistory"
      },
      {
        group: "sub",
        name: "Archived sheds",
        href: "/chemicals/sheds/archived",
        path: "/chemicals/sheds/archived",
        component: "Sheds",
        exact: true,
        menu: true,
        private: true,
        module: "inventory"
      },
      {
        group: "sub",
        name: "Archived products",
        href: "/chemicals/archived",
        path: "/chemicals/archived",
        component: "Chemicals",
        exact: true,
        menu: true,
        private: false
      }
    ]
  },
  {
    group: "main",
    name: "Machinery",
    href: "/machinery",
    path: "/machinery",
    component: "Machinery",
    exact: true,
    icon: "tuf-tractor",
    menu: true,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Add New Machinery",
    href: "/machinery/add_new_machinery",
    path: ["/machinery/add_new_machinery", "/machinery/add_new_machinery/:id"],
    component: "AddNewMachinery",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Clients",
    href: "/clients",
    path: "/clients",
    component: "Clients",
    exact: true,
    icon: "address book outline",
    menu: true,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Add New Client",
    href: "/clients",
    path: "/clients/add_new_client",
    component: "AddNewClient",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Farm Sensors",
    href: "/weather_stations",
    path: "/weather_stations",
    component: "WeatherStations",
    exact: true,
    icon: "sun",
    menu: true,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Reports",
    href: "/reports",
    path: "/reports",
    component: "Reports",
    exact: true,
    icon: "chart bar",
    menu: true,
    className: null,
    private: true
  },

  {
    group: "main",
    name: "Planting report ",
    href: "/reports/farm_assets/planting_report",
    path: "/reports/farm_assets/planting_report",
    component: "PlantingReport",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "sub",
    module: "harvest",
    name: "Harvest by area",
    href: "/reports/harvest/field_scanning/harvest_by_area",
    path: ["/reports/harvest/field_scanning/harvest_by_area"],
    component: "HarvestByArea",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "sub",
    module: "harvest",
    name: "Harvest by variety",
    href: "/reports/harvest/field_scanning/harvest_by_variety",
    path: ["/reports/harvest/field_scanning/harvest_by_variety"],
    component: "HarvestByVariety",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "sub",
    module: "harvest",
    name: "Picker report",
    href: "/reports/harvest/field_scanning/picker_report",
    path: ["/reports/harvest/field_scanning/picker_report"],
    component: "PickerReport",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: false
  },
  {
    group: "sub",
    module: "harvest",
    name: "Contractor report",
    href: "/reports/harvest/field_scanning/contractor_report",
    path: ["/reports/harvest/field_scanning/contractor_report"],
    component: "ContractorReport",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: false
  },
  {
    group: "sub",
    module: "harvest",
    name: "Harvest by area",
    href: "/reports/harvest/pick_to_pack/harvest_by_area",
    path: ["/reports/harvest/pick_to_pack/harvest_by_area"],
    component: "PickToPackHarvestByArea",
    exact: true,
    icon: "leaf",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "sub",
    module: "harvest",
    name: "Harvest by variety",
    href: "/reports/harvest/pick_to_pack/harvest_by_variety",
    path: ["/reports/harvest/pick_to_pack/harvest_by_variety"],
    component: "PickToPackHarvestByVariety",
    exact: true,
    icon: "leaf",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "sub",
    module: "harvest",
    name: "Picker report",
    href: "/reports/harvest/pick_to_pack/picker_report",
    path: ["/reports/harvest/pick_to_pack/picker_report"],
    component: "PickToPackPickerReport",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: false
  },
  {
    group: "sub",
    module: "harvest",
    name: "Contractor report",
    href: "/reports/harvest/pick_to_pack/contractor_report",
    path: ["/reports/harvest/pick_to_pack/contractor_report"],
    component: "PickToPackContractorReport",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: false
  },
  {
    group: "main",
    name: "Application Record",
    href: "/reports/applications_record_by_block",
    path: "/reports/applications_record_by_block",
    component: "ApplicationsRecordByBlock",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Nutrient breakdown",
    href: "/reports/nutrient_breakdown",
    path: "/reports/nutrient_breakdown",
    component: "NutrientBreakdown",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Product type usage",
    href: "/reports/product_type_usage",
    path: "/reports/Product_type_usage",
    component: "ProductTypeUsage",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Product usage",
    href: "/reports/product_usage",
    path: "/reports/Product_usage",
    component: "ProductUsage",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Total Cost Per Area",
    href: "/reports/total_cost_per_area",
    path: "/reports/total_cost_per_area",
    component: "TotalCostPerArea",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Total Cost Per Farm",
    href: "/reports/total_cost_per_farm",
    path: "/reports/total_cost_per_farm",
    component: "TotalCostPerFarm",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: true
  },
  {
    group: "main",
    name: "Payroll — Hourly",
    href: "/reports/hourly_payroll",
    path: "/reports/hourly_payroll",
    component: "HourlyPayroll",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: false
  },
  {
    group: "main",
    name: "Payroll — Piece Rate",
    href: "/reports/piece_rate_payroll",
    path: "/reports/piece_rate_payroll",
    component: "PieceRatePayroll",
    exact: true,
    icon: "chart bar",
    menu: false,
    className: null,
    private: false
  },
  {
    group: "main",
    name: "Analytics",
    menu: false,
    className: null,
    icon: "chart area",
    private: true,
    children: [
      {
        group: "sub",
        module: "harvest",
        name: "Picker and variety report",
        href: "/harvest/packed_boxes/reports/picker_and_variety",
        path: ["/harvest/packed_boxes/reports/picker_and_variety"],
        component: "PickerAndVarietyReport",
        exact: true,
        icon: "leaf",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Harvest by variety",
        href: "/harvest/packed_boxes/reports/produce_by_variety",
        path: ["/harvest/packed_boxes/reports/produce_by_variety"],
        component: "PickToPackHarvestByVariety",
        exact: true,
        icon: "leaf",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Custom report",
        href: "/harvest/packed_boxes/reports/custom",
        path: ["/harvest/packed_boxes/reports/custom"],
        component: "CustomPackedBoxesReport",
        exact: true,
        icon: "leaf",
        menu: false,
        className: null,
        private: true
      },
      {
        group: "sub",
        module: "harvest",
        name: "Picked Crops per employee report",
        href: "/harvest/packed_boxes/reports/by_general_barcodes",
        path: ["/harvest/packed_boxes/reports/by_general_barcodes"],
        component: "ScannedGeneralBarcodesReport",
        exact: true,
        icon: "leaf",
        menu: false,
        className: null,
        private: true
      }
    ]
  },
  {
    group: "main",
    name: "Add New Weather Station",
    href: "/weather_stations/add_new_weather_station",
    path: [
      "/weather_stations/add_new_weather_station",
      "/weather_stations/add_new_weather_station/:id"
    ],
    component: "AddWeatherStation",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: true
  },
  {
    group: "headerMenu",
    name: "",
    icon: "tuf-cog",
    menu: true,
    className: null,
    private: true,
    children: [
      {
        group: "sub",
        name: "General information",
        href: "/account/settings",
        path: "/account/settings",
        component: "Settings",
        exact: true,
        icon: "tuf-cog",
        className: "",
        type: "account",
        menu: true,
        private: true
      },
      {
        group: "sub",
        name: "Task Customization",
        href: "/taskCustomization_settings",
        path: "/taskCustomization_settings",
        component: "SettingTaskCustomization",
        exact: true,
        icon: "tuf-cog",
        className: "",
        menu: true,
        private: true
      }
    ]
  },
  {
    group: "account",
    name: "Sign Out",
    href: "/account/logoff",
    path: "/account/logoff",
    component: "LogOff",
    exact: true,
    icon: "sign out",
    menu: true,
    className: "hide-md",
    private: false
  },
  {
    group: "support",
    name: "1300 944 318",
    href: "/support",
    path: "/support",
    component: "Blank",
    exact: true,
    icon: "tuf-comment-solid",
    menu: true,
    className: null,
    private: false,
    disabled: true
  },
  {
    group: null,
    name: "Tie Up Farming",
    href: "/",
    path: "/",
    component: "Home",
    exact: true,
    icon: null,
    menu: false,
    className: null,
    private: false
  },
  {
    group: null,
    name: "404",
    href: null,
    path: null,
    component: "NotFound",
    exact: false,
    icon: null,
    menu: false,
    className: null,
    private: false
  }
];

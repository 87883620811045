import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Dropdown, Icon } from "semantic-ui-react";
import styles from "./Filters.module.css";
import { filterTypes, filterTypesParser } from "./filterTypes/filterTypes";
import ClearButton from "./ClearButton";
import {
  getHashParameterIndex,
  getHashParameters,
  removeHashParameter,
  removeHashParameters,
  setHashParameter,
} from "../../utils/hashToObject";
import { setLocationToState } from "actions/GeneralTasks/generalTasks";

export const parseFilters = (options, location) => {
  const params = getHashParameters(location);
  return options
    .filter((option) => params[option.id])
    .reduce((arr, option) => {
      const parser = option.parser || filterTypesParser[option.type];
      const parser1 = parser(option, params[option.id]);
      return [...arr, ...parser1];
    }, []);
};

const GeneralTaskFilter = ({
  fromReportPage,
  data,
  options,
  disabled,
  stateLocation,
  tag,
  additionalButtons,
  location,
  disableChecker,
  onFilterClick,
  navigate,
}) => {
  const dispatch = useDispatch();
  const optionsMap = useMemo(() => {
    return options.reduce((map, option) => {
      map[option.id] = option;
      return map;
    }, {});
  }, [options]);

  const selectedFilters = useMemo(
    () =>
      options
        .map((option) => ({
          option,
          index: getHashParameterIndex(location, option.id, tag),
        }))
        .filter((option) => Number.isInteger(option.index))
        .sort((o1, o2) => o1.index - o2.index)
        .map((option) => option.option.id),
    [options, location, stateLocation]
  );

  const notSelectedOptions = useMemo(
    () =>
      options
        .filter(
          (option) =>
            selectedFilters.findIndex((filter) => filter === option.id) === -1
        )
        .map((option) => ({
          key: option.id,
          text: option.name,
          value: option.id,
          label: { icon: "plus" },
        })),
    [options, selectedFilters]
  );

  const onChange = useCallback(
    (_, data) => {
      return setHashParameter(location, data.value, "*", tag, navigate);
    },
    [location]
  );

  const defaultSeason = data.seasons?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});
  const defaultDate = [`${defaultSeason?.startAt},${defaultSeason?.endAt}`];
  useEffect(() => {
    defaultSeason?.startAt &&
      defaultSeason?.endAt &&
      !fromReportPage &&
      setHashParameter(stateLocation, "season", defaultDate, tag, navigate);
  }, []);

  const clearFilters = useCallback(() => {
    onFilterClick(true);
    removeHashParameters(
      stateLocation,
      options.map((option) => option.id),
      tag,
      navigate
    );
  }, [options, stateLocation]);

  const removeFilter = useCallback(
    (id) => {
      onFilterClick(true);
      removeHashParameter(stateLocation, id, tag, navigate);
    },
    [stateLocation]
  );

  const getFilter = useCallback(
    (id) => {
      const filter = optionsMap[id];
      const FilterComponent = filterTypes[filter.type];
      return (
        <div key={id} className={styles.filterBox}>
          <FilterComponent
            onFilterClick={onFilterClick}
            disableChecker={disableChecker}
            {...filter}
          />
          <Button
            icon="close"
            className="button-text"
            disabled={
              disabled ||
              (disableChecker === "date" && filter.name === "Season") ||
              (disableChecker === "seasons" && filter.name === "Date")
            }
            onClick={() => {
              removeFilter(id);
            }}
          />
        </div>
      );
    },
    [disabled, optionsMap, removeFilter]
  );
  useEffect(() => {
    !selectedFilters.length && dispatch(setLocationToState({ location }));
  }, [selectedFilters]);

  return (
    <div className={styles.tabsAndFilters}>
      <div className={styles.filtersHolder}>
        <div className={styles.filterAdd}>
          <Dropdown
            floating
            labeled
            button
            disabled={!notSelectedOptions.length || disabled}
            className={`icon ${styles.buttonFilters}`}
            search
            icon={
              <>
                <Icon name="sliders horizontal" />
                <span className={styles.countFilters}>
                  {selectedFilters.length}
                </span>
              </>
            }
            text="Add Filter"
            selection
            options={notSelectedOptions}
            value={null}
            onChange={onChange}
            selectOnBlur={false}
          />
        </div>
        {selectedFilters.length > 0 && (
          <ClearButton
            className="hide-md-inline-block"
            onClick={clearFilters}
          />
        )}
        {selectedFilters.length > 0 && (
          <span className={styles.filtersItems}>
            {selectedFilters.map(getFilter)}
          </span>
        )}
        {additionalButtons.length > 0 &&
          additionalButtons.map(({ clickHandler, text }, index) => (
            <Button
              key={`${text}_${index}`}
              className={styles.additionalButton}
              onClick={clickHandler}
            >
              {text}
            </Button>
          ))}
        {selectedFilters.length > 0 && (
          <ClearButton
            className="show-md-inline-block"
            onClick={clearFilters}
          />
        )}
      </div>
    </div>
  );
};

GeneralTaskFilter.propTypes = {
  disabled: PropTypes.bool,
  location: PropTypes.object,
  options: PropTypes.array.isRequired,
  additionalButtons: PropTypes.array,
  tag: PropTypes.string,
};

GeneralTaskFilter.defaultProps = {
  tag: null,
  additionalButtons: [],
};

function mapStateToProps(state) {
  const {
    hourlyPayroll: { fromReportPage },
    settings: { data },
    router: { location },
  } = state;
  return {
    fromReportPage,
    data,
    location: location,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({}, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralTaskFilter);

import React, { useCallback, useEffect, useState } from "react";
import { Input, Button } from "semantic-ui-react";
import { connect, useDispatch, useSelector } from "react-redux";
import styles from "../ClockIn.module.css";
import { lunchIntervalFilter } from "selectors/workingTime";
import { fetchEmployeeGroupsList } from "actions/EmployeeGroups/employeeGroups";
import { fetchContractorsList } from "actions/Contractors/contractors";
import Employee from "reducers/Employee";
import AllEmployees from "./Sections/AllEmployees";
import LabourGroup from "./Sections/Labour";
import Contractor from "./Sections/Contractor";

const EmployeeSection = ({
  areas,
  activeItem,
  values,
  setFieldValue,
  setValues,
  laborGroups,
  employee,
  contractors
}) => {
  const [search, setSearch] = useState("");
  const [areaType, setAreaType] = useState("ALL");

  const dispatch = useDispatch();
  const onSearchChange = useCallback((_, { value: search }) => {
    setSearch(search);
  }, []);
  useEffect(() => {
    !laborGroups.length && dispatch(fetchEmployeeGroupsList());
    !contractors.length && dispatch(fetchContractorsList());
  }, [laborGroups, employee, contractors]);
  return (
    <div className={styles.btnContentWrapper}>
      <div className={styles.serchRow}>
        <Input
          value={search}
          className={styles.searchInput}
          placeholder="Search"
          onChange={onSearchChange}
        />
        <Button.Group basic className={styles.buttonsGroup} fluid>
          <Button
            key={"all"}
            type="button"
            onClick={() => {
              return setAreaType("ALL"), setFieldValue("children", []);
            }}
            className={areaType === "ALL" ? styles.buttonActive : null}
            basic
          >
            All employees
          </Button>
          <Button
            key={"group"}
            type="button"
            onClick={() => {
              return setAreaType("GROUP"), setFieldValue("children", []);
            }}
            className={areaType === "GROUP" ? styles.buttonActive : null}
            basic
          >
            Group
          </Button>
          <Button
            key={"contractor"}
            type="button"
            onClick={() => {
              return setAreaType("CONTRACTOR"), setFieldValue("children", []);
            }}
            className={areaType === "CONTRACTOR" ? styles.buttonActive : null}
            basic
          >
            Contractor
          </Button>
        </Button.Group>
      </div>
      <div className={styles.contentSection}>
        {areaType === "ALL" ? (
          <AllEmployees
            setValues={setValues}
            employee={employee}
            setFieldValue={setFieldValue}
            values={values}
            areas={areas}
          />
        ) : areaType === "GROUP" ? (
          <LabourGroup
            setValues={setValues}
            employee={employee}
            laborGroups={laborGroups}
            setFieldValue={setFieldValue}
            values={values}
            areas={areas}
          />
        ) : (
          <Contractor
            setValues={setValues}
            employee={employee}
            contractors={contractors}
            setFieldValue={setFieldValue}
            values={values}
            areas={areas}
          />
        )}
      </div>
    </div>
  );
};

export default connect((state, props) => {
  return {
    laborGroups: state.laborGroups?.list.content,
    employee: state.employee.list.content,
    contractors: state.contractors.list.content,
    areas: state.areas.list.content
  };
})(EmployeeSection);

import { actionType } from "../../constants/Recommendations";

const initialState = {
  currentTask: null,
  data: { content: [] },
  templatesList: null,
  isTemplatesListFetching: false,
  isFetching: false,
  isEditing: false,
  error: null
};

export default (state = initialState, action) => {
  if (!action) return state;

  switch (action.type) {
    case actionType.FETCH_RECOMMENDATIONS_START:
      return {
        ...state,
        isFetching: true
      };
    case actionType.SET_RECOMMENDATIONS_ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false
      };
    case actionType.SET_RECOMMENDATIONS:
      return {
        ...state,
        data: action.payload,
        isFetching: false
      };
    case actionType.SET_CURRENT_TASK:
      return {
        ...state,
        currentTask: action.payload,
        isFetching: false
      };
    case actionType.SET_IS_EDITING:
      return {
        ...state,
        isEditing: action.payload
      };
    case actionType.CLEAR_ERROR:
      return {
        ...state,
        error: null
      };

    case actionType.SET_TEMPLATES:
      return {
        ...state,
        templatesList: action.payload,
        isTemplatesListFetching: false
      };
    case actionType.FETCH_TEMPLATES_START:
      return {
        ...state,
        isTemplatesListFetching: true
      };
    case actionType.FETCH_TEMPLATES_FAIL:
      return {
        ...state,
        error: action.payload,
        isTemplatesListFetching: false
      };

    case actionType.ARCHIVE_TASKS_START:
      return {
        ...state,
        isDeleting: true
      };

    case actionType.ARCHIVE_TASKS:
      return {
        ...state,
        data: {
          ...state.data,
          content: state.data.content.filter(
            sc => !action.payload.includes(sc.id)
          )
        },
        isDeleting: false
      };

    case actionType.ARCHIVE_TASKS_FAIL:
      return {
        ...state,
        error: action.payload,
        isDeleting: false
      };

    default:
      return state;
  }
};

import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useReactToPrint } from "react-to-print";
import PropTypes from "prop-types";
import { useDispatch, useSelector, connect } from "react-redux";
import {
  Segment,
  Grid,
  Table,
  Header,
  Sidebar,
  Button,
  Icon,
} from "semantic-ui-react";
import { filtersOptions, sortConfig } from "./constants";
import TaskSidebar from "./components/Sidebar";
import Layout from "../../../components/Layout/Layout";
import Filters from "../../../components/Filters";
import Loader from "../../../components/Loader";
import moment from "moment";
import SortDropdown from "../../../components/SortDropdown";
import Pagination from "../../../components/ListTable/Pagination";
import SidebarHolder from "../../../components/SidebarHolder";
import Numeric from "../../../components/Numeric";
import PrintReport from "./components/PrintReport";
import { fetchSprayTasks } from "../../../actions/ApplicationsRecordByBlock/applicationsRecordByBlock";
import { fetchListSheds } from "../../../actions/Sheds";
import { getFiltersFromLocations } from "../../../utils/routeHelpers";
import { hashToObject, setHashParameter } from "../../../utils/hashToObject";
import {
  applicationsRecordByBlockSelector,
  applicationsRecordPrintoutDataSelector,
} from "../../../selectors/reports";
import { onlineSelector } from "../../../selectors/common";
import { history } from "../../../store";
import styles from "./ApplicationsRecordByBlock.module.css";
import { chemicalsOptionsFilterSelector } from "selectors/chemicals";
import { shedsListOptionsSelector } from "selectors/sheds";
import { excelExport } from "utils/excelExport";
import { calcTotalHectare, getTaskPlannedOrEndDate } from "utils/tasksUtils";
import { Area } from "../../../models/block.model";
import { uniq } from "lodash";
import { debounce } from "lodash";
import { usePrevious } from "utils/hooks";
import { calculateChemicalQuantity } from "utils/chemicalBreakdownUtils";
import { fetchAreasLog } from "actions/Areas/areas";
import { fetchChemicalsLog } from "actions/Chemicals";
import { fetchEmployeesLog } from "actions/Employee/employees";

function ApplicationsRecordByBlock({
  route,
  location,
  areasList,
  employeeList,
  chemicalList,
  shedList,
  data,
  navigate,
}) {
  const dispatch = useDispatch();
  const [checkLog, setCheckLog] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [activeTask, setActiveTask] = useState(null);
  const rawFilters = getFiltersFromLocations(filtersOptions, location);

  const prevLocation = usePrevious(location);
  const prevPageSize = usePrevious(pageSize);
  const prevPageNumber = usePrevious(pageNumber);

  const [printExpandedData, setPrintExpandedData] = useState(false);
  const {
    isFetching,
    data: { number, totalElements, totalPages },
  } = useSelector((state) => state.applicationsRecordByBlock);
  const online = useSelector(onlineSelector);
  const shedsList = useSelector(shedsListOptionsSelector);
  const tasksList = useSelector(applicationsRecordByBlockSelector(rawFilters));
  const printReportData = useSelector(
    applicationsRecordPrintoutDataSelector(rawFilters)
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { sort } = hashToObject(location.hash);
  const [sortValue, sortOrder] = sort ? sort.split(",") : ["farm", "asc"];

  const { filters, dateAfter, dateBefore } = useMemo(() => {
    const { dateFrom, dateTo, ...filters } = rawFilters;

    setPageNumber(0);

    const dateAfter = rawFilters.dateFrom ? rawFilters.dateFrom[0] : null;
    const dateBefore = rawFilters.dateTo ? rawFilters.dateTo[0] : null;

    return {
      filters,
      dateAfter,
      dateBefore,
    };
  }, [location.hash]);

  const disableChecker = !!filters.seasons
    ? "seasons"
    : !!filters.expirationDateAfter || !!filters.expirationDateBefore
    ? "date"
    : null;
  const unsetActiveTask = () => setActiveTask(null);

  const handleBlur = (event) => {
    if (!event.target.closest("#sidebarHolder")) {
      unsetActiveTask();
    }
  };
  const drawMultipleItems = (items, key) => {
    return items.map((item, index) =>
      key === "printIngredient" ? (
        Array.isArray(item) ? (
          <div className={styles.activeIngredientsCell} key={`${key}_${index}`}>
            {item ? `- ${item.join(",")}` : "—"}
          </div>
        ) : (
          <div className={styles.activeIngredientsCell} key={`${key}_${index}`}>
            {item ? `- ${item}` : "—"}
          </div>
        )
      ) : Array.isArray(item) ? (
        <div key={`${key}_${index}`}>{item.join(",") || "—"}</div>
      ) : (
        <div key={`${key}_${index}`}>{item || "—"}</div>
      )
    );
  };

  const handleSetActiveTask = (taskAdditionalInfo) => () => {
    setActiveTask(taskAdditionalInfo);
  };

  const itemsCount = useMemo(
    () => ({
      itemFrom: number * pageSize + 1,
      itemTo: Math.min(number * pageSize + 1 + pageSize - 1, totalElements),
    }),
    [number, pageSize, totalElements]
  );

  const fetchPrintoutAllInformation = () => {
    return dispatch(
      fetchSprayTasks({
        page: pageNumber,
        size: pageSize,
        filters,
        sort: [`${sortValue},${sortOrder}`],
        dateAfter,
        dateBefore,
        unpaged: true,
      })
    );
  };

  const fetchSpray = useRef(
    debounce((...params) => {
      if (!areasList.length || !chemicalList.length || !employeeList.length) {
        setCheckLog(true);
        Promise.all([
          dispatch(fetchAreasLog()),
          dispatch(fetchChemicalsLog()),
          dispatch(fetchEmployeesLog()),
        ])
          .then(() => {
            dispatch(fetchSprayTasks(...params));
          })
          .then(() => setCheckLog(false));
      } else {
        dispatch(fetchSprayTasks(...params));
      }
    }, 300)
  );
  useEffect(() => {
    if (
      prevLocation ||
      prevPageNumber !== pageNumber ||
      prevPageSize !== pageSize ||
      prevLocation.hash !== location.hash
    ) {
      fetchSpray.current({
        page: pageNumber,
        size: pageSize,
        filters,
        sort: [`${sortValue},${sortOrder}`],
        dateAfter,
        dateBefore,
      });
    }
  }, [pageNumber, pageSize, location]);

  useEffect(() => {
    document.body.addEventListener("click", handleBlur);

    return () => {
      document.body.removeEventListener("click", handleBlur);
    };
  }, [handleBlur]);

  const handleBackClick = () => {
    navigate({
      pathname: "/reports",
      state: {
        activeNode: location.state?.reportNode,
      },
    });
  };

  const handleExcelExport = useCallback(
    async (filters, sheds) => {
      const data = await dispatch(
        fetchSprayTasks({
          page: pageNumber,
          size: pageSize,
          filters,
          sort: [`${sortValue},${sortOrder}`],
          dateAfter,
          dateBefore,
          unpaged: true,
        })
      );
      const { content } = data || [];
      const windStrengthList = {
        WS_LESS_THEN_2: "Less then 2 km/h",
        WS_2_TO_5: "2-5 km/h",
        WS_5_TO_12: "5-12 km/h",
        WS_12_TO_20: "12-20 km/h",
        WS_20_TO_30: "20-30 km/h",
        WS_39_TO_49: "30-49 km/h",
      };

      const headerColumn = [
        "Planned date",
        "Completed date",
        "Expiry date",
        "Task ID",
        "Type",
        "Farm",
        "Area",
        "Crop",
        "Variety",
        "Shed",
        "Product type",
        "Chemical/Fertiliser",
        "Batch Number",
        "Active ingredient",
        "Purpose",
        "Withholding Period",
        "Safe Harvest Date",
        "Concentration Factor",
        "Actual Rate Applied",
        "Spray Vol/Ha",
        "Working Times",
        "Wind Speed",
        "Wind Direction",
        "Temperature",
        "Delta T",
        "Machinery",
        "Supervisor",
        "Operator ",
        "Comments  ",
      ];
      if (content?.length && !isFetching) {
        const newData = content
          .map((item, index) => {
            const multiplier = (item) => {
              const foundElement = [
                { id: "gram", matter: "SOLID", multiplicationFactor: 0.001 },
                { id: "kilogram", matter: "SOLID", multiplicationFactor: 1 },
                { id: "liter", matter: "LIQUID", multiplicationFactor: 1 },
                {
                  id: "milliliter",
                  matter: "LIQUID",
                  multiplicationFactor: 0.001,
                },
              ].find(({ id }) => id === item);

              if (foundElement) {
                return 1 / foundElement.multiplicationFactor;
              }
              return 1;
            };
            const calculateChemicalTotal = (
              i,
              selectedAreas,
              rowsToSpray,
              rowWidth,
              literPerHectare,
              conc
            ) => {
              const totalHectares = calcTotalHectare(selectedAreas);

              return calculateChemicalQuantity(
                i,
                totalHectares,
                rowsToSpray,
                rowWidth,
                literPerHectare,
                conc
              );
            };
            const selectedAreas = item.areas
              .map((area) => ({
                ...area.area,
                ...area,
              }))
              .map((item) => ({
                ...item,
                size: item.hectares,
              }));

            const selectedChemicals = item.chemicals.map((chemical) => ({
              ...chemical,
              ...chemical.chemical,
              rate: {
                ...chemical.chemicalRate,
                rate: chemical.dilutionRate,
              },
            }));
            const totalChemicals = selectedChemicals.map((chemical) => ({
              units: chemical.rate.unit,
              quantity: selectedAreas.reduce(
                (prev, { hectares, actualTanks, tanks, plannedTanks }) => {
                  const {
                    quantity: predictedQuantity,
                  } = calculateChemicalQuantity(
                    chemical,
                    hectares,
                    item.rowsToSpray,
                    item.rowWidth,
                    item.literPerHectare,
                    item.conc
                  );
                  let quantity = predictedQuantity;
                  if (
                    actualTanks !== null &&
                    actualTanks !== undefined &&
                    actualTanks !== (tanks || plannedTanks)
                  ) {
                    quantity =
                      (predictedQuantity * actualTanks) /
                      (tanks || plannedTanks);
                  }

                  return prev + quantity;
                },
                0
              ),
            }));
            const calculateActualRate = (
              rate,
              totalChemical,
              selectedChemical,
              selectedAreas,
              rowsToSpray,
              rowWidth,
              literPerHectare,
              conc
            ) => {
              return (
                (rate *
                  (totalChemical.quantity * multiplier(totalChemical.units))) /
                (calculateChemicalTotal(
                  selectedChemical,
                  selectedAreas,
                  rowsToSpray,
                  rowWidth,
                  literPerHectare,
                  conc
                )?.quantity *
                  multiplier(totalChemical.units))
              );
            };
            const workingTimes = item.workingTimes;
            const status = item.status;
            const { date: actualDate } = getTaskPlannedOrEndDate({
              workingTimes,
              status,
            });
            const area = areasList.filter((area) =>
              item.areas.some((a) => a.areaId === area.id)
            );
            const shed = sheds.find((s) => s.id == item.shedId);
            const productType = item.chemicals.map(
              ({ chemical }) => chemical.type.type
            );
            const chemicalFertiliser = item.chemicals.map(
              ({ chemical, dilutionRate, dilutionRateType }) => {
                return `${chemical.name}${dilutionRate}${
                  chemical.matter === "SOLID" ? "Kg" : "L"
                }`;
              }
            );
            const batchNumbers = item.chemicals
              .map(({ batchNumber }) => batchNumber)
              .filter((item) => !!item);
            const activeIngredients = item.chemicals
              .map(({ chemical }) => chemical.activeIngredients)
              .flat()
              .map((item) => item.activeIngredient);
            const purpose = item.chemicals
              .map(({ chemicalRate }) => chemicalRate?.purpose)
              .filter((item) => !!item);
            const withholdingPeriods = item.chemicals.map(
              ({ chemicalRate }) => chemicalRate.withholdingPeriod
            );

            const mostWithholdingPeriods = Math.max(
              ...withholdingPeriods.map((item) => {
                let mostWithholdingPeriod = 0;
                if (item > mostWithholdingPeriod) {
                  mostWithholdingPeriod = item;
                }
                return mostWithholdingPeriod;
              })
            );
            const actualRateApplied = item.chemicals.map(
              ({ chemical, dilutionRate, dilutionRateType }, indx) => {
                return `${
                  dilutionRateType === "PER_HECTARE"
                    ? calculateActualRate(
                        dilutionRate,
                        totalChemicals[indx],
                        selectedChemicals[indx],
                        selectedAreas,
                        item.rowsToSpray,
                        item.rowWidth,
                        item.literPerHectare,
                        item.conc,
                        index
                      ).toFixed(2)
                    : dilutionRate
                }${chemical.matter === "SOLID" ? "Kg" : "L"}/${
                  dilutionRateType === "PER_100_LITERS" ? "100L" : "Ha"
                }`;
              }
            );

            const farms = [];
            const cropsList = [];
            const varietiesList = [];

            area.map((item) => {
              return (
                farms.push(item.farm.name),
                item.varieties.length
                  ? item.varieties.forEach((variety) => {
                      varietiesList.push(variety);
                      cropsList.push(variety);
                    })
                  : (cropsList.push(null), varietiesList.push(null))
              );
            });
            const filtredCrops = area.map((a) => {
              const block = new Area(a);
              const variety = block.areaVarietyByDate(actualDate);
              const varietyList = a.varietiesList;

              return varietyList
                ? uniq(varietyList).map((vary, index) => {
                    return <div key={`variety_${index}`}>{vary}</div>;
                  })
                : variety.name
                ? `${variety.crop ? `${variety.crop.name}  ` : ""}`
                : "Vacant";
            });
            const filtredVariety = area.map((a) => {
              const block = new Area(a);
              const variety = block.areaVarietyByDate(actualDate);
              const varietyList = a.varietiesList;

              return varietyList
                ? uniq(varietyList).map((vary, index) => {
                    return <div key={`variety_${index}`}>{vary}</div>;
                  })
                : variety.name
                ? variety.name
                : "Vacant";
            });
            return [
              {
                plannedDate:
                  moment(item.plannedDate).format("DD/MM/YYYY") || "-",
                completedAt: moment(actualDate).format("DD/MM/YYYY") || "-",
                expirationDate: item.expirationDate
                  ? moment(item.expirationDate).format("DD/MM/YYYY")
                  : "-",
                id: item.id ? `ST-${item.id}` : "-",

                applicationType:
                  (
                    item.applicationType
                      .toLowerCase()
                      .charAt(0)
                      .toUpperCase() +
                    item.applicationType.toLowerCase().slice(1)
                  ).replace(/\s/g, "_") || "-",
                farm: [farms.length],
                area: [area.map((item) => item.name).length] || "-",
                cropsList: [filtredCrops.length] || "-",
                varietiesList: [filtredVariety.length] || "-",
                shed: shed?.name || "-",
                productType: [productType.length] || "-",
                chemicalFertiliser: [chemicalFertiliser.length] || "-",
                batchNumbers: [batchNumbers.length] || "-",
                activeIngredients: [activeIngredients.length] || "-",
                purpose: [purpose.length] || "-",
                withholdingPeriods: [withholdingPeriods.length] || "-",
                safeHarvestDate:
                  moment(actualDate)
                    .add(mostWithholdingPeriods, "days")
                    .format("DD/MM/YYYY") || "-",
                conc: item.conc || "-",
                actualRateApplied: [actualRateApplied.length] || "-",
                literPerHectare:
                  `${(
                    (item.literPerHectare *
                      item.areas.reduce(
                        (prev, curr) =>
                          prev +
                          (curr.actualTanks
                            ? curr.actualTanks
                            : curr.plannedTanks),
                        0
                      ) *
                      item.machinerySize) /
                      item.areas.reduce(
                        (prev, curr) => prev + curr.plannedLiters,
                        0
                      ) || item.literPerHectare
                  ).toFixed(2)} L/Ha` || "-",
                workingTimes: [item.workingTimes.length] || "-",
                windSpeed: windStrengthList[item.windStrength],
                windDirection: item.windDirection,
                temperature: `${item.temperature} °C`,
                deltaT: item.deltaT,
                machinery: item.machinery?.name,
                supervisor: [item.supervisors.length],
                operator: [item.assignees.length],
                comment: item.comment,
              },

              item.areas.length > item.chemicals.length
                ? item.areas.map((area, index) => {
                    const filtredArea = areasList.find(
                      (a) => a.id === area.areaId
                    );
                    const workingTime =
                      item.workingTimes && item.workingTimes[index];
                    const supervisor =
                      item.supervisors && item.supervisors[index];

                    const operator = item.assignees && item.assignees[index];

                    return {
                      plannedDate: undefined,
                      completedAt: undefined,
                      expirationDate: undefined,
                      id: undefined,
                      applicationType: undefined,
                      farm: [filtredArea?.farm?.name],
                      area: [
                        filtredArea.parent
                          ? `${filtredArea.parent.name}/${filtredArea.name}`
                          : filtredArea.name,
                      ],
                      cropsList: [filtredCrops[index]],
                      varietiesList: [filtredVariety[index]],
                      shed: undefined,
                      productType: [productType[index]],
                      chemicalFertiliser: [chemicalFertiliser[index]],
                      batchNumbers: [batchNumbers[index]],
                      activeIngredients: [activeIngredients[index]],
                      purpose: purpose[index] ? [purpose[index]] : [],
                      withholdingPeriods:
                        withholdingPeriods[index] ||
                        withholdingPeriods[index] === 0
                          ? [`${withholdingPeriods[index]} days`]
                          : [],
                      safeHarvestDate: undefined,
                      conc: undefined,
                      actualRateApplied: [actualRateApplied[index]],
                      literPerHectare: undefined,
                      workingTimes: workingTime
                        ? [
                            `${moment(
                              workingTime?.workingFrom,
                              "HH:mm:ss"
                            ).format("hh:mm a")}-${moment(
                              workingTime?.workingTo,
                              "HH:mm:ss"
                            ).format("hh:mm a")}`,
                          ]
                        : [],
                      windSpeed: undefined,
                      windDirection: undefined,
                      temperature: undefined,
                      deltaT: undefined,
                      machinery: undefined,
                      supervisor: supervisor
                        ? [
                            `${supervisor.employee.firstName} ${supervisor.employee.lastName}`,
                          ]
                        : [],
                      operator: operator
                        ? [
                            `${operator.employee.firstName} ${operator.employee.lastName}`,
                          ]
                        : [],
                      comment: undefined,
                    };
                  })
                : item.chemicals.map((chemical, index) => {
                    const workingTime =
                      item.workingTimes && item.workingTimes[index];
                    const supervisor =
                      item.supervisor && item.supervisor[index];
                    const operator = item.assignees && item.assignees[index];

                    const filtredArea = areasList.find(
                      (a) => a.id === item.areas[index]?.areaId
                    );
                    const activeIngredientsList = item.chemicals
                      .map(({ chemical }) => chemical.activeIngredients)
                      .flat()
                      .map((item) => item.activeIngredient);
                    return {
                      plannedDate: undefined,
                      completedAt: undefined,
                      expirationDate: undefined,
                      id: undefined,
                      applicationType: undefined,
                      farm: [filtredArea?.farm?.name],
                      area: [
                        filtredArea?.parent
                          ? `${filtredArea?.parent?.name}/${filtredArea?.name}`
                          : filtredArea?.name,
                      ],
                      cropsList: [filtredCrops[index]],
                      varietiesList: [filtredVariety[index]],
                      shed: undefined,
                      productType: [chemical.chemical.type.type],
                      chemicalFertiliser: [
                        `${chemical.chemical.name}${chemical.dilutionRate}${
                          chemical.chemical.matter === "SOLID" ? "Kg" : "L"
                        }`,
                      ],
                      batchNumbers: [
                        chemical.batchNumber && chemical.batchNumber,
                      ],
                      activeIngredients: [activeIngredientsList[index]],
                      purpose: [chemical.chemicalRate?.purpose],
                      withholdingPeriods:
                        chemical.withholdingPeriod ||
                        chemical.withholdingPeriod === 0
                          ? [`${chemical.withholdingPeriod} days`]
                          : [],
                      safeHarvestDate: undefined,
                      conc: undefined,
                      actualRateApplied: [
                        `${chemical.dilutionRate}${
                          chemical.chemical.matter === "SOLID" ? "Kg" : "L"
                        }/${
                          chemical.dilutionRateType === "PER_100_LITERS"
                            ? "100L"
                            : "Ha"
                        }`,
                      ],
                      literPerHectare: undefined,
                      workingTimes: workingTime
                        ? [
                            `${moment(
                              workingTime?.workingFrom,
                              "HH:mm:ss"
                            ).format("hh:mm a")}-${moment(
                              workingTime?.workingTo,
                              "HH:mm:ss"
                            ).format("hh:mm a")}`,
                          ]
                        : [],
                      windSpeed: undefined,
                      windDirection: undefined,
                      temperature: undefined,
                      deltaT: undefined,
                      machinery: undefined,
                      supervisor: supervisor
                        ? [
                            `${supervisor.employee.firstName} ${supervisor.employee.lastName}`,
                          ]
                        : [],
                      operator: operator
                        ? [
                            `${operator.employee.firstName} ${operator.employee.lastName}`,
                          ]
                        : [],
                      comment: undefined,
                    };
                  }),
            ].flat();
          })
          .flat();
        const excelData = newData.map(
          (
            {
              plannedDate,
              expirationDate,
              completedAt,
              id,
              applicationType,
              farm,
              area,
              cropsList,
              varietiesList,
              shed,
              productType,
              chemicalFertiliser,
              batchNumbers,
              activeIngredients,
              purpose,
              withholdingPeriods,
              mostWithholdingPeriods,
              actualRateApplied,
              safeHarvestDate,
              areas,
              shedId,
              chemicals,
              workingTimes,
              status,
              conc,
              literPerHectare,
              windSpeed,
              windDirection,
              temperature,
              deltaT,
              machinery,
              supervisor,
              operator,
              comment,
            },
            index
          ) => {
            return {
              ...{ plannedDate: plannedDate || "-" },
              ...{ completedAt: completedAt || "-" },
              ...{ expirationDate: expirationDate || "-" },
              ...{ id: id || "-" },
              ...{
                applicationType: applicationType
                  ? (
                      applicationType
                        ?.toLowerCase()
                        .charAt(0)
                        .toUpperCase() + applicationType.toLowerCase().slice(1)
                    ).replace(/\s/g, "_")
                  : "-",
              },
              ...{ farm: farm?.join(", ") || "-" },
              ...{ area: area.join(", ") || "-" },
              ...{ cropsList: cropsList.join(", ") || "-" },
              ...{ varietiesList: varietiesList.join(", ") || "-" },
              ...{ shed: shed || "-" },
              ...{ productType: productType.join(", ") || "-" },
              ...{ chemicalFertiliser: chemicalFertiliser.join(", ") || "-" },
              ...{ batchNumbers: batchNumbers.join(", ") || "-" },
              ...{ activeIngredients: activeIngredients.join(", ") || "-" },
              ...{ purpose: purpose.join(", ") || "-" },
              ...{
                withholdingPeriods: withholdingPeriods.join(", ") || "-",
              },
              ...{
                safeHarvestDate: safeHarvestDate,
              },
              ...{ conc: conc || "-" },
              ...{ actualRateApplied: actualRateApplied.join(", ") || "-" },
              ...{ literPerHectare: literPerHectare ? literPerHectare : "-" },
              ...{ workingTimes: workingTimes.join(", ") || "-" },
              ...{ windSpeed: windSpeed || "-" },
              ...{ windDirection: windDirection || "-" },
              ...{ temperature: temperature || "-" },
              ...{ deltaT: deltaT || "-" },
              ...{ machinery: machinery || "-" },
              ...{ supervisor: supervisor.join(", ") || "-" },
              ...{ operator: operator.join(", ") || "-" },
              ...{ comment: comment || "-" },
            };
          }
        );

        excelExport(excelData, headerColumn, "application_recor-excel");
      }
    },
    [fetchSprayTasks]
  );
  const defaultSeason = data?.reduce(function(prev, current) {
    return prev.startAt > current.startAt ? prev : current;
  }, {});
  const defaultDate = [defaultSeason?.startAt, defaultSeason?.endAt];

  useEffect(() => {
    defaultSeason?.startAt &&
      defaultSeason?.endAt &&
      setHashParameter(location, "seasons", defaultDate, null, navigate);
  }, []);
  return (
    <Layout route={route} location={location} classForMain={styles.mainHolder}>
      <Sidebar.Pushable className={styles.tasksHolder}>
        <Sidebar
          id="sidebarHolder"
          animation="overlay"
          direction="right"
          visible={!!activeTask}
          className={styles.sidebarHolder}
          onHidden={unsetActiveTask}
        >
          {activeTask && (
            <SidebarHolder navigate={navigate} onClose={unsetActiveTask}>
              <TaskSidebar task={activeTask} />
            </SidebarHolder>
          )}
        </Sidebar>
        <Sidebar.Pusher>
          <Header as="h2">
            <span className={styles.backIconWrapper} onClick={handleBackClick}>
              <Icon name="angle left" className={styles.backIcon} />
            </span>
            {route.name}
          </Header>
          <div style={{ display: "none" }}>
            <PrintReport
              title="Application Record"
              data={printReportData}
              shedsList={shedsList}
              startDate={dateAfter}
              endDate={dateBefore}
              showExpandedData={printExpandedData}
              drawMultipleItems={drawMultipleItems}
              ref={componentRef}
            />
          </div>
          <Segment>
            <Grid className={styles.filtersGrid} verticalAlign="middle">
              <Grid.Row verticalAlign="middle">
                <Grid.Column
                  mobile={16}
                  tablet={10}
                  computer={10}
                  largeScreen={10}
                >
                  <Filters
                    navigate={navigate}
                    filterName="seasons"
                    /* disabled={isFetching} */
                    disableChecker={disableChecker}
                    options={filtersOptions}
                    location={location}
                  />
                </Grid.Column>
                <Grid.Column
                  mobile={16}
                  tablet={6}
                  computer={6}
                  largeScreen={6}
                  floated="right"
                  textAlign="right"
                >
                  <Button
                    style={{ marginLeft: 20 }}
                    onClick={() =>
                      fetchPrintoutAllInformation().then(() => {
                        setPrintExpandedData(true);
                        handlePrint();
                      })
                    }
                  >
                    <span>
                      {!isFetching ? (
                        <Icon name="print" />
                      ) : (
                        <Icon name="circle notch loading icon" />
                      )}
                      Print
                    </span>
                  </Button>
                  <Button
                    style={{ marginLeft: 20 }}
                    onClick={() => handleExcelExport(rawFilters, shedList)}
                  >
                    <span>
                      {!isFetching ? (
                        <Icon name="download" />
                      ) : (
                        <Icon name="circle notch loading icon" />
                      )}
                      Export
                    </span>
                  </Button>
                  <SortDropdown
                    navigate={navigate}
                    {...sortConfig}
                    sortValue={sortValue}
                    sortOrder={sortOrder}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {(isFetching && tasksList.length === 0) || checkLog ? (
              <Loader className={styles.tasksLoader} />
            ) : tasksList.length > 0 ? (
              <>
                <Header as="h3">
                  Applications:{" "}
                  <span className="show-sm-inline">{itemsCount.itemFrom}</span>
                  <span className="hide-sm-inline">1</span>-{itemsCount.itemTo}{" "}
                  / {totalElements || "..."} items
                </Header>
                <Segment
                  basic
                  id="tasksListHolder"
                  className={styles.tasksListHolder}
                >
                  <Table selectable unstackable singleLine>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Planned date</Table.HeaderCell>
                        <Table.HeaderCell>Completed date</Table.HeaderCell>
                        <Table.HeaderCell>Expiry date</Table.HeaderCell>
                        <Table.HeaderCell>Task ID</Table.HeaderCell>
                        <Table.HeaderCell>Application type</Table.HeaderCell>
                        <Table.HeaderCell>Farm</Table.HeaderCell>
                        <Table.HeaderCell>Area</Table.HeaderCell>
                        <Table.HeaderCell>Crop</Table.HeaderCell>
                        <Table.HeaderCell>Variety</Table.HeaderCell>
                        <Table.HeaderCell>Shed</Table.HeaderCell>
                        <Table.HeaderCell>Product type</Table.HeaderCell>
                        <Table.HeaderCell>Chemical/Fertiliser</Table.HeaderCell>
                        <Table.HeaderCell>Batch Number</Table.HeaderCell>
                        <Table.HeaderCell>Active ingredient</Table.HeaderCell>
                        <Table.HeaderCell>Purpose</Table.HeaderCell>
                        <Table.HeaderCell>Withholding Period</Table.HeaderCell>
                        <Table.HeaderCell>Safe Harvest Date</Table.HeaderCell>
                        <Table.HeaderCell>
                          Concentration Factor
                        </Table.HeaderCell>
                        <Table.HeaderCell>Actual Rate Applied</Table.HeaderCell>
                        <Table.HeaderCell>Spray Vol/Ha</Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {tasksList.length > 0 &&
                        tasksList.map(
                          ({
                            date,
                            taskId,
                            type,
                            farms,
                            areas,
                            crops,
                            shedId,
                            varieties,
                            chemicalsFertilisers,
                            productTypeList,
                            literPerHectare,
                            batchNumbers,
                            activeIngredients,
                            purposes,
                            expirationDate,
                            withholdingPeriods,
                            safeHarvestDate,
                            concentrationFactor,
                            plannedDate,
                            actualRateApplied,
                            taskAdditionalInfo,
                          }) => (
                            <Table.Row
                              key={taskId}
                              verticalAlign="top"
                              onClick={handleSetActiveTask(taskAdditionalInfo)}
                            >
                              <Table.Cell>
                                {plannedDate
                                  ? moment(plannedDate).format("DD/MM/YYYY")
                                  : "-"}
                              </Table.Cell>
                              <Table.Cell>{date ? date : "-"}</Table.Cell>
                              <Table.Cell>
                                {expirationDate
                                  ? moment(expirationDate).format("DD/MM/YYYY")
                                  : "-"}
                              </Table.Cell>
                              <Table.Cell>{taskId}</Table.Cell>
                              <Table.Cell>
                                {(
                                  type
                                    .toLowerCase()
                                    .charAt(0)
                                    .toUpperCase() + type.toLowerCase().slice(1)
                                ).replace(/\s/g, "_")}
                              </Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(farms, "farm")}
                              </Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(areas, "area")}
                              </Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(crops, "crop")}
                              </Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(varieties, "variety")}
                              </Table.Cell>
                              <Table.Cell>
                                {shedsList.find((item) => item.key === shedId)
                                  ?.text || "-"}
                              </Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(
                                  productTypeList,
                                  "productTypeList"
                                )}
                              </Table.Cell>
                              <Table.Cell width={1}>
                                {drawMultipleItems(
                                  chemicalsFertilisers,
                                  "chemical"
                                )}
                              </Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(batchNumbers, "batch")}
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  whiteSpace: "break-spaces",
                                  minWidth: "300px",
                                  maxWidth: "300px",
                                }}
                              >
                                {drawMultipleItems(
                                  activeIngredients,
                                  "ingredient"
                                )}
                              </Table.Cell>
                              <Table.Cell
                                style={{
                                  whiteSpace: "break-spaces",
                                  minWidth: "200px",
                                  maxWidth: "300px",
                                }}
                              >
                                {drawMultipleItems(purposes, "purpose")}
                              </Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(
                                  withholdingPeriods,
                                  "period"
                                )}
                              </Table.Cell>
                              <Table.Cell>{safeHarvestDate}</Table.Cell>
                              <Table.Cell>{concentrationFactor}</Table.Cell>
                              <Table.Cell>
                                {drawMultipleItems(actualRateApplied, "rate")}
                              </Table.Cell>
                              <Table.Cell>
                                <Numeric
                                  fractionDigits={2}
                                  value={literPerHectare}
                                  commaSeparatorOnThousands
                                />
                                L/Ha
                              </Table.Cell>
                            </Table.Row>
                          )
                        )}
                    </Table.Body>
                  </Table>
                </Segment>
                <Pagination
                  currentPage={pageNumber}
                  totalElements={totalElements}
                  pageSize={pageSize}
                  online={online}
                  itemsCount={itemsCount}
                  onPageChangeOwn={(pageNumber) => setPageNumber(pageNumber)}
                  pages={totalPages}
                  updatePageSize={(_, data) => {
                    setPageSize(data.value);
                    setPageNumber(0);
                  }}
                  pageSizeOptions={[10, 20, 50, 100]}
                />
              </>
            ) : (
              <Header as="h4">Nothing found</Header>
            )}
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Layout>
  );
}

ApplicationsRecordByBlock.propTypes = {
  route: PropTypes.object.isRequired,
};

ApplicationsRecordByBlock.defaultProps = {
  route: { name: "{%name%}" },
};

export default connect((state, props) => {
  return {
    data: state.settings.data.seasons,
    shedList: state.shed.list.content,
    areasList: state.areas.list.content,
    employeeList: state.employee.list.content,
    chemicalList: state.chemical.list.content,
  };
})(ApplicationsRecordByBlock);
